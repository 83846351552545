import { useEffect, useState, useCallback } from 'react'
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input as ChakraInput, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select as ChakraSelect, Spinner, Text, Textarea, useDisclosure } from '@chakra-ui/react'

import { useParams } from 'react-router-dom'

import * as yup from 'yup'

import { format, parseISO } from 'date-fns'

import { IconType } from 'react-icons'
import { MdGroup, MdChat } from 'react-icons/md'
import { ImFolderUpload } from 'react-icons/im'
import { CgGirl } from 'react-icons/cg'
import { HiCheck } from 'react-icons/hi'

import * as S from './styles'
import { api } from '../../../services/api'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CreateButton from '../../../components/Buttons/CreateButton'
import { RiChatNewLine } from 'react-icons/ri'
import { useAuth } from '../../../context/AuthContext'

interface FeedbackProps {
    id: number
    description: string
    type: 'CUSTOMER' | 'PERSONAL' | 'PROJECT'
    created_at: Date | string
    resource: {
        id: number
        name: string
    }
    reporter: {
        id: number
        name: string
    } | null
    project: {
        id: number
        name: string
    } | null
    customer: {
        id: number
        name: string
    } | null
}

interface ProjectProps {
    id: number
    name: string
}

interface CustomerProps {
    id: number
    name: string
}

interface FeedbacksParams {
    resource_id: string
}

interface FeedbackButtonProps {
    text: string
    type?: any
    icon: IconType
    iconSize?: number
}

const Feedbacks: React.FC = () => {
    const { user } = useAuth()
    const { resource_id } = useParams<FeedbacksParams>()
    const [loading, setLoading] = useState(true)
    const [loadingCreating, setLoadingCreating] = useState(false)
    const [feedbacks, setFeedbacks] = useState<FeedbackProps[]>([])
    const [feedbacksFiltered, setFeedbacksFiltered] = useState<FeedbackProps[]>([])
    const [typeSelected, setTypeSelected] = useState(undefined)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [isLoadingCustomers, setIsLoadingCustomers] = useState(true)
    const [customers, setCustomers] = useState<CustomerProps[]>([])

    const [isLoadingProjects, setIsLoadingProjects] = useState(true)
    const [projects, setProjects] = useState<ProjectProps[]>([])

    useEffect(() => {
        async function loadFeedbacks() {
            const response = await api.get(`/feedbacks?resource_id=${Number(resource_id)}`)
            const feedbacksFormatted = response.data.map((f: FeedbackProps) => {
                return { ...f, created_at: format(parseISO(f.created_at + ''), 'dd/MM/yyyy') }
            })
            setFeedbacks(feedbacksFormatted)
            setFeedbacksFiltered(feedbacksFormatted)
            setLoading(false)
        }

        async function loadCustomers() {
            const response = await api.get(`/customers?qtdPerPage=500&currentPage=1`)
            setCustomers(response.data.data)
            setIsLoadingCustomers(false)
        }

        async function loadProjects() {
            const response = await api.get(`/projects`)
            setProjects(response.data)
            setIsLoadingProjects(false)
        }

        loadFeedbacks()
        loadCustomers()
        loadProjects()
    }, [resource_id])

    const feedbackSchema = yup.object({
        description: yup.string().min(3, 'Deve ter no mínimo 3 caracteres').max(1500, 'Deve ter até 1500 caracteres').required('Descrição é obrigatória'),
        type: yup.mixed().oneOf([
            'CUSTOMER',
            'PERSONAL',
            'PROJECT'], 'Tipo é obrigatório'),
        project_id: yup.number().nullable().when('type', (type, project_id) =>
            type === 'PROJECT' ? project_id.min(1, 'Projeto é obrigatório').required('Projeto é obrigatório') : project_id
        ),
        customer_id: yup.number().nullable().when('type', (type, customer_id) =>
            type === 'CUSTOMER' ? customer_id.min(1, 'Cliente é obrigatório').required('Cliente é obrigatório') : customer_id
        ),
    }).required()

    const {
        register,
        control,
        handleSubmit,
        formState,
        reset,
        clearErrors
    } = useForm({
        shouldUnregister: true,
        resolver: yupResolver(feedbackSchema)
    })

    const watchDescription = useWatch({ control, name: 'description', defaultValue: '' })
    const watchType = useWatch({ control, name: 'type' })

    const feedbacksType = useCallback((type?: 'PROJECT' | 'CUSTOMER' | 'PERSONAL') => {
        if (type) return feedbacksFiltered.filter(f => f.type === type)
        else return feedbacksFiltered
    }, [feedbacksFiltered])

    const FeedbackButton = ({ text, type, icon: Icon, iconSize = 25 }: FeedbackButtonProps) => {
        return (
            <S.FeedbackType isSelected={typeSelected === type} onClick={() => handleChangeFeedbackType(type)}>
                <Flex justifyContent='center' alignItems='center' w='40px' h='40px' bgColor='#323237' padding='7px' borderRadius={'50%'}>
                    <Icon color='#fff' size={iconSize} />
                </Flex>
                <Flex direction='column' alignItems='flex-start'>
                    <Text fontWeight={700} color='#323237'>{text}</Text>
                    {type && (
                        <>
                            {feedbacksType(type).length > 0 && (
                                <Text color='#323237' fontSize='14px'>
                                    Último em {feedbacksType(type)[0].created_at}
                                </Text>
                            )}
                        </>
                    )}
                    <Text color='#707070' mt='5px'>
                        {feedbacksType(type).length}{feedbacksType(type).length === 1 ? ' feedback' : ' feedbacks'}
                    </Text>
                </Flex>
            </S.FeedbackType>
        )
    }

    const FeedbackButtonSelected: React.FC = () => {
        if (typeSelected === 'CUSTOMER') {
            return <FeedbackButton text='Clientes' type='CUSTOMER' icon={MdChat} iconSize={20} />
        } else if (typeSelected === 'PERSONAL') {
            return <FeedbackButton text='Pessoal' type='PERSONAL' icon={MdGroup} />
        } else if (typeSelected === 'PROJECT') {
            return <FeedbackButton text='Projetos' type='PROJECT' icon={ImFolderUpload} iconSize={20} />
        }

        return <FeedbackButton text='Todos' type={undefined} icon={CgGirl} />
    }

    const handleChangeFeedbackType = (type?: any) => {
        setTypeSelected(type)
    }

    function closeNewModal() {
        reset()
        clearErrors()
        onClose()
    }

    async function handleCreate(data: any) {
        setLoadingCreating(true)
        const response = await api.post('/feedbacks', {
            ...data,
            resource_id,
            reporter_id: user.id
        })

        const feedbackCreated = response.data as FeedbackProps
        const feedbackCreatedFormatted: any = { ...feedbackCreated, created_at: format(parseISO(feedbackCreated.created_at + ''), 'dd/MM/yyyy') }
        const oldFeedbacks = [...feedbacks, feedbackCreatedFormatted]

        setFeedbacksFiltered(oldFeedbacks)

        closeNewModal()

        setLoadingCreating(false)
    }

    function handleFilterFeedback(text: string) {
        text = text.toLowerCase()
        if (text !== '' || !text.match(/\s\s+/g)) {
            setTimeout(() => {
                let feedbacksFiltered = feedbacks.filter((f: FeedbackProps) => {
                    const byReporter = f.reporter?.name.toLowerCase().includes(text)
                    const byFeedback = f.description.toLowerCase().includes(text)
                    const byProject = f.project?.name.toLowerCase().includes(text)
                    const byCustomer = f.customer?.name.toLowerCase().includes(text)
                    const byResource = f.resource.name.toLowerCase().includes(text)
                    return byReporter || byFeedback || byProject || byCustomer || byResource
                })
                setFeedbacksFiltered(feedbacksFiltered)
            }, 1000)
        } else {
            setFeedbacksFiltered(feedbacks)
        }
    }

    const feedbackTypeDescription = (feedback: FeedbackProps): string => {
        if (feedback.type === 'CUSTOMER' && feedback.customer) return 'Clientes'
        else if (feedback.type === 'PROJECT' && feedback.project) return 'Projeto'
        else return 'Pessoal'
    }

    const feedbackNameDescription = (feedback: FeedbackProps): string | null => {
        if (feedback.type === 'CUSTOMER' && feedback.customer) return feedback.customer.name
        else if (feedback.type === 'PROJECT' && feedback.project) return feedback.project.name
        else return null
    }

    return (
        <S.Container>
            {loading ? (
                <Spinner />
            ) : (
                <S.FeedbacksList>
                    <Flex
                        boxShadow={'0px 2px 5px 1px rgba(0,0,0,0.35)'}
                        flex={1}
                        padding='5px'
                        direction='column'
                        gap='20px'
                        bgColor='#fff'
                        borderRadius='10px'>
                        <ChakraInput
                            defaultValue=''
                            placeholder='Pesquisar'
                            onChange={(e) => handleFilterFeedback(e.target.value)} />
                        <Flex direction='column' gap='20px'>
                            <FeedbackButton text='Todos' type={undefined} icon={CgGirl} />
                            <FeedbackButton text='Pessoal' type='PERSONAL' icon={MdGroup} />
                            <FeedbackButton text='Projetos' type='PROJECT' icon={ImFolderUpload} iconSize={20} />
                            <FeedbackButton text='Clientes' type='CUSTOMER' icon={MdChat} iconSize={20} />
                        </Flex>
                    </Flex>
                    <Flex
                        boxShadow={'0px 2px 5px 1px rgba(0,0,0,0.35)'}
                        direction='column'
                        padding='5px'
                        flex={2}
                        gap='20px'
                        bgColor='#fff'
                        borderRadius='10px'>
                        <FeedbackButtonSelected />
                        <Flex padding='20px' direction='column' gap='10px' overflowY={'auto'} maxH='300px'>
                            {feedbacksType(typeSelected).length ? (
                                <>
                                    {feedbacksType(typeSelected).map((f, i) => (
                                        <Flex
                                            boxShadow={'0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)'}
                                            key={f.id}
                                            direction='column'
                                            bgColor='#fff'
                                            padding='15px'
                                            borderRadius={'9px'}
                                            gap='10px'>
                                            <Text color='#000' fontWeight={700}>
                                                {`${f.reporter?.name ?? 'Anônimo'} | ${feedbackTypeDescription(f)}`}
                                                {feedbackNameDescription(f) && ` | ${feedbackNameDescription(f)}`}
                                            </Text>
                                            <Text wordBreak='break-word' color='#000'>{f.description}</Text>
                                            <Flex alignItems='center' gap='3px'>
                                                <HiCheck color='#000' size={16} />
                                                <Text fontSize='14px' color='#000'>{f.created_at}</Text>
                                            </Flex>
                                        </Flex>
                                    ))}
                                </>
                            ) : (
                                <Text>Não há feedbacks nesta categoria</Text>
                            )}
                        </Flex>
                    </Flex>
                </S.FeedbacksList>
            )}
            <CreateButton tooltipText='feedback' icon={RiChatNewLine} onClick={() => onOpen()} />
            <Modal
                isOpen={isOpen}
                onClose={() => closeNewModal()}
                size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <Box as='form' onSubmit={handleSubmit(handleCreate)}>
                        <ModalHeader>Novo:</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex
                                flexDir={'column'}
                                gap={4}>
                                <FormControl isInvalid={!!formState.errors.description}>
                                    <FormLabel>Descrição</FormLabel>
                                    <Textarea
                                        size={'lg'}
                                        placeholder='Feedback do recurso...'
                                        {...register('description')}
                                        maxLength={1500}
                                    />
                                    <Text mt='5px' color='#747474' fontSize='15px'>Limite: {1500 - (`${watchDescription}`.length)}</Text>
                                    <FormErrorMessage>
                                        {formState.errors.description && formState.errors.description.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!formState.errors.type}>
                                    <FormLabel>Tipo</FormLabel>
                                    <ChakraSelect
                                        placeholder='Selecione...'
                                        {...register('type')} >
                                        <option value='CUSTOMER'>Clientes</option>
                                        <option value='PERSONAL'>Pessoal</option>
                                        <option value='PROJECT'>Projeto</option>
                                    </ChakraSelect>

                                    <FormErrorMessage>
                                        {formState.errors.type && formState.errors.type.message}
                                    </FormErrorMessage>
                                </FormControl>
                                {watchType === 'PROJECT' && (
                                    <>
                                        {isLoadingProjects ? (
                                            <Spinner />
                                        ) : (
                                            <FormControl isInvalid={!!formState.errors.project_id}>
                                                <FormLabel>Projetos</FormLabel>
                                                <ChakraSelect
                                                    {...register('project_id')} >
                                                    <option value={0}>Selecione...</option>
                                                    {projects.map(p => (
                                                        <option key={p.id} value={p.id}>{p.name}</option>
                                                    ))}
                                                </ChakraSelect>

                                                <FormErrorMessage>
                                                    {formState.errors.project_id && formState.errors.project_id.message}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </>
                                )}

                                {watchType === 'CUSTOMER' && (
                                    <>
                                        {isLoadingCustomers ? (
                                            <Spinner />
                                        ) : (
                                            <FormControl isInvalid={!!formState.errors.customer_id}>
                                                <FormLabel>Clientes</FormLabel>
                                                <ChakraSelect
                                                    {...register('customer_id')} >
                                                    <option value={0}>Selecione...</option>
                                                    {customers.map(p => (
                                                        <option key={p.id} value={p.id}>{p.name}</option>
                                                    ))}
                                                </ChakraSelect>

                                                <FormErrorMessage>
                                                    {formState.errors.customer_id && formState.errors.customer_id.message}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </>

                                )}

                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={closeNewModal}>
                                Cancelar
                            </Button>
                            <Button disabled={loadingCreating} type='submit' colorScheme='blue'>
                                {loadingCreating && (
                                    <Spinner marginRight={'5px'} />
                                )}
                                Cadastrar</Button>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
        </S.Container>
    )
}

export default Feedbacks