import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    height: 60px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    z-index: 1;

    background: #f7f7f7;
`

export const Profile = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 10px;
`

export const ProfileLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    border-radius: 5px;

    transition: all 0.2s;

    &:hover {
        background: #ccc;
    }

    @media(max-width: 700px) {
        p {
            display: none;
        }
    }
`

export const ActionButton = styled.button`
    width: 40px;
    height: 40px;

    border-radius: 50%;
    transition: all 0.2s;

    &:hover {
        background: #ccc;
    }

    & > span {
        display: flex;
        justify-content: center;
        align-items: center;
    }

`