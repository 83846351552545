import { memo } from 'react'
import { Avatar, Box, Flex, Skeleton, Stack, Table, Tag, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react"

import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

import { FaRegEye } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { AiOutlineHistory } from 'react-icons/ai'

import { HasPermission } from "../../../components/HasPermission"
import Panel from "../../../components/Panel"
import { ProjectProps } from '../Models'
import TableActionButton from '../../../components/Buttons/TableActionButton'

interface ProjectsTableProps {
    projects: ProjectProps[]
    panelTitle: string
    projectStatus: string
    loadingProjects: boolean
    selectProject(id: number): void
    viewSelectedProject(id: number): void
    showHistoric(id: number, name: string): void
}

const ProjectsTable = memo(({
    projects,
    projectStatus,
    panelTitle,
    loadingProjects,
    selectProject,
    viewSelectedProject,
    showHistoric }: ProjectsTableProps) => {

    return (
        <Panel title={panelTitle} counter={projects.length.toString()} flex={1} position='relative' flexDir={'column'}>
            {loadingProjects ? (
                <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <Flex maxWidth={'-webkit-fill-available'} overflowX='auto'>
                    {
                        projects.length > 0 ? (
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th>NOME</Th>
                                        <Th>QTD</Th>
                                        <Th>TIPO</Th>
                                        <Th>HORAS</Th>
                                        {projectStatus === 'A_INICIAR' && (
                                            <Th>MÊS</Th>
                                        )}
                                        <Th>RESPONSÁVEL</Th>
                                        <Th></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {projects.map(p => (
                                        <Tr key={p.uuid}>
                                            <Td maxWidth={'fit-content'}>
                                                <Flex alignItems={'center'} gap='20px' maxWidth={'fit-content'}>
                                                    <Tooltip hasArrow label={p.customer.name} bg='red.600'>
                                                        <Avatar
                                                            objectFit={'cover'}
                                                            borderRadius='full'
                                                            boxSize='50px'
                                                            name={p.customer.name}
                                                            src={p.customer.image_url}
                                                        />
                                                    </Tooltip>
                                                    <Text fontWeight={700} minWidth='170px'>
                                                        {p.name}
                                                    </Text>
                                                </Flex>
                                            </Td>
                                            <Td>
                                                {p.resources_length}
                                            </Td>
                                            <Td>
                                                <Tag variant='solid' fontWeight={700} colorScheme={p.type === 'PF' ? 'blue' : 'green'}>
                                                    <Tooltip
                                                        hasArrow
                                                        label={
                                                            p.type === 'PF' ? 'Projeto Fechado' : 'Projeto Recorrente'} bg='red.600'>
                                                        {p.type}
                                                    </Tooltip>
                                                </Tag>
                                            </Td>
                                            <Td>
                                                <Text fontWeight={700}>
                                                    {p.hours}H
                                                </Text>
                                            </Td>
                                            {projectStatus === 'A_INICIAR' && (
                                                <Td>
                                                    <Text textTransform={'capitalize'}>{format(p.start_estimate, 'MMMMMMM', { locale: ptBR })}</Text>
                                                </Td>
                                            )}
                                            <Td>
                                                <Flex alignItems={'center'} gap='5px' maxWidth={'fit-content'}>
                                                    {p.responsible ? (
                                                        <Tooltip key={p.responsible.name} hasArrow label={p.responsible.name} bg='red.600'>
                                                            <Avatar
                                                                objectFit={'cover'}
                                                                borderRadius='full'
                                                                boxSize='50px'
                                                                name={p.responsible.name}
                                                                src={p.responsible.photo_url}
                                                            />
                                                        </Tooltip>
                                                    ) : (
                                                        <Text>Sem responsável</Text>
                                                    )}
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Box display='flex' gap='10px'>
                                                    <HasPermission permissions={['update_project']}>
                                                       <TableActionButton text='Editar' icon={FiEdit} onClick={() => selectProject(p.id)} />
                                                    </HasPermission>
                                                    <TableActionButton text='Visualizar' icon={FaRegEye} onClick={() => viewSelectedProject(p.id)}/>
                                                    <TableActionButton text='Exibir histórico' icon={AiOutlineHistory} onClick={() => showHistoric(p.id, p.name)}/>
                                                </Box>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <Text width='100%' textAlign='center'>Não há projetos neste status</Text>
                        )
                    }
                </Flex>
            )}
        </Panel>
    )
})

export default ProjectsTable