import { MdDashboard } from 'react-icons/md'
import { BsPersonFill, BsFileEarmarkBarGraphFill } from 'react-icons/bs'
import { RiFileList3Line } from 'react-icons/ri'
import { BiWindows } from 'react-icons/bi'
import { IoRocketOutline } from 'react-icons/io5'

import * as S from './styles'
import { HasPermission } from '../HasPermission'

import menuIcon from '../../assets/icone_do_menu.png'

const Sidebar: React.FC = () => {
    return (
        <S.Container>
            <S.SidebarMenu>
                <S.Header>
                    <img width='40px' height='40px' src={menuIcon} alt='Icone do menu' />
                    <h3>Equipe +</h3>
                </S.Header>
                <S.Body>
                    <S.MenuList>
                        <S.Item to='/'>
                            <S.IconContainer>
                                <BsFileEarmarkBarGraphFill color='#fff' size={25} />
                            </S.IconContainer>
                            <span>Visão Geral</span>
                        </S.Item>
                        <HasPermission permissions={['view_dashboard_menu']}>
                            <S.Item to='/dashboard'>
                                <S.IconContainer>
                                    <MdDashboard color='#fff' size={25} />
                                </S.IconContainer>
                                <span>Dashboard</span>
                            </S.Item>
                        </HasPermission>
                        <HasPermission permissions={['view_projects', 'create_project', 'update_project']}>
                            <S.Item to='/projetos'>
                                <S.IconContainer>
                                    <BiWindows color='#fff' size={25} />
                                </S.IconContainer>
                                <span>Projetos</span>
                            </S.Item>
                        </HasPermission>
                        <HasPermission permissions={['view_customers', 'create_customer', 'update_customer', 'delete_customer']} >
                            <S.Item to='/clientes'>
                                <S.IconContainer>
                                    <RiFileList3Line color='#fff' size={25} />
                                </S.IconContainer>
                                <span>Clientes</span>
                            </S.Item>
                        </HasPermission>
                        <HasPermission permissions={['view_resources', 'create_resource', 'update_resource']}>
                            <S.Item to='/recursos'>
                                <S.IconContainer>
                                    <BsPersonFill color='#fff' size={25} />
                                </S.IconContainer>
                                <span>Recursos</span>
                            </S.Item>
                        </HasPermission>
                        <HasPermission permissions={['view_skills', 'create_skill', 'update_skill', 'delete_skill']}>
                            <S.Item to='/skills'>
                                <S.IconContainer>
                                    <IoRocketOutline color='#fff' size={25} />
                                </S.IconContainer>
                                <span>Skills</span>
                            </S.Item>
                        </HasPermission>
                    </S.MenuList>
                </S.Body>
            </S.SidebarMenu>
            <S.BlurBackground />
        </S.Container>
    )
}

export default Sidebar