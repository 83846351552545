import { Flex } from "@chakra-ui/react"
import Panel from "../../../components/Panel"

const Auth: React.FC = ({ children }) => {
    return (
        <Flex
            justify={'center'}
            align={'center'}
            h='100vh'
            backgroundColor='#f7f7f7'>
            <Panel
                title='Gestão de equipes TC'
                flexDir={'column'}
                maxWidth={'400'}>
                {children}
            </Panel >
        </Flex >
    )
}

export default Auth