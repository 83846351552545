import { createContext, useContext, useState } from "react"
import { useToast } from '@chakra-ui/react'
import { Cookies } from 'react-cookie'
import { api } from "../services/api"

type Credentials = {
    email: string
    password: string
}

type UpdateUserProps = {
    name: string
    email: string
    password: string
    newPassword: string
}

type UserProps = {
    id: number
    name: string
    email: string
    permissions: string[]
}

type AuthStateProps = {
    user: UserProps
    isLoading: boolean
}

type AuthContextProps = {
    user: UserProps
    isAuthenticated: boolean
    isLoading: boolean
    signIn(data: Credentials): Promise<void>
    singOut(): void
    updateUser(data: UpdateUserProps): Promise<void>
    hasPermissions(permissions: string[]): boolean
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

export const AuthProvider: React.FC = ({ children, ...props }) => {
    const toast = useToast()
    const [cookie] = useState(new Cookies())
    const [data, setData] = useState<AuthStateProps>(() => {
        const token = cookie.get('tcTeam:token')
        let userStored = localStorage.getItem('tcTeam:user')

        if (token && userStored) {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`

            api.get('/me')
                .then(res => {
                    const userResponse = res.data
                    localStorage.setItem('tcTeam:user', JSON.stringify(userResponse))
                    setData({ user: userResponse, isLoading: false })
                })
        }

        if (userStored) {
            const userStoredParsed: UserProps = JSON.parse(userStored)
            return { user: userStoredParsed, isLoading: false } as AuthStateProps
        }
        return {} as AuthStateProps
    })

    async function signIn({ email, password }: Credentials) {
        setData((oldData) => {
            return { ...oldData, isLoading: true }
        })

        api.post('/sessions', {
            email,
            password
        }).then(async res => {
            const { token } = res.data

            api.defaults.headers.common['Authorization'] = `Bearer ${token}`

            cookie.set('tcTeam:token', token, {
                maxAge: 60 * 60 * 24 * 30,
                path: '/'
            })

            const userResponse = await api.get('/me')
            const user = userResponse.data

            localStorage.setItem('tcTeam:user', JSON.stringify(user))

            setData({ user, isLoading: false })
        })
            .catch(err => {
                const { message } = err.response.data
                toast({
                    title: message,
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
                setData({ isLoading: false } as AuthStateProps)
            })
    }

    function singOut() {
        cookie.remove('tcTeam:token')
        localStorage.removeItem('tcTeam:user')
        setData({} as AuthStateProps)
    }

    async function updateUser({ name, email, password, newPassword }: UpdateUserProps) {
        const newUserProps = {
            name,
            email
        }

        if (password && newPassword) {
            Object.assign(newUserProps, {
                password,
                newPassword
            })
        }

        await api.put('/me', newUserProps)

        setData(oldData => {
            return { ...oldData, user: { ...oldData.user, name, email } }
        })

        toast({
            title: 'Perfil atualizado!',
            status: 'success',
            duration: 4000,
            isClosable: true
        })
    }

    function hasPermissions(permissions: string[]): boolean {
        return data.user.permissions.some(userPermission => permissions.includes(userPermission))
    }

    return (
        <AuthContext.Provider
            value={{
                user: data.user,
                isAuthenticated: !!data.user,
                isLoading: data.isLoading,
                signIn,
                singOut,
                updateUser,
                hasPermissions
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    const context = useContext(AuthContext)
    return context
}