import styled, { css } from 'styled-components'

interface ButtonProps {
    forceAbsolute?: boolean
}

export const Button = styled.button<ButtonProps>`
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    background: #000;

    @media(max-width: 1200px) {
        ${props => !props.forceAbsolute && css`
            margin-left: auto;
            position: relative;
            right: 0;
            bottom: 0;
        `}
    }
`