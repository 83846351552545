import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button, Flex, FormControl, FormLabel, Spinner, Switch } from '@chakra-ui/react'

import { useAuth } from "../../context/AuthContext"

import * as S from './styles'
import Input from "../../components/Forms/Input"

const SignIn: React.FC = () => {
    const { signIn, isLoading } = useAuth()
    const schema = yup.object({
        email: yup.string().required('E-mail é obrigatório').email('E-mail é inválido'),
        password: yup.string().required('Senha é obrigatória')
    }).required()

    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(schema)
    })

    async function handleSignIn(values: any): Promise<void> {
        await signIn(values)
    }

    return (
        <Flex
            as='form'
            flexDir={'column'}
            gap={4}
            onSubmit={handleSubmit(handleSignIn)}>
            <Input
                label='Email'
                placeholder='beltrano@testingcompany.com.br'
                error={formState.errors.email}
                {...register('email')} />
            <Input
                label='Senha'
                placeholder='123456...'
                type='password'
                error={formState.errors.password}
                {...register('password')} />
            <FormControl display='flex' alignItems='center'>
                <Switch id='keep-on' />
                <FormLabel htmlFor='keep-on' m='0' ml='2'>
                    Manter logado
                </FormLabel>
            </FormControl>
            <Button type='submit' isDisabled={isLoading} colorScheme='red' mt='15'>
                {isLoading ? <Spinner /> : 'Logar'}
            </Button>
            <S.PanelBottom>
                <span>Esqueceu a senha?</span>
                <Link to='/esqueci-minha-senha'>Clique aqui</Link>
            </S.PanelBottom>
        </Flex>
    )
}

export default SignIn