import { useEffect, useState } from "react"
import { Flex, Text } from "@chakra-ui/react"

import { api } from "../../../services/api"

import PiChart from "../../../components/Charts/PiChart"
import { MdPieChart } from "react-icons/md"

import * as S from './styles'

interface ProjectProps {
  id: number
  name: string
  type: string
}

interface CustomerChartsProps {
  id: string
  value: number,
  label: string
}

const ProjectsCharts: React.FC = () => {
  const [projectTypes, setProjectTypes] = useState<CustomerChartsProps[]>([])

  useEffect(() => {
    async function loadCustomers() {
      const response = await api.get('/dashboard/projects')
      const customerResponse: ProjectProps[] = response.data

      const projectByPF = customerResponse.filter(cr => cr.type === 'PF')
      const projectByPR = customerResponse.filter(cr => cr.type === 'PR')

      const projectsFormattedByPF = { id: 'Fechados', label: 'Fechados (PF)', value: projectByPF.length }
      const projectsFormattedByPR = { id: 'Recorrentes', label: 'Recorrentes (PR)', value: projectByPR.length }

      setProjectTypes([projectsFormattedByPF, projectsFormattedByPR])
    }
    loadCustomers()
  }, [])

  return (
    <Flex
      position='relative'
      borderRadius='10px'
      alignContent='center'
      justifyContent='center'
      direction='column'
      boxShadow={'0px 2px 5px 1px rgba(0,0,0,0.35)'}
      width='100%'
      minW={0}>
      <Flex position='absolute' top='-20px' left='20px' gap='20px' alignSelf='flex-start'>
        <Flex
          height='70px'
          width='70px'
          alignItems='center'
          justifyContent='center'
          borderRadius={'10px'}
          bg='linear-gradient(#ffa523, #fb8e03)'
          boxShadow='0px 2px 5px 1px rgba(0,0,0,0.35)'>
          <MdPieChart size={30} color='#fff' />
        </Flex>
        <Flex justifyContent='flex-end' direction='column' mt='30px'>
          <Text color='#254568' fontWeight={700}>Projetos por tipo</Text>
          <Text color='#c2c2c2'>Comparação</Text>
        </Flex>
      </Flex>
      <S.LargeChartContainer>
        <PiChart data={projectTypes} showLegend={true} />
      </S.LargeChartContainer>
      <S.SmallChartContainer>
        <PiChart data={projectTypes} isMobile={true} />
      </S.SmallChartContainer>
    </Flex>
  )
}

export default ProjectsCharts