import { useEffect, useState } from "react"
import { Flex, Text } from "@chakra-ui/react"

import { api } from "../../../services/api"

import PiChart from "../../../components/Charts/PiChart"

import * as S from './styles'
import { MdPieChart } from "react-icons/md"

interface CustomerProps {
  id: number
  name: string
  status: string
  hired_hours: number
  resources: any[]
}

interface CustomerChartsProps {
  id: string
  value: number,
  label: string
}

const CustomersCharts: React.FC = () => {
  const [customersByHiredHours, setCustomersByHiredHours] = useState<CustomerChartsProps[]>([])
  const [customersByResources, setCustomersByResources] = useState<CustomerChartsProps[]>([])

  useEffect(() => {
    async function loadCustomers() {
      const response = await api.get('/dashboard/customers')
      const customerResponse: CustomerProps[] = response.data

      const dataByHiredHours =
        customerResponse.filter(cr => cr.status !== 'INATIVO')
          .map(cr => {
            return { id: cr.name, value: cr.hired_hours, label: cr.name }
          })
      setCustomersByHiredHours(dataByHiredHours)

      const dataByResources =
        customerResponse.filter(cr => cr.status !== 'INATIVO')
          .map(cr => { return { id: cr.name, value: cr.resources.length, label: cr.name } })
      setCustomersByResources(dataByResources)
    }
    loadCustomers()
  }, [])

  return (
    <S.ChartLine>
      <Flex
        borderRadius='10px'
        alignContent='center'
        justifyContent='center'
        direction='column'
        boxShadow={'0px 2px 5px 1px rgba(0,0,0,0.35)'}
        width='100%'
        minW={0}>
        <Flex position='absolute' top='50px' left='40px' gap='20px' alignSelf='flex-start'>
          <Flex
            height='70px'
            width='70px'
            alignItems='center'
            justifyContent='center'
            borderRadius={'10px'}
            bg='linear-gradient(#ffa523, #fb8e03)'
            boxShadow='0px 2px 5px 1px rgba(0,0,0,0.35)'>
            <MdPieChart size={30} color='#fff' />
          </Flex>
          <Flex justifyContent='flex-end' direction='column' mt='30px'>
            <Text color='#254568' fontWeight={700}>Clientes por hora contratada</Text>
            <Text color='#c2c2c2'>Comparação</Text>
          </Flex>
        </Flex>
        <S.LargeChartContainer>
          <PiChart data={customersByHiredHours} />
        </S.LargeChartContainer>
        <S.SmallChartContainer>
          <PiChart data={customersByHiredHours} isMobile={true} />
        </S.SmallChartContainer>
      </Flex>
      <Flex
        position='relative'
        borderRadius='10px'
        alignContent='center'
        justifyContent='center'
        direction='column'
        boxShadow={'0px 2px 5px 1px rgba(0,0,0,0.35)'}
        width='100%'
        minW={0}>
        <Flex position='absolute' top='-20px' left='20px' gap='20px' alignSelf='flex-start'>
          <Flex
            height='70px'
            width='70px'
            alignItems='center'
            justifyContent='center'
            borderRadius={'10px'}
            bg='linear-gradient(#ffa523, #fb8e03)'
            boxShadow='0px 2px 5px 1px rgba(0,0,0,0.35)'>
            <MdPieChart size={30} color='#fff' />
          </Flex>
          <Flex justifyContent='flex-end' direction='column' mt='30px'>
            <Text color='#254568' fontWeight={700}>Clientes por quantidade de recurso</Text>
            <Text color='#c2c2c2'>Comparação</Text>
          </Flex>
        </Flex>
        <S.LargeChartContainer>
          <PiChart data={customersByResources} />
        </S.LargeChartContainer>
        <S.SmallChartContainer>
          <PiChart data={customersByResources} isMobile={true} />
        </S.SmallChartContainer>
      </Flex>
    </S.ChartLine>
  )
}

export default CustomersCharts