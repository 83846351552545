import { BrowserRouter } from 'react-router-dom'

import { ChakraProvider } from '@chakra-ui/react'

import { AuthProvider } from './context/AuthContext'

import Routes from './routes'
import Jwt from './components/Jwt'
import Global from './styles/global'

export default function App() {
  return (
    <ChakraProvider>
      <AuthProvider>
        <BrowserRouter>
          <Global />
          <Routes />
          <Jwt />
        </BrowserRouter>
      </AuthProvider>
    </ChakraProvider>
  )
}

