import { ChangeEvent, useEffect, useState } from 'react'
import {
    Avatar,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input as ChakraInput,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Spinner,
    Switch,
    Text,
    useToast,
    WrapItem
} from '@chakra-ui/react'

import { useDisclosure } from '@chakra-ui/hooks'

import queryString from 'query-string'

import { useLocation, useHistory } from 'react-router-dom'

import * as yup from 'yup'

import { HiOutlineExclamationCircle } from 'react-icons/hi'
import { MdPostAdd } from 'react-icons/md'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { api } from '../../services/api'
import CreateButton from '../../components/Buttons/CreateButton'
import Input from '../../components/Forms/Input'

import * as S from './styles'
import { HasPermission } from '../../components/HasPermission'
import { CustomerProps } from './Models'
import CustomersTable from './CustomersTable'
import InputDefault from '../../components/Forms/ChakraInput'
import InputDate from '../../components/Forms/InputDate'
import ptBR from 'date-fns/locale/pt-BR'
import { format, parseISO } from 'date-fns'

interface FilterProps {
    name: string
    qtdPerPage: number
    currentPage: number
}

interface CustomerProjectsProps {
    customer_id: number
    projects: {
        id: number
        name: string
    }[]
    isLoading: boolean
}

const Customers: React.FC = () => {
    const toast = useToast()
    const { search } = useLocation()
    const history = useHistory()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)
    const [loadingCustomers, setLoadingCustomers] = useState(true)
    const [newCustomerStatus, setNewCustomerStatus] = useState(true)
    const [isLoadingCreating, setIsLoadingCreating] = useState(false)
    const [newCustomerImage, setNewCustomerImage] = useState<{ file: File | null, tempImage: string }>({
        file: null,
        tempImage: ''
    })

    const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure()
    const { onOpen: onOpenCustomerStatus, onClose: onCloseCustomerStatus, isOpen: isOpenCustomerStatus } = useDisclosure()
    const { isOpen: isOpenView, onOpen: onOpenView, onClose: onCloseView } = useDisclosure()
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerProps>({} as CustomerProps)
    const [currentSelectedCustomerStatus, setCurrentSelectedCustomerStatus] = useState(false)
    const [isLoadingUpdating, setIsLoadingUpdating] = useState(false)
    const [currentSelectedCustomerProjects, setCurrentSelectedCustomerProjects] = useState<CustomerProjectsProps>()

    const [customers, setCustomers] = useState<CustomerProps[]>([])
    const [customersFiltered, setCustomersFiltered] = useState<CustomerProps[]>([])
    const [filters] = useState<FilterProps>({
        name: '',
        qtdPerPage: 100,
        currentPage: 1
    })

    useEffect(() => {
        async function loadCustomers() {
            const response = await api.get(`/customers?name=${filters.name}&status=&qtdPerPage=${filters.qtdPerPage}&currentPage=${filters.currentPage}`)

            const customersData = response.data.data.map((cd: CustomerProps) => {
                let start_contract_time = null
                if (cd.start_contract_time) start_contract_time = parseISO(cd.start_contract_time + '')

                let end_contract_time = null
                if (cd.end_contract_time) end_contract_time = parseISO(cd.end_contract_time + '')
                return { ...cd, start_contract_time, end_contract_time }
            })
            setCustomers(customersData)
            setCustomersFiltered(customersData)

            setLoadingCustomers(false)
        }

        loadCustomers()

        //eslint-disable-next-line
    }, [filters])

    useEffect(() => {
        if(customers.length > 0) {
            const viewCustomer = queryString.parse(search)
            if (viewCustomer) {
                const customer_id = Number(viewCustomer.visualizar)
    
                const currentCustomer = customers.find((c: CustomerProps) => c.id === customer_id)
                if (currentCustomer) {
                    setSelectedCustomer(currentCustomer)
                    setCurrentSelectedCustomerStatus(currentCustomer.status === 'ATIVO' ? true : false)
                    onOpenView()
                }
            }
        }

    // eslint-disable-next-line
    }, [customers, search])

    const newCustomerSchema = yup.object({
        name: yup.string().required('Nome é obrigatório'),
        hired_hours: yup.number().required('Hora é obrigatória').max(9999, 'Máximo de 9999 horas')
    }).required()

    const {
        control,
        register: registerNewCustomer,
        handleSubmit: handleSubmitNewCustomer,
        formState,
        setValue,
        reset: resetNewCustomerModal,
        clearErrors } = useForm({
            resolver: yupResolver(newCustomerSchema)
        })

    const {
        control: controlUpdate,
        register: registerUpdateCustomer,
        handleSubmit: handleSubmitUpdateCustomer,
        formState: formStateUpdate,
        setValue: setValueUpdate,
        reset: resetUpdateCustomerModal,
        clearErrors: clearUpdateErrors } = useForm({
            resolver: yupResolver(newCustomerSchema)
        })

    function closeNewCustomerModal() {
        setNewCustomerImage({ file: null, tempImage: '' })
        clearErrors()
        resetNewCustomerModal()
        onClose()
    }

    function selectCustomer(customer_id: number) {
        resetUpdateCustomerModal()
        clearUpdateErrors()

        const currentCustomer = customers.find(c => c.id === customer_id)
        if (currentCustomer) {
            setSelectedCustomer(currentCustomer)
            setValueUpdate('start_contract_time', currentCustomer.start_contract_time)
            setValueUpdate('end_contract_time', currentCustomer.end_contract_time)
            setCurrentSelectedCustomerStatus(currentCustomer.status === 'ATIVO' ? true : false)
        }

        onOpenUpdate()
    }

    function viewSelectedCustomer(customer_id: number) {
        const currentCustomer = customers.find(c => c.id === customer_id)
        if (currentCustomer) {
            setSelectedCustomer(currentCustomer)
            setCurrentSelectedCustomerStatus(currentCustomer.status === 'ATIVO' ? true : false)
        }
        onOpenView()
    }

    async function handleCreateNewCustomer(values: any): Promise<void> {
        const {
            name,
            status,
            hired_hours,
            resource_profile,
            start_contract_time,
            end_contract_time,
            responsible_name,
            responsible_email,
            responsible_phone,
            objective } = values
        setIsLoadingCreating(true)

        const formData = new FormData();
        formData.append('name', name)
        formData.append('hired_hours', hired_hours)
        formData.append('status', status ? 'ATIVO' : 'INATIVO')

        formData.append('resource_profile', resource_profile)
        formData.append('start_contract_time', start_contract_time)
        formData.append('end_contract_time', end_contract_time)
        formData.append('responsible_name', responsible_name)
        formData.append('responsible_email', responsible_email)
        formData.append('responsible_phone', responsible_phone)
        formData.append('objective', objective)

        if (newCustomerImage.file) {
            formData.append('image', newCustomerImage.file)
        }
        await api.post('/customers', formData, { headers: { 'content-type': 'multipart/form-data' } })
            .then(res => {
                const response = res.data
                let start_contract_time = null
                if (response.start_contract_time) start_contract_time = parseISO(response.start_contract_time + '')

                let end_contract_time = null
                if (response.end_contract_time) end_contract_time = parseISO(response.end_contract_time + '')
                const newCustomer = { ...response, start_contract_time, end_contract_time }

                const newCustomers = [...customers]
                newCustomers.push(newCustomer)
                setCustomers(newCustomers)
                setCustomersFiltered(newCustomers)

                resetNewCustomerModal()

                toast({
                    title: 'Cliente cadastrado!',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                })

                closeNewCustomerModal()
            })
            .catch(err => {
                toast({
                    title: 'Ocorreu um erro',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            })
        setIsLoadingCreating(false)
    }

    async function handleUpdateCustomer(values: any): Promise<void> {
        setIsLoadingUpdating(true)
        const { name } = values
        await api.put(`/customers/${selectedCustomer.id}`, { ...values, status: currentSelectedCustomerStatus ? 'ATIVO' : 'INATIVO' })
            .then(res => {
                const response = res.data
                let start_contract_time = null
                if (response.start_contract_time) start_contract_time = parseISO(response.start_contract_time + '')

                let end_contract_time = null
                if (response.end_contract_time) end_contract_time = parseISO(response.end_contract_time + '')

                const customerUpdated = { ...response, start_contract_time, end_contract_time }

                const newCustomers = [...customersFiltered]
                const customerIndex = newCustomers.findIndex(c => c.id === selectedCustomer.id)
                newCustomers[customerIndex] = { ...customerUpdated, image_url: selectedCustomer.image_url }
                setCustomers(newCustomers)
                setCustomersFiltered(newCustomers)

                resetUpdateCustomerModal()
                clearUpdateErrors()

                setCurrentSelectedCustomerProjects(undefined)

                toast({
                    title: `${name} atualizado!`,
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                })

                onCloseUpdate()
            })
            .catch(err => {
                toast({
                    title: 'Ocorreu um erro',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            })
        setIsLoadingUpdating(false)
    }

    async function handleNewCustomerImage(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files) {
            const photo = e.target.files[0] as { size: number, type: string }
            const photoDataSize = photo.size > (1024 * 1024) * 5
            const photoDataType = photo.type !== 'image/png' && photo.type !== 'image/jpeg'

            if (photoDataSize) {
                toast({
                    title: 'Foto não pode ser maior que 5mb!',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            }

            if (photoDataType) {
                toast({
                    title: 'Imagem deve ser em formato PNG ou JPEG!',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            }

            if (!photoDataSize && !photoDataType) {
                setNewCustomerImage({ file: e.target.files[0], tempImage: URL.createObjectURL(e.target.files[0]) })
            }
        }
    }

    async function handleImageUpdate(e: ChangeEvent<HTMLInputElement>) {
        if (selectedCustomer && e.target.files) {
            const data = new FormData()
            const photo = e.target.files[0] as { size: number, type: string }
            const photoDataSize = photo.size > (1024 * 1024) * 5
            const photoDataType = photo.type !== 'image/png' && photo.type !== 'image/jpeg'

            if (photoDataSize) {
                toast({
                    title: 'Foto não pode ser maior que 5mb!',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            }

            if (photoDataType) {
                toast({
                    title: 'Imagem deve ser em formato PNG ou JPEG!',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            }

            if (!photoDataSize && !photoDataType) {
                data.append('image', e.target.files[0])

                api.patch(`/customers/${selectedCustomer.id}`, data)
                    .then(res => {
                        const newCustomerUpdated = res.data as CustomerProps
                        const customerImage = newCustomerUpdated.image_url

                        const oldCustomers = [...customers]
                        const customerIndex = oldCustomers.findIndex(r => r.id === selectedCustomer.id)
                        const oldCurrentCustomer = oldCustomers[customerIndex]
                        oldCurrentCustomer.image_url = customerImage
                        oldCustomers[customerIndex] = oldCurrentCustomer
                        setCustomers(oldCustomers)

                        setSelectedCustomer(oldProps => {
                            return { ...oldProps, image_url: customerImage }
                        })

                        toast({
                            title: 'Foto atualizada!',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        })
                    })
                    .catch(err => {
                        toast({
                            title: 'Error ao tentar atualizar foto',
                            status: 'error',
                            duration: 4000,
                            isClosable: true
                        })
                    })
            }
        }
    }

    async function loadCurrentCustomerProjectsToShowAtStatus() {
        if (currentSelectedCustomerProjects?.customer_id !== selectedCustomer.id && currentSelectedCustomerStatus) {
            setCurrentSelectedCustomerProjects((oldData: any) => {
                return { ...oldData, isLoading: true }
            })

            if (selectedCustomer) {
                const res = await api.get(`/customers/${selectedCustomer.id}/projects-in-progress`)
                setCurrentSelectedCustomerProjects({ projects: res.data, customer_id: selectedCustomer.id, isLoading: false })
            }
        }

    }

    async function deleteCustomer(customer_id: number) {
        setIsLoadingDelete(true)

        try {
            await api.delete(`/customers/${customer_id}`)

            const newCustomers = [...customersFiltered]
            const customerIndex = newCustomers.findIndex(c => c.id === customer_id)
            newCustomers.splice(customerIndex, 1)
            setCustomersFiltered(newCustomers)
            setCustomers(newCustomers)

            toast({
                title: 'Cliente deletado!',
                status: 'success',
                duration: 4000,
                isClosable: true
            })

        } catch (err: any) {
            toast({
                title: 'Ocorreu um erro',
                status: 'error',
                duration: 4000,
                isClosable: true
            })
        }

        setIsLoadingDelete(false)
    }

    function typeOfCustomers(customerStatus: string): CustomerProps[] {
        const customersOfStatus = customersFiltered.filter(c => c.status === customerStatus)

        return customersOfStatus
    }

    function filterCustomersByName(name: string) {
        if (name !== '' || !name.match(/\s\s+/g)) {
            setTimeout(() => {
                let newCustomersFiltered = customers.filter((r: CustomerProps) => r.name.toLocaleLowerCase().includes(name.toLowerCase()))
                setCustomersFiltered(newCustomersFiltered)
            }, 1000)
        } else {
            setCustomersFiltered(customers)
        }
    }

    function onCloseCustomerView() {
        history.replace('/clientes')

        onCloseView()
    }

    return (
        <S.Container>
            <Flex alignItems={'center'}>
                <Flex marginLeft='auto' alignItems={'center'} gap='10px'>
                    <InputDefault onChange={(e) => filterCustomersByName(e.target.value)} size='md' name='name' placeholder='Insira o nome do cliente' />
                </Flex>
            </Flex>
            <S.TableLineContainer>
                <CustomersTable
                    customers={typeOfCustomers('ATIVO')}
                    deleteCustomer={deleteCustomer}
                    loadingCustomers={loadingCustomers}
                    selectCustomer={selectCustomer}
                    viewSelectedCustomer={viewSelectedCustomer}
                    isLoadingDelete={isLoadingDelete}
                    panelTitle='Ativos' />
                <CustomersTable
                    customers={typeOfCustomers('INATIVO')}
                    deleteCustomer={deleteCustomer}
                    loadingCustomers={loadingCustomers}
                    selectCustomer={selectCustomer}
                    viewSelectedCustomer={viewSelectedCustomer}
                    isLoadingDelete={isLoadingDelete}
                    panelTitle='Inativos' />
            </S.TableLineContainer>
            <HasPermission permissions={['create_customer']}>
                <CreateButton tooltipText='cliente' icon={MdPostAdd} onClick={onOpen} />
            </HasPermission>
            <Modal isOpen={isOpen} onClose={closeNewCustomerModal}>
                <ModalOverlay />
                <ModalContent>
                    <Box as='form' onSubmit={handleSubmitNewCustomer(handleCreateNewCustomer)}>
                        <ModalHeader>Cadastro de clientes</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex
                                flexDir={'column'}
                                gap={4}>
                                <Flex
                                    alignItems='center'
                                    justifyContent='center'>
                                    <WrapItem
                                        position='relative'>
                                        <Avatar
                                            border='2px solid red'
                                            size='2xl'
                                            src={newCustomerImage.tempImage} />
                                        <FormLabel
                                            cursor='pointer'
                                            htmlFor='customer_image'
                                            position='absolute'
                                            height={'100%'}
                                            width={'100%'}>
                                            <ChakraInput
                                                display='none'
                                                id='customer_image'
                                                type='file'
                                                onChange={handleNewCustomerImage} />
                                        </FormLabel>
                                    </WrapItem>
                                </Flex>
                                <Input
                                    label='Nome'
                                    placeholder='W3haus, Paquetá...'
                                    error={formState.errors.name}
                                    {...registerNewCustomer('name')} />
                                <Flex flexDirection='column' gap='15px'>
                                    <Text fontSize='20px'>Informações de contrato</Text>
                                    <Flex>
                                        <FormControl>
                                            <FormLabel htmlFor='hired-hours'>Horas contratadas</FormLabel>
                                            <NumberInput
                                                size='md'
                                                width={'100px'}
                                                defaultValue={0}
                                                onChange={(value) => setValue('hired_hours', value)}
                                                min={0}
                                                max={9999}>
                                                <NumberInputField id='hired-hours' {...registerNewCustomer('hired_hours')} />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                        </FormControl>
                                        <FormControl>
                                            <Box maxW={'max-content'}>
                                                <FormLabel htmlFor='keep-on'>{newCustomerStatus ? 'Ativo' : 'Inativo'}</FormLabel>
                                                <Switch
                                                    id='keep-on'
                                                    defaultChecked={newCustomerStatus}
                                                    {...registerNewCustomer('status', { onChange: () => setNewCustomerStatus(!newCustomerStatus) })} />
                                            </Box>
                                        </FormControl>
                                    </Flex>
                                    <Flex justifyContent='space-between' gap={10}>
                                        <FormControl>
                                            <Controller
                                                control={control}
                                                name="start_contract_time"
                                                render={({ field: { onChange, value } }) => (
                                                    <InputDate
                                                        label='Início do contrato'
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={ptBR}
                                                        onChange={onChange}
                                                        onBlur={onChange}
                                                        selected={value}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Controller
                                                control={control}
                                                name="end_contract_time"
                                                render={({ field: { onChange, value } }) => (
                                                    <InputDate
                                                        label='Fim do contrato'
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={ptBR}
                                                        onChange={onChange}
                                                        onBlur={onChange}
                                                        selected={value}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Flex>
                                </Flex>
                                <Flex flexDirection='column' gap='15px'>
                                    <Text fontSize='20px'>Informações do projeto</Text>
                                    <Input
                                        label='Perfil do recurso'
                                        placeholder=''
                                        error={formState.errors.resource_profile}
                                        {...registerNewCustomer('resource_profile')} />
                                    <Input
                                        label='Objetivo do cliente'
                                        placeholder=''
                                        error={formState.errors.objective}
                                        {...registerNewCustomer('objective')} />
                                </Flex>
                                <Flex flexDirection='column' gap='15px'>
                                    <Text fontSize='20px'>Informações do responsável</Text>
                                    <Flex gap={5}>
                                        <Input
                                            label='Nome'
                                            placeholder=''
                                            error={formState.errors.responsible_name}
                                            {...registerNewCustomer('responsible_name')} />
                                        <Input
                                            type='number'
                                            label='Telefone'
                                            placeholder=''
                                            error={formState.errors.responsible_phone}
                                            {...registerNewCustomer('responsible_phone')} />
                                    </Flex>
                                    <Input
                                        label='E-mail'
                                        placeholder=''
                                        error={formState.errors.responsible_email}
                                        {...registerNewCustomer('responsible_email')} />
                                </Flex>
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button type='submit' colorScheme='blue' disabled={isLoadingCreating}>{isLoadingCreating ? <Spinner /> : 'Cadastrar'}</Button>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenUpdate} onClose={onCloseUpdate}>
                <ModalOverlay />
                <ModalContent>
                    <Box as='form' onSubmit={handleSubmitUpdateCustomer(handleUpdateCustomer)}>
                        <ModalHeader>{selectedCustomer.name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex
                                alignItems='center'
                                justifyContent='center'>
                                <WrapItem
                                    position='relative'>
                                    <Avatar
                                        border='2px solid red'
                                        size='2xl'
                                        name={selectedCustomer?.name}
                                        src={selectedCustomer?.image_url} />
                                    <FormLabel
                                        cursor='pointer'
                                        htmlFor='customer_image'
                                        position='absolute'
                                        height={'100%'}
                                        width={'100%'}>
                                        <ChakraInput
                                            display='none'
                                            id='customer_image'
                                            type='file'
                                            onChange={handleImageUpdate} />
                                    </FormLabel>
                                </WrapItem>
                            </Flex>
                            <Flex
                                flexDir={'column'}
                                gap={4}>
                                <Input
                                    defaultValue={selectedCustomer.name}
                                    label='Nome'
                                    placeholder='W3haus, Paquetá...'
                                    error={formStateUpdate.errors.name}
                                    {...registerUpdateCustomer('name')} />
                                <Flex flexDirection='column' gap='15px'>
                                    <Text fontSize='20px'>Informações de contrato</Text>
                                    <Flex flexDir='column' gap='15px'>
                                        <Flex>
                                            <FormControl>
                                                <FormLabel htmlFor='hired-hours'>Horas contratadas</FormLabel>
                                                <NumberInput
                                                    size='md'
                                                    width={'100px'}
                                                    defaultValue={selectedCustomer.hired_hours}
                                                    onChange={(value) => setValueUpdate('hired_hours', value)}
                                                    min={0}
                                                    max={9999}>
                                                    <NumberInputField id='hired-hours' {...registerUpdateCustomer('hired_hours')} />
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                            </FormControl>
                                            <FormControl>
                                                <Box maxW={'max-content'}>
                                                    <FormLabel htmlFor='keep-on'>STATUS</FormLabel>
                                                    <Popover
                                                        onOpen={onOpenCustomerStatus}
                                                        onClose={onCloseCustomerStatus}
                                                        returnFocusOnClose={false}
                                                        isOpen={isOpenCustomerStatus}
                                                        placement='bottom'
                                                        closeOnBlur={true} >
                                                        <PopoverTrigger>
                                                            <Button
                                                                onClick={() => loadCurrentCustomerProjectsToShowAtStatus()}
                                                                rightIcon={<HiOutlineExclamationCircle />}
                                                                colorScheme={currentSelectedCustomerStatus ? 'blue' : 'red'}>
                                                                {currentSelectedCustomerStatus ? 'ATIVO' : 'INATIVO'}
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent>
                                                            <PopoverArrow />
                                                            <PopoverCloseButton />
                                                            {currentSelectedCustomerProjects?.isLoading ? (
                                                                <>
                                                                    <PopoverHeader>
                                                                        <Flex direction='column' gap='10px' mr='20px'>
                                                                            Tem certeza que quer inativar?
                                                                        </Flex>
                                                                    </PopoverHeader>
                                                                    <PopoverBody display='flex' justifyContent='center' alignItems='center'>
                                                                        <Spinner size='md' />
                                                                    </PopoverBody>
                                                                    <PopoverFooter display='flex' justifyContent={'flex-end'}>
                                                                        <Button disabled>Desativar</Button>
                                                                    </PopoverFooter>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <PopoverHeader>
                                                                        <Flex direction='column' gap='10px' mr='20px'>
                                                                            <Text fontSize='18px'>
                                                                                {currentSelectedCustomerStatus ? `Tem certeza que quer inativar?` : `Ativar este cliente?`}
                                                                            </Text>
                                                                        </Flex>
                                                                    </PopoverHeader>
                                                                    <PopoverBody>
                                                                        <Text>
                                                                            {currentSelectedCustomerStatus ? (
                                                                                currentSelectedCustomerProjects?.projects.length === 0 ?
                                                                                    `O cliente não tem nenhum projeto em andamento, portanto sua desativação não impactará em nenhum projeto.`
                                                                                    :
                                                                                    `Este cliente tem ${currentSelectedCustomerProjects?.projects.length} projeto(s) em andamento, em caso afirmativo, os projetos serão marcados como concluídos e arquivados.`
                                                                            ) :
                                                                                `O cliente irá ser ativado, e poderão ser criados novos projetos para o mesmo.`
                                                                            }
                                                                        </Text>
                                                                    </PopoverBody>
                                                                    <PopoverFooter display='flex' justifyContent={'flex-end'}>
                                                                        <Button
                                                                            onClick={() => {
                                                                                setCurrentSelectedCustomerStatus(!currentSelectedCustomerStatus)
                                                                                onCloseCustomerStatus()
                                                                            }}
                                                                            colorScheme={!currentSelectedCustomerStatus ? 'blue' : 'red'}>
                                                                            {currentSelectedCustomerStatus ? 'DESATIVAR' : 'ATIVAR'}
                                                                        </Button>
                                                                    </PopoverFooter>
                                                                </>
                                                            )}
                                                        </PopoverContent>
                                                    </Popover>
                                                </Box>
                                            </FormControl>
                                        </Flex>
                                        <Flex justifyContent='space-between' gap={10}>
                                            <FormControl>
                                                <Controller
                                                    control={controlUpdate}
                                                    name="start_contract_time"
                                                    render={({ field: { onChange, value } }) => (
                                                        <InputDate
                                                            label='Início do contrato'
                                                            dateFormat="dd/MM/yyyy"
                                                            locale={ptBR}
                                                            onChange={onChange}
                                                            onBlur={onChange}
                                                            selected={value}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <Controller
                                                    control={controlUpdate}
                                                    name="end_contract_time"
                                                    render={({ field: { onChange, value } }) => (
                                                        <InputDate
                                                            label='Fim do contrato'
                                                            dateFormat="dd/MM/yyyy"
                                                            locale={ptBR}
                                                            onChange={onChange}
                                                            onBlur={onChange}
                                                            selected={value}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex flexDirection='column' gap='15px'>
                                    <Text fontSize='20px'>Informações do projeto</Text>
                                    <Input
                                        defaultValue={selectedCustomer.resource_profile}
                                        label='Perfil do recurso'
                                        placeholder=''
                                        error={formState.errors.resource_profile}
                                        {...registerUpdateCustomer('resource_profile')} />
                                    <Input
                                        defaultValue={selectedCustomer.objective}
                                        label='Objetivo do projeto'
                                        placeholder=''
                                        error={formState.errors.objective}
                                        {...registerUpdateCustomer('objective')} />
                                </Flex>
                                <Flex flexDirection='column' gap='15px'>
                                    <Text fontSize='20px'>Informações do responsável</Text>
                                    <Flex gap={5}>
                                        <Input
                                            defaultValue={selectedCustomer.responsible_name}
                                            label='Nome'
                                            placeholder=''
                                            error={formState.errors.responsible_name}
                                            {...registerUpdateCustomer('responsible_name')} />
                                        <Input
                                            defaultValue={selectedCustomer.responsible_phone}
                                            type='number'
                                            label='Telefone'
                                            placeholder=''
                                            error={formState.errors.responsible_phone}
                                            {...registerUpdateCustomer('responsible_phone')} />
                                    </Flex>
                                    <Input
                                        defaultValue={selectedCustomer.responsible_email}
                                        label='E-mail'
                                        placeholder=''
                                        error={formState.errors.responsible_email}
                                        {...registerUpdateCustomer('responsible_email')} />
                                </Flex>
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={onCloseUpdate}>
                                Cancelar
                            </Button>
                            <Button type='submit' colorScheme='blue' disabled={isLoadingUpdating}>{isLoadingUpdating ? <Spinner /> : 'Atualizar'}</Button>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenView} onClose={onCloseCustomerView}>
                <ModalOverlay />
                <ModalContent>
                    <Box>
                        <ModalHeader>{selectedCustomer.name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex
                                alignItems='center'
                                justifyContent='center'>
                                <WrapItem
                                    position='relative'>
                                    <Avatar
                                        border='2px solid red'
                                        size='2xl'
                                        name={selectedCustomer?.name}
                                        src={selectedCustomer?.image_url} />
                                </WrapItem>
                            </Flex>
                            <Flex
                                flexDir={'column'}
                                gap={4}>
                                <FormControl>
                                    <FormLabel fontWeight={600}>Nome</FormLabel>
                                    <Text>{selectedCustomer.name}</Text>
                                </FormControl>
                                <Flex>
                                    <FormControl>
                                        <FormLabel fontWeight={600}>Horas contratadas</FormLabel>
                                        <Text>{selectedCustomer.hired_hours}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <Box maxW={'max-content'}>
                                            <FormLabel fontWeight={600}>STATUS</FormLabel>
                                            <Text>{currentSelectedCustomerStatus ? 'ATIVO' : 'INATIVO'}</Text>
                                        </Box>
                                    </FormControl>
                                </Flex>
                                <Flex>
                                    <FormControl>
                                        <FormLabel fontWeight={600}>Início do contrato</FormLabel>
                                        {selectedCustomer.start_contract_time && (
                                            <Text>{format(selectedCustomer.start_contract_time, 'dd/MM/yyyy')}</Text>
                                        )}
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={600}>Fim do contrato</FormLabel>
                                        {selectedCustomer.end_contract_time && (
                                            <Text>{format(selectedCustomer.end_contract_time, 'dd/MM/yyyy')}</Text>
                                        )}
                                    </FormControl>
                                </Flex>
                                <Flex direction='column' gap={4}>
                                    <Text fontSize='20px'>Informações do projeto</Text>
                                    <FormControl>
                                        <FormLabel fontWeight={600}>Perfil do recurso</FormLabel>
                                        <Text>{selectedCustomer.resource_profile}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={600}>Objetivo do projeto</FormLabel>
                                        <Text>{selectedCustomer.objective}</Text>
                                    </FormControl>
                                </Flex>
                                <Flex direction='column' gap={4}>
                                    <Text fontSize='20px'>Informações do responsável</Text>
                                    <Flex gap={4}>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Nome</FormLabel>
                                            <Text>{selectedCustomer.responsible_name}</Text>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Telefone</FormLabel>
                                            <Text>{selectedCustomer.responsible_phone}</Text>
                                        </FormControl>
                                    </Flex>
                                    <FormControl>
                                        <FormLabel fontWeight={600}>E-mail</FormLabel>
                                        <Text>{selectedCustomer.responsible_email}</Text>
                                    </FormControl>
                                </Flex>
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={onCloseCustomerView}>
                                Fechar
                            </Button>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
        </S.Container >
    )
}

export default Customers