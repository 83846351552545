import { Switch } from 'react-router-dom'
import Customers from '../pages/Customers'
import Dashboard from '../pages/Dashboard'
import GeralVision from '../pages/GeralVision'
import Skills from '../pages/Skills'
import MyProfile from '../pages/MyProfile'
import PasswordRecovery from '../pages/PasswordRecovery'
import PasswordReset from '../pages/PasswordReset'
import Projects from '../pages/Projects'
import Resources from '../pages/Resources'
import Roles from '../pages/Roles'
import SignIn from '../pages/SignIn'
import Users from '../pages/Users'
import Router from './Router'
import ResetPassRouter from './ResetPassRouter'
import ErrorRouter from './ErrorRouter'
import NotFound from '../pages/Errors/NotFound'
import ResourceProjects from '../pages/ResourceProfile/ResourceProjects'
import Feedbacks from '../pages/ResourceProfile/Feedbacks'
import ResourceProfileRouter from './ResourceProfileRouter'
import Profile from '../pages/ResourceProfile/Profile'
import ResourceSkill from '../pages/ResourceProfile/ResourceSkill'

export default function Routes() {
    return (
        <Switch>
            <Router exact path='/meu-perfil' component={MyProfile} />

            <Router exact path='/' component={GeralVision} />
            
            <Router exact path='/recursos' component={Resources} permissions={['view_resources', 'create_resource', 'update_resource']} />
            <ResourceProfileRouter exact path='/recursos/:resource_id' component={Profile} permissions={['view_resources']} />
            <ResourceProfileRouter exact path='/recursos/:resource_id/projetos' component={ResourceProjects} permissions={['view_resources']} />
            <ResourceProfileRouter exact path='/recursos/:resource_id/feedbacks' component={Feedbacks} permissions={['view_resources']} />
            <ResourceProfileRouter exact path='/recursos/:resource_id/skills' component={ResourceSkill} permissions={['view_resources']} />
            
            <Router exact path='/dashboard' component={Dashboard} />
            <Router exact path='/clientes' component={Customers} permissions={['view_customers', 'create_customer', 'update_customer', 'delete_customer']} />
            <Router exact path='/projetos' component={Projects} permissions={['view_projects', 'create_project', 'update_project']} />
            <Router exact path='/usuarios' component={Users} permissions={['view_users', 'create_user', 'update_user', 'delete_user']} />
            <Router exact path='/grupo-de-permissoes' component={Roles} permissions={['view_roles', 'create_role', 'update_role', 'delete_role']} />
            <Router exact path='/skills' component={Skills} permissions={['view_skills', 'create_skill', 'update_skill', 'delete_skill']} />

            <Router exact path='/login' component={SignIn} isPrivate={false} />
            <Router exact path='/esqueci-minha-senha' component={PasswordRecovery} isPrivate={false} />
            <ResetPassRouter exact path='/resetar-minha-senha/:token' component={PasswordReset} isPrivate={false} />

            <ErrorRouter exact component={NotFound} />
        </Switch>
    )
}