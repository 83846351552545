import { ChangeEvent, useEffect, useState, useMemo } from 'react'
import { v4 } from 'uuid'
import {
    Avatar,
    Box,
    Button,
    CheckboxGroup,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input as ChakraInput,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Spinner,
    Text,
    useToast,
    Select as ChakraSelect,
    WrapItem,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Checkbox,
    Switch
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/hooks'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch, Controller } from 'react-hook-form'
import * as yup from 'yup'

import { api } from '../../services/api'
import CreateButton from '../../components/Buttons/CreateButton'
import Input from '../../components/Forms/Input'
import * as S from './styles'
import { IoMdPersonAdd } from 'react-icons/io'
import { HasPermission } from '../../components/HasPermission'
import { ResourceProjectsProps, ClassificationProps, ResourceProps, TypesProps } from './Models'
import ResourcesTable from './ResourcesTable'
import InputDefault from '../../components/Forms/ChakraInput'
import InputDate from '../../components/Forms/InputDate'
import ptBR from 'date-fns/locale/pt-BR'
import { format, parseISO } from 'date-fns'

interface ResourceProjectStatusProps {
    isLoading: boolean
    resource_id: number
    projects_responsible: number
    projects_as_resource: number
}

const Resources: React.FC = () => {
    const toast = useToast()

    const [showNotActiveResources, setShowNotActiveResources] = useState(false)
    const { isOpen: isOpenNew, onOpen: onOpenNew, onClose: onCloseNew } = useDisclosure()
    const [loadingResources, setLoadingResources] = useState(true)
    const [isLoadingCreating, setIsLoadingCreating] = useState(false)
    const [newResourceImage, setNewResourceImage] = useState<{ file: File | null, tempImage: string }>({
        file: null,
        tempImage: ''
    })

    const { isOpen: isOpenView, onOpen: onOpenView, onClose: onCloseView } = useDisclosure()
    const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure()
    const [selectedResource, setSelectedResource] = useState<ResourceProps>()
    const [isLoadingUpdating, setIsLoadingUpdating] = useState(false)

    const [selectResourceId, setSelectResourceId] = useState(-1)
    const [selectResourceProjectLists, setSelectResourceProjectLists] = useState<ResourceProjectsProps>()
    const [loadingResourceProjectLists, setLoadingResourceProjectLists] = useState(false)

    const [types, setTypes] = useState<TypesProps[]>([])

    const [classifications, setClassifications] = useState<ClassificationProps[]>([])
    const [resources, setResources] = useState<ResourceProps[]>([])
    const [resourcesFiltered, setResourcesFiltered] = useState<ResourceProps[]>([])
    const [resourceProjectStatus, setResourceProjectStatus] = useState<ResourceProjectStatusProps>()

    useEffect(() => {
        async function loadResources() {
            const response = await api.get(`/resources`)
            const currentResources = response.data.map((cr: ResourceProps) => {
                let admission_date = null
                if(cr.admission_date) admission_date = parseISO(cr.admission_date + '')

                let vacation_date = null
                if(cr.vacation_date) vacation_date = parseISO(cr.vacation_date + '')
                return { ...cr, uuid1: v4(), uuid2: v4(), uuid3: v4(), admission_date, vacation_date }
            })
            setResources(currentResources)
            setResourcesFiltered(currentResources)
            setLoadingResources(false)
        }

        async function loadTypes() {
            await api.get('/types')
                .then(res => {
                    setTypes(res.data)
                })
        }

        async function loadClassifications() {
            const response = await api.get('/classifications')
            setClassifications(response.data)
        }

        loadResources()
        loadTypes()
        loadClassifications()
    }, [])

    const newResourceSchema = yup.object({
        name: yup.string().required('Nome é obrigatório'),
        email: yup.string().required('E-mail é obrigatório').email('E-mail é inválido'),
        admission_date: yup.date().nullable().required('Admissão é obrigatória'),
        hours_amount: yup.number().required('Horas é obrigatório'),
        status: yup.mixed().oneOf([
            'ATIVO',
            'FERIAS',
            'TREINAMENTO',
            'INATIVO'], 'Status é obrigatório'),
        classification_id: yup.number().min(1, 'Classificação é obrigatória'),
        types_ids: yup.array().when('classification_id', (classification_id, types_ids) => {
            return classification_id === 4 ? types_ids.min(1, 'Selecione pelo menos um tipo').required('Selecione pelo menos um tipo') : types_ids
        })
    })

    const {
        control,
        register: registerNewResource,
        handleSubmit: handleSubmitNewResource,
        setValue,
        formState,
        reset: resetNewResourceModal,
        clearErrors: clearNewErrors } = useForm({
            resolver: yupResolver(newResourceSchema)
        })

    const watchHoursAmount = useWatch({ control, name: 'hours_amount' })
    const watchClassification = useWatch({ control, name: 'classification_id' })
    const watchStatus = useWatch({ control, name: 'status' })

    const {
        control: controlUpdate,
        setValue: setValueUpdate,
        register: registerUpdateResource,
        handleSubmit: handleSubmitUpdateResource,
        formState: formStateUpdate,
        reset: resetUpdateResourceModal,
        clearErrors: clearUpdateErrors } = useForm({
            resolver: yupResolver(newResourceSchema)
        })

    const watchHoursAmountUpdate = useWatch({ control: controlUpdate, name: 'hours_amount' })
    const watchClassificationUpdate = useWatch({ control: controlUpdate, name: 'classification_id' })
    const watchStatusUpdate = useWatch({ control: controlUpdate, name: 'status' })

    function openNewResourceModal() {
        if (isOpenNew) {
            onCloseNew()
        } else {
            setNewResourceImage({ file: null, tempImage: '' })
            resetNewResourceModal()
            clearNewErrors()
            onOpenNew()
        }
    }

    function selectResource(resource_id: number) {
        setResourceProjectStatus(undefined)
        resetUpdateResourceModal()
        clearUpdateErrors()

        const currentResource = resources.find(r => r.id === resource_id)
        if (currentResource) {
            setSelectedResource(currentResource)
            setValueUpdate('admission_date', currentResource.admission_date)
            setValueUpdate('vacation_date', currentResource.vacation_date)
            setValueUpdate('types_ids', currentResource.__types__.map(t => t.id + ''))
            setValueUpdate('status', currentResource.resourceStatus.status.name)
            const classification_id = currentResource.resourceClassification?.classification.id
            if (classification_id === 4) setValueUpdate('classification_id', '4')
        }

        onOpenUpdate()
    }

    function viewSelectedResource(resource_id: number) {
        const currentResource = resources.find(r => r.id === resource_id)
        if (currentResource) setSelectedResource(currentResource)

        onOpenView()
    }

    async function handleCreateNewResource(values: any): Promise<void> {
        setIsLoadingCreating(true)
        const {
            name,
            email,
            hours_amount,
            classification_id,
            leader,
            types_ids,
            status,
            admission_date,
            vacation_date
        } = values

        let typesIds = []

        if (types_ids) {
            typesIds = types_ids.map((id: string) => {
                return Number(id)
            })
        }

        try {
            const response = await api.post('/resources', {
                name,
                email,
                hours_amount,
                status,
                leader,
                classification_id,
                types_ids: typesIds,
                admission_date,
                vacation_date
            })

            const resourceCreated = response.data

            const { id } = resourceCreated

            let admission_date_formatted = parseISO(resourceCreated.admission_date)
            let vacation_date_formatted = parseISO(resourceCreated.vacation_date)

            if (newResourceImage.file) {
                const data = new FormData()
                data.append('photo', newResourceImage.file)
                const responsePhotoUpdated = await api.patch(`/resources/${id}`, data)
                const newResourceUpdated = responsePhotoUpdated.data as ResourceProps

                const newResources = [...resources]
                const newResource = { ...newResourceUpdated, hours_left: hours_amount, uuid1: v4(), uuid2: v4(), uuid3: v4(),
                    admission_date: admission_date_formatted, vacation_date: vacation_date_formatted }
                newResources.push(newResource)
                setResources(newResources)
                setResourcesFiltered(newResources)
            } else {
                const newResources = [...resources]
                
                const newResource = { ...response.data, hours_left: hours_amount, uuid1: v4(), uuid2: v4(), uuid3: v4(),
                    admission_date: admission_date_formatted, vacation_date: vacation_date_formatted }
                newResources.push(newResource)
                setResources(newResources)
                setResourcesFiltered(newResources)
            }

            toast({
                title: 'Recurso cadastrado!',
                status: 'success',
                duration: 4000,
                isClosable: true
            })

            onCloseNew()

            resetNewResourceModal()

        } catch (err: any) {
            const { message } = err.response.data
            toast({
                title: `${message}`,
                status: 'error',
                duration: 4000,
                isClosable: true
            })
        }
        setIsLoadingCreating(false)
    }

    async function handleUpdateResource(values: any): Promise<void> {
        const { name } = values

        if (selectedResource) {
            setIsLoadingUpdating(true)
            await api.put(`/resources/${selectedResource.id}`, values)
                .then(res => {
                    const resourceUpdated = res.data
                    const newResources = [...resources]
                    const resourceIndex = newResources.findIndex(c => c.id === selectedResource.id)

                    let admission_date = parseISO(resourceUpdated.admission_date)
                    let vacation_date = parseISO(resourceUpdated.vacation_date)

                    newResources[resourceIndex] = {
                        ...resourceUpdated,
                        photo_url: selectedResource.photo_url,
                        uuid1: selectedResource.uuid1, uuid2: selectedResource.uuid2, uuid3: selectedResource.uuid3,
                        admission_date, vacation_date
                    }
                    setResources(newResources)
                    setResourcesFiltered(newResources)

                    resetUpdateResourceModal()
                    clearUpdateErrors()
                    onCloseUpdate()

                    toast({
                        title: `${name} atualizado!`,
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    })
                })
                .catch(err => {
                    toast({
                        title: `${err}`,
                        status: 'error',
                        duration: 4000,
                        isClosable: true
                    })
                })
        }

        setIsLoadingUpdating(false)
    }

    async function handlePhotoUpdate(e: ChangeEvent<HTMLInputElement>) {
        if (selectedResource && e.target.files) {
            const data = new FormData()
            const photo = e.target.files[0] as { size: number, type: string }
            const photoDataSize = photo.size > (1024 * 1024) * 5
            const photoDataType = photo.type !== 'image/png' && photo.type !== 'image/jpeg'

            if (photoDataSize) {
                toast({
                    title: 'Foto não pode ser maior que 5mb!',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            }

            if (photoDataType) {
                toast({
                    title: 'Imagem deve ser em formato PNG ou JPEG!',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            }

            if (!photoDataSize && !photoDataType) {
                data.append('photo', e.target.files[0])

                api.patch(`/resources/${selectedResource.id}`, data)
                    .then(res => {
                        const newResourceUpdated = res.data as ResourceProps
                        const resourcePhoto = newResourceUpdated.photo_url

                        const oldResources = [...resources]
                        const resourceIndex = oldResources.findIndex(r => r.id === selectedResource.id)
                        const oldCurrentResource = oldResources[resourceIndex]
                        oldCurrentResource.photo_url = resourcePhoto
                        oldResources[resourceIndex] = oldCurrentResource
                        setResources(oldResources)

                        setSelectedResource((oldProps: any) => {
                            return { ...oldProps, photo_url: resourcePhoto }
                        })

                        toast({
                            title: 'Foto atualizada!',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        })
                    })
                    .catch(err => {
                        const { response } = err
                        toast({
                            title: `${response.data.message}`,
                            status: 'error',
                            duration: 4000,
                            isClosable: true
                        })
                    })
            }
        }
    }

    async function handleNewResourcePhoto(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files) {
            const photo = e.target.files[0] as { size: number, type: string }
            const photoDataSize = photo.size > (1024 * 1024) * 5
            const photoDataType = photo.type !== 'image/png' && photo.type !== 'image/jpeg'

            if (photoDataSize) {
                toast({
                    title: 'Foto não pode ser maior que 5mb!',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            }

            if (photoDataType) {
                toast({
                    title: 'Imagem deve ser em formato PNG ou JPEG!',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            }

            if (!photoDataSize && !photoDataType) {
                setNewResourceImage({ file: e.target.files[0], tempImage: URL.createObjectURL(e.target.files[0]) })
            }
        }
    }

    const resourceStatusDescription = (status: string, hours_amount: number, classification?: string): { description: string, color: string } => {
        switch (status) {
            case 'ATIVO':
                return { description: 'Ativo', color: 'green' }
            case 'DISPONIVEL':
                return hours_amount > 0 || classification === 'Gestor' ? { description: 'Disponível', color: 'green' } : { description: 'Indisponível', color: 'orange' }
            case 'PARCIALMENTE':
                return { description: 'Parcialmente', color: 'blue' }
            case 'ALOCADO':
                return { description: 'Alocado', color: 'red' }
            case 'FERIAS':
                return { description: 'Férias', color: 'yellow' }
            case 'TREINAMENTO':
                return { description: 'Treinamento', color: 'yellow' }
            case 'INATIVO':
                return { description: 'Inativo', color: 'red' }
            default:
                return { description: 'Outros', color: 'gray' }
        }
    }

    const typeOfResources = (tableName: string) => {
        if (tableName === 'Alocados') {
            return resourcesFiltered.filter(r => {
                const isManager = r.resourceClassification?.classification.description !== 'Gestor'
                const isActive = r.resourceStatus.status.name === 'ATIVO'
                const isPartial = r.resourceStatus.substatus?.name === 'PARCIALMENTE'
                const isAlocated = r.resourceStatus.substatus?.name === 'ALOCADO'
                const hasProjects = r.has_projects

                return r && isActive && isManager && hasProjects && (isPartial || isAlocated)
            })
        }

        else if (tableName === 'Disponíveis') {
            return resourcesFiltered.filter(r => {
                const isManager = r.resourceClassification?.classification.description !== 'Gestor'
                const isActive = r.resourceStatus.status.name === 'ATIVO'
                const isPartial = r.resourceStatus.substatus?.name === 'PARCIALMENTE'
                const isAvailable = r.resourceStatus.substatus?.name === 'DISPONIVEL'
                const hasHours = r.hours_amount > 0

                return r && isActive && isManager && hasHours && (isPartial || isAvailable)
            })
        }

        else if (tableName === 'Indisponíveis') {
            return resourcesFiltered.filter(r => {
                const isManager = r.resourceClassification?.classification.description !== 'Gestor'
                const isOnVacation = r.resourceStatus.status.name === 'FERIAS'
                const isOnTraining = r.resourceStatus.status.name === 'TREINAMENTO'
                const isZerado = r.hours_amount === 0
                const isNotActive = r.resourceStatus.status.name !== 'INATIVO'

                return isManager && isNotActive && (isZerado || isOnTraining || isOnVacation)
            })
        }

        else if (tableName === 'Outros') {
            return resourcesFiltered.filter(r => {
                const isActive = r.resourceStatus.status.name !== 'INATIVO'
                const isNotTester = r.resourceClassification?.classification.description !== 'Testador'
                return isActive && isNotTester
            })

        } else if (tableName === 'Sobrecarga') {
            return resourcesFiltered.filter(r => {
                const isManager = r.resourceClassification?.classification.description !== 'Gestor'
                const isHoursNegative = r.hours_left < 0
                return isManager && isHoursNegative
            })

        } else {
            const resourcesOfStatus = resourcesFiltered.filter(r => {
                const isInactive = r.resourceStatus.status.name === 'INATIVO'
                return isInactive
            })
            return resourcesOfStatus
        }
    }

    async function verifyResourceProjectsStatus(e: ChangeEvent<HTMLInputElement>) {
        const resourceStatus = e.target.value
        if (selectedResource && resourceStatus === 'INATIVO') {
            setResourceProjectStatus((oldData: any) => { return { ...oldData, isLoading: true } })
            const response = await api.get(`/resources/${selectedResource.id}/projects-status`)
            setResourceProjectStatus({ ...response.data, isLoading: false })
        } else {
            setResourceProjectStatus(undefined)
        }
    }

    const getResourceTypes = () => {
        return selectedResource?.__types__.map(t => t.id + '')
    }

    function filterResourcesByClassification(classificacao_id: string) {
        const classificacaoResource = Number(classificacao_id)
        if (classificacaoResource !== 0) {
            setTimeout(() => {
                let newResourcesFiltered = resources.filter((r: ResourceProps) => r.resourceClassification?.classification.id === classificacaoResource)
                setResourcesFiltered(newResourcesFiltered)
            }, 300)
        } else {
            setResourcesFiltered(resources)
        }
    }

    function filterResourcesByName(name: string) {
        if (name !== '' || !name.match(/\s\s+/g)) {
            setTimeout(() => {
                let newResourcesFiltered = resources.filter((r: ResourceProps) => r.name.toLocaleLowerCase().includes(name.toLowerCase()))
                setResourcesFiltered(newResourcesFiltered)
            }, 1000)
        } else {
            setResourcesFiltered(resources)
        }
    }

    async function handleSelectResourceAndListProjects(resource_id: number) {
        if (selectResourceId !== resource_id) {
            setLoadingResourceProjectLists(true)
            setSelectResourceId(resource_id)
            const response = await api.get(`/resources/${resource_id}/projects`)
            const resourceProject = response.data as ResourceProjectsProps
            setSelectResourceProjectLists(resourceProject)
            setLoadingResourceProjectLists(false)
        }
    }

    useMemo(() => {
        if (watchClassificationUpdate !== '4') {
            setValueUpdate('types_ids', [])
        }

        //eslint-disable-next-line
    }, [watchClassificationUpdate])

    useMemo(() => {
        if (watchStatus === 'INATIVO') {
            setValue('hours_amount', 0)
        }

        //eslint-disable-next-line
    }, [watchStatus])

    useMemo(() => {
        if (watchStatusUpdate === 'INATIVO') {
            setValueUpdate('hours_amount', 0)
        }

        //eslint-disable-next-line
    }, [watchStatusUpdate])

    return (
        <S.Container>
            <S.FiltersContainer>
                <Checkbox onChange={(e) => setShowNotActiveResources(e.target.checked)}>Exibir recursos inativos</Checkbox>
                <S.FiltersContainerLeft>
                    <S.FilterClassification>
                        <FormLabel width='max-content' marginBottom='0'>Filtrar por:</FormLabel>
                        <ChakraSelect width={'max-content'} onChange={(e: any) => filterResourcesByClassification(e.target.value)}>
                            <option value={''}>Nenhum...</option>
                            {classifications.map((e: ClassificationProps) => (
                                <option key={e.id} value={e.id}>{e.description}</option>
                            ))}
                        </ChakraSelect>
                    </S.FilterClassification>
                    <InputDefault width='212px' onChange={(e) => filterResourcesByName(e.target.value)} size='md' name='name' placeholder='Insira o nome do recurso' />
                </S.FiltersContainerLeft>
            </S.FiltersContainer>
            <S.PanelsContent>
                <S.TableLineContainer>
                    <Flex flexGrow={1} flex={1} direction='column' gap='70px 30px' height='fit-content'>
                        <ResourcesTable
                            panelTitle='Disponíveis'
                            loadingResources={loadingResources}
                            resourceStatusDescription={resourceStatusDescription}
                            resources={typeOfResources('Disponíveis')}
                            selectResource={selectResource}
                            viewSelectedResource={viewSelectedResource}
                            handleSelectResourceAndListProjects={handleSelectResourceAndListProjects}
                            loadingResourceProjectLists={loadingResourceProjectLists}
                            selectResourceProjectLists={selectResourceProjectLists}
                        />
                        <ResourcesTable
                            panelTitle='Alocados'
                            loadingResources={loadingResources}
                            resourceStatusDescription={resourceStatusDescription}
                            resources={typeOfResources('Alocados')}
                            selectResource={selectResource}
                            viewSelectedResource={viewSelectedResource}
                            handleSelectResourceAndListProjects={handleSelectResourceAndListProjects}
                            loadingResourceProjectLists={loadingResourceProjectLists}
                            selectResourceProjectLists={selectResourceProjectLists}
                        />
                    </Flex>
                    <Flex flexGrow={1} flex={1} direction='column' gap='70px 30px' height='fit-content'>
                        <ResourcesTable panelTitle='Indisponíveis'
                            loadingResources={loadingResources}
                            resourceStatusDescription={resourceStatusDescription}
                            resources={typeOfResources('Indisponíveis')}
                            selectResource={selectResource}
                            viewSelectedResource={viewSelectedResource}
                            handleSelectResourceAndListProjects={handleSelectResourceAndListProjects}
                            loadingResourceProjectLists={loadingResourceProjectLists}
                            selectResourceProjectLists={selectResourceProjectLists}
                        />
                        <ResourcesTable panelTitle='Outros'
                            loadingResources={loadingResources}
                            resourceStatusDescription={resourceStatusDescription}
                            resources={typeOfResources('Outros')}
                            selectResource={selectResource}
                            viewSelectedResource={viewSelectedResource}
                            handleSelectResourceAndListProjects={handleSelectResourceAndListProjects}
                            loadingResourceProjectLists={loadingResourceProjectLists}
                            selectResourceProjectLists={selectResourceProjectLists}
                        />
                        <ResourcesTable panelTitle='Sobrecarga'
                            loadingResources={loadingResources}
                            resourceStatusDescription={resourceStatusDescription}
                            resources={typeOfResources('Sobrecarga')}
                            selectResource={selectResource}
                            viewSelectedResource={viewSelectedResource}
                            handleSelectResourceAndListProjects={handleSelectResourceAndListProjects}
                            loadingResourceProjectLists={loadingResourceProjectLists}
                            selectResourceProjectLists={selectResourceProjectLists}
                        />
                        {showNotActiveResources && (
                            <ResourcesTable panelTitle='Inativos'
                                loadingResources={loadingResources}
                                resourceStatusDescription={resourceStatusDescription}
                                resources={typeOfResources('Inativos')}
                                selectResource={selectResource}
                                viewSelectedResource={viewSelectedResource}
                                handleSelectResourceAndListProjects={handleSelectResourceAndListProjects}
                                loadingResourceProjectLists={loadingResourceProjectLists}
                                selectResourceProjectLists={selectResourceProjectLists}
                            />
                        )}
                    </Flex>
                </S.TableLineContainer>
                <HasPermission permissions={['create_resource']}>
                    <CreateButton tooltipText='recurso' icon={IoMdPersonAdd} onClick={openNewResourceModal} />
                </HasPermission>
            </S.PanelsContent>
            <Modal isOpen={isOpenNew} onClose={onCloseNew}>
                <ModalOverlay />
                <ModalContent>
                    <Box as='form' onSubmit={handleSubmitNewResource(handleCreateNewResource)}>
                        <ModalHeader>Cadastro de recursos</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex
                                alignItems='center'
                                justifyContent='center'>
                                <WrapItem
                                    position='relative'>
                                    <Avatar
                                        border='2px solid red'
                                        size='2xl'
                                        src={newResourceImage.tempImage} />
                                    <FormLabel
                                        cursor='pointer'
                                        htmlFor='resource_image'
                                        position='absolute'
                                        height={'100%'}
                                        width={'100%'}>
                                        <ChakraInput
                                            display='none'
                                            id='resource_image'
                                            type='file'
                                            onChange={handleNewResourcePhoto} />
                                    </FormLabel>
                                </WrapItem>
                            </Flex>
                            <Flex
                                flexDir={'column'}
                                gap={4}>
                                <Input
                                    label='Nome'
                                    placeholder='Beltrano'
                                    error={formState.errors.name}
                                    {...registerNewResource('name')} />
                                <Input
                                    label='Email'
                                    placeholder='fulano@testingcompany.com.br'
                                    error={formState.errors.email}
                                    {...registerNewResource('email')} />

                                <Flex justifyContent={'space-between'} gap='10px'>
                                    <FormControl isInvalid={!!formState.errors.admission_date}>
                                        <Controller
                                            control={control}
                                            name="admission_date"
                                            render={({ field: { onChange, value } }) => (
                                                <InputDate
                                                    label='Data de admissão'
                                                    dateFormat="dd/MM/yyyy"
                                                    locale={ptBR}
                                                    onChange={onChange}
                                                    onBlur={onChange}
                                                    selected={value}
                                                />
                                            )}
                                        />
                                        <FormErrorMessage>
                                            {formState.errors.admission_date && formState.errors.admission_date.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl>
                                        <Controller
                                            control={control}
                                            name="vacation_date"
                                            render={({ field: { onChange, value } }) => (
                                                <InputDate
                                                    label='Previsão de férias'
                                                    dateFormat="dd/MM/yyyy"
                                                    locale={ptBR}
                                                    onChange={onChange}
                                                    onBlur={onChange}
                                                    selected={value}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Flex>

                                <Flex justifyContent={'space-between'} gap={'10px'}>
                                    <FormControl isInvalid={!!formState.errors.status}>
                                        <FormLabel>Status</FormLabel>
                                        <ChakraSelect
                                            placeholder='Selecione...'
                                            {...registerNewResource('status')} >
                                            <option value='ATIVO'>Ativo</option>
                                            <option value='FERIAS'>Férias</option>
                                            <option value='TREINAMENTO'>Treinamento</option>
                                            <option value='INATIVO'>Inativo</option>
                                        </ChakraSelect>

                                        <FormErrorMessage>
                                            {formState.errors.status && formState.errors.status.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!formState.errors.classification_id}>
                                        <FormLabel>Classificação</FormLabel>
                                        <ChakraSelect
                                            {...registerNewResource('classification_id')}>
                                            <option value={0}>Selecione...</option>
                                            {classifications.map((e: ClassificationProps) => (
                                                <option key={e.id} value={e.id}>{e.description}</option>
                                            ))}
                                        </ChakraSelect>
                                        <FormErrorMessage>{formState.errors.classification_id && (formState.errors.classification_id as any)?.message}</FormErrorMessage>
                                    </FormControl>
                                </Flex>

                                {watchClassification && watchClassification === '4' && (
                                    <Flex>
                                        <FormControl isInvalid={!!formState.errors.types_ids}>
                                            <FormLabel>Tipos</FormLabel>
                                            {types.length > 0 && (
                                                <Controller
                                                    name='types_ids'
                                                    control={control}
                                                    render={({ field: { ref, ...rest } }) => (
                                                        <CheckboxGroup {...rest}>
                                                            <Flex gap='10px'>
                                                                {types.map(t => (
                                                                    <Checkbox key={t.id} value={t.id + ''}>{t.name}</Checkbox>
                                                                ))}
                                                            </Flex>
                                                        </CheckboxGroup>
                                                    )}
                                                />
                                            )}
                                            <FormErrorMessage>{formState.errors.types_ids && (formState.errors.types_ids as any)?.message}</FormErrorMessage>
                                        </FormControl>
                                    </Flex>
                                )}

                                <Flex>
                                    <FormControl>
                                        {watchHoursAmount > 180 && (
                                            <Alert status='error' padding='5px'>
                                                A quantidade de horas inseridas está acima do normal
                                            </Alert>
                                        )}
                                        <FormLabel htmlFor='hours-amount'>Horas</FormLabel>
                                        <NumberInput
                                            isDisabled={watchStatus === 'INATIVO'}
                                            {...(watchStatus === 'INATIVO' && { value: 0 })}
                                            size='md'
                                            width={'100px'}
                                            defaultValue={0}
                                            min={0}
                                            max={9999}
                                            onChange={(e) => setValue('hours_amount', e)}>
                                            <NumberInputField id='hours_amount' {...registerNewResource('hours_amount')} />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor='leader'>Líder</FormLabel>
                                            <Switch id='leader' {...registerNewResource('leader')} />
                                    </FormControl>
                                </Flex>
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={onCloseNew}>
                                Cancelar
                            </Button>
                            <Button type='submit' colorScheme='blue' disabled={isLoadingCreating}>{isLoadingCreating ? <Spinner /> : 'Cadastrar'}</Button>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenUpdate} onClose={onCloseUpdate}>
                <ModalOverlay />
                <ModalContent>
                    {selectedResource && (
                        <Box as='form' onSubmit={handleSubmitUpdateResource(handleUpdateResource)}>
                            <ModalHeader>{selectedResource.name}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Flex
                                    alignItems='center'
                                    justifyContent='center'>
                                    <WrapItem
                                        position='relative'>
                                        <Avatar
                                            border='2px solid red'
                                            size='2xl'
                                            name={selectedResource?.name}
                                            src={selectedResource?.photo_url} />
                                        <FormLabel
                                            cursor='pointer'
                                            htmlFor='resource_photo'
                                            position='absolute'
                                            height={'100%'}
                                            width={'100%'}>
                                            <ChakraInput
                                                display='none'
                                                id='resource_photo'
                                                type='file'
                                                onChange={handlePhotoUpdate} />
                                        </FormLabel>
                                    </WrapItem>
                                </Flex>
                                <Flex
                                    flexDir={'column'}
                                    gap={4}>
                                    <Input
                                        defaultValue={selectedResource?.name}
                                        label='Nome'
                                        placeholder='W3haus, Paquetá...'
                                        error={formStateUpdate.errors.name}
                                        {...registerUpdateResource('name')} />
                                    <Input
                                        defaultValue={selectedResource?.email}
                                        label='Email'
                                        placeholder='fulano@testingcompany.com.br'
                                        error={formStateUpdate.errors.email}
                                        {...registerUpdateResource('email')} />
                                    <Flex justifyContent={'space-between'} gap='10px'>
                                        <FormControl isInvalid={!!formStateUpdate.errors.admission_date}>
                                            <Controller
                                                control={controlUpdate}
                                                name="admission_date"
                                                render={({ field: { onChange, value } }) => (
                                                    <InputDate
                                                        label='Data de admissão'
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={ptBR}
                                                        onChange={onChange}
                                                        onBlur={onChange}
                                                        selected={value}
                                                    />
                                                )}
                                            />
                                            <FormErrorMessage>
                                                {formStateUpdate.errors.admission_date && formStateUpdate.errors.admission_date.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl>
                                            <Controller
                                                control={controlUpdate}
                                                name="vacation_date"
                                                render={({ field: { onChange, value } }) => (
                                                    <InputDate
                                                        label='Previsão de férias'
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={ptBR}
                                                        onChange={onChange}
                                                        onBlur={onChange}
                                                        selected={value}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Flex>
                                    <Flex justifyContent={'space-between'} gap='10px'>
                                        <FormControl>
                                            <FormLabel>Status</FormLabel>
                                            <ChakraSelect
                                                defaultValue={selectedResource.resourceStatus.status.name}
                                                placeholder='Selecione...'
                                                {...registerUpdateResource('status', { onChange: verifyResourceProjectsStatus })} >
                                                <option value='ATIVO'>Ativo</option>
                                                <option value='FERIAS'>Férias</option>
                                                <option value='TREINAMENTO'>Treinamento</option>
                                                <option value='INATIVO'>Inativo</option>
                                            </ChakraSelect>
                                            <FormErrorMessage>
                                                {formStateUpdate.errors.status}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={!!formStateUpdate.errors.classification_id}>
                                            <FormLabel>Classificação</FormLabel>
                                            <ChakraSelect
                                                defaultValue={selectedResource.resourceClassification?.classification.id ?? 0}
                                                {...registerUpdateResource('classification_id')}>
                                                <option value={0}>Selecione...</option>
                                                {classifications.map((e: ClassificationProps) => (
                                                    <option key={e.id} value={e.id}>{e.description}</option>
                                                ))}
                                            </ChakraSelect>
                                            <FormErrorMessage>{formStateUpdate.errors.classification_id && (formStateUpdate.errors.classification_id as any)?.message}</FormErrorMessage>
                                        </FormControl>
                                    </Flex>
                                    {watchClassificationUpdate && watchClassificationUpdate === '4' && (
                                        <Flex>
                                            <FormControl isInvalid={!!formStateUpdate.errors.types_ids}>
                                                <FormLabel>Tipos</FormLabel>
                                                {types.length > 0 && (
                                                    <Controller
                                                        name='types_ids'
                                                        control={controlUpdate}
                                                        render={({ field: { ref, ...rest } }) => (
                                                            <CheckboxGroup defaultValue={getResourceTypes()} {...rest}>
                                                                <Flex gap='10px'>
                                                                    {types.map(t => (
                                                                        <Checkbox key={t.id} value={t.id + ''}>{t.name}</Checkbox>
                                                                    ))}
                                                                </Flex>
                                                            </CheckboxGroup>
                                                        )}
                                                    />
                                                )}
                                                <FormErrorMessage>{formStateUpdate.errors.types_ids && formStateUpdate.errors.types_ids.message}</FormErrorMessage>
                                            </FormControl>
                                        </Flex>
                                    )}
                                    {resourceProjectStatus && (
                                        <>
                                            {resourceProjectStatus.isLoading ? (
                                                <span>Carregando...</span>
                                            ) : (
                                                <>
                                                    {(resourceProjectStatus.projects_responsible > 0
                                                        ||
                                                        resourceProjectStatus.projects_as_resource > 0) && (
                                                            <Flex>
                                                                <Alert status='warning' display='flex' flexDirection='column' alignItems='flex-start'>
                                                                    <AlertTitle mr={2} mb={'15px'} display='flex'>
                                                                        <AlertIcon />
                                                                        <Text>Este recurso está:</Text>
                                                                    </AlertTitle>
                                                                    {resourceProjectStatus.projects_responsible > 0 && (
                                                                        <AlertDescription>- Atrelado como responsável em {resourceProjectStatus.projects_responsible} projeto(s);</AlertDescription>
                                                                    )}
                                                                    {resourceProjectStatus.projects_as_resource > 0 && (
                                                                        <AlertDescription>- Atrelado como recurso em {resourceProjectStatus.projects_as_resource} projeto(s);</AlertDescription>
                                                                    )}
                                                                    <AlertDescription mt='15px'><strong>Obs.:</strong> este recurso será retirado de todos os projetos.</AlertDescription>
                                                                    <CloseButton position='absolute' right='8px' top='8px' />
                                                                </Alert>
                                                            </Flex>
                                                        )}
                                                </>
                                            )}
                                        </>
                                    )}
                                    <Flex>
                                        <FormControl>
                                            {watchHoursAmountUpdate > 180 && (
                                                <Alert status='error' padding='5px'>
                                                    A quantidade de horas inseridas está acima do normal
                                                </Alert>
                                            )}
                                            <FormLabel htmlFor='hours-amount'>Horas</FormLabel>
                                            <NumberInput
                                                isDisabled={watchStatusUpdate === 'INATIVO'}
                                                size='md'
                                                width={'100px'}
                                                onChange={(e) => setValueUpdate('hours_amount', e)}
                                                defaultValue={selectedResource?.hours_amount} min={0} max={9999}
                                                {...(watchStatusUpdate === 'INATIVO' && { value: 0 })}>
                                                <NumberInputField id='hours_amount' {...registerUpdateResource('hours_amount')} />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel htmlFor='leader'>Líder</FormLabel>
                                                <Switch id='leader' defaultChecked={selectedResource?.leader} {...registerUpdateResource('leader')} />
                                        </FormControl>
                                    </Flex>
                                </Flex>
                            </ModalBody>
                            <ModalFooter>
                                <Button variant='ghost' mr={3} onClick={onCloseUpdate}>
                                    Cancelar
                                </Button>
                                <Button type='submit' colorScheme='blue' disabled={isLoadingUpdating}>{isLoadingUpdating ? <Spinner /> : 'Atualizar'}</Button>
                            </ModalFooter>
                        </Box>
                    )}

                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenView} onClose={onCloseView}>
                <ModalOverlay />
                <ModalContent>
                    {selectedResource && (
                        <Box>
                            <ModalHeader>{selectedResource.name}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Flex
                                    alignItems='center'
                                    justifyContent='center'>
                                    <WrapItem
                                        position='relative'>
                                        <Avatar
                                            border='2px solid red'
                                            size='2xl'
                                            name={selectedResource.name}
                                            src={selectedResource.photo_url} />
                                    </WrapItem>
                                </Flex>
                                <Flex
                                    flexDir={'column'}
                                    gap={4}>
                                    <FormControl>
                                        <FormLabel fontWeight={600}>Nome</FormLabel>
                                        <Text>{selectedResource.name}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={600}>E-mail</FormLabel>
                                        <Text>{selectedResource.email}</Text>
                                    </FormControl>
                                    <Flex gap={4}>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Data de admissão</FormLabel>
                                            {selectedResource.admission_date ? (
                                                <Text>{format(selectedResource.admission_date, 'dd/MM/yyyy')}</Text>
                                            ) : (
                                                <Text></Text>
                                            )}
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Previsão de férias</FormLabel>
                                            {selectedResource.vacation_date ? (
                                                <Text>{format(selectedResource.vacation_date, 'dd/MM/yyyy')}</Text>
                                            ) : (
                                                <Text></Text>
                                            )}
                                        </FormControl>
                                    </Flex>
                                    <Flex>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Tipos</FormLabel>
                                            <Flex>
                                                {selectedResource.__types__.length > 0 ? (
                                                    <>
                                                        {selectedResource.__types__.map((t, i) => (
                                                            <Text key={t.id}>{i >= 1 ? ',' : ''} {t.name}</Text>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <Text>Sem tipos cadastrados</Text>
                                                )}
                                            </Flex>
                                        </FormControl>
                                    </Flex>
                                    <Flex gap='20px'>
                                        <FormControl width={'max-content'}>
                                            <FormLabel fontWeight={600}>Status</FormLabel>
                                            <Text>{resourceStatusDescription(selectedResource.resourceStatus.status.name, selectedResource.hours_amount)?.description}</Text>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Classificação</FormLabel>
                                            <Text>{selectedResource.resourceClassification?.classification.description}</Text>
                                        </FormControl>
                                    </Flex>

                                    <Flex gap={4}>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Horas</FormLabel>
                                            <Text>{selectedResource?.hours_amount}</Text>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontWeight={600}>Líder</FormLabel>
                                            <Text>{selectedResource?.leader ? 'Sim' : 'Não'}</Text>
                                        </FormControl>
                                    </Flex>
                                </Flex>
                            </ModalBody>
                            <ModalFooter>
                                <Button variant='ghost' mr={3} onClick={onCloseView}>
                                    Cancelar
                                </Button>
                            </ModalFooter>
                        </Box>
                    )}

                </ModalContent>
            </Modal>
        </S.Container >
    )
}

export default Resources