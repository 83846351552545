import styled from 'styled-components'

export const ActionButton = styled.button`
    min-height: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.2s;

    &:hover {
        background: rgba(0,0,0,0.1);
    }
`