import { useEffect, useState, useMemo } from 'react'
import { v4 } from 'uuid'
import {
    Avatar,
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Spinner,
    Text,
    useToast,
    Select as ChakraSelect,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Alert,
    Checkbox,
    Divider,
    Skeleton,
    SkeletonCircle
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/hooks'

import queryString from 'query-string'

import { useLocation, useHistory } from 'react-router-dom'

import { parseISO, format, addDays, isBefore } from 'date-fns'

import Select from 'react-select'
import ptBR from 'date-fns/locale/pt-BR'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm, useWatch } from 'react-hook-form'
import * as yup from 'yup'

import { TiDelete } from 'react-icons/ti'
import { AiFillEdit } from 'react-icons/ai'
import { IoIosAddCircle } from 'react-icons/io'
import { FaLockOpen, FaLock } from 'react-icons/fa'
import { HiExclamationCircle } from 'react-icons/hi'

import { api } from '../../services/api'
import CreateButton from '../../components/Buttons/CreateButton'
import Input from '../../components/Forms/Input'
import InputDate from '../../components/Forms/InputDate'
import * as S from './styles'
import { MdLibraryAdd } from 'react-icons/md'
import { HasPermission } from '../../components/HasPermission'
import ProjectsTable from './ProjectsTable'
import { CustomerProps, ProjectProps, ResourceProps, HistoricProps } from './Models'
import InputDefault from '../../components/Forms/ChakraInput'

const Projects: React.FC = () => {
    const toast = useToast()
    const { search } = useLocation()
    const history = useHistory()

    const [showFinishedProjects, setShowFinishedProjects] = useState(false)
    const [loadingProjects, setLoadingProjects] = useState(true)

    const { isOpen: isOpenView, onOpen: onOpenView, onClose: onCloseView } = useDisclosure()
    const { isOpen: isOpenNew, onOpen: onOpenNew, onClose: onCloseNew } = useDisclosure()
    const [isLoadingCreating, setIsLoadingCreating] = useState(false)
    const [projectsFiltered, setProjectsFiltered] = useState<ProjectProps[]>([])
    const [projects, setProjects] = useState<ProjectProps[]>([])
    const [newProjectResources, setNewProjectResources] = useState<ResourceProps[]>([])

    const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure()
    const [isLoadingUpdating, setIsLoadingUpdating] = useState(false)
    const [projectSelected, setProjectSelected] = useState<ProjectProps>()
    const [isLoadingProjectResources, setIsLoadingProjectResources] = useState(false)
    const [isLoadingUpdateResourceHours, setIsLoadingUpdateResourceHours] = useState(-1)

    const [isLoadingResources, setIsLoadingResources] = useState(true)
    const [resources, setResources] = useState<ResourceProps[]>([])
    const [customers, setCustomers] = useState<CustomerProps[]>([])
    const [allCustomers, setAllCustomers] = useState<CustomerProps[]>([])

    const [isLoadingResponsibles, setIsLoadingResponsibles] = useState(true)
    const [responsibles, setResponsibles] = useState<ResourceProps[]>([])

    const { isOpen: isOpenHistoric, onOpen: onOpenHistoric, onClose: onCloseHistoric } = useDisclosure()
    const [loadingHistoric, setLoadingHistoric] = useState(false)
    const [loadingMoreHistoric, setLoadingMoreHistoric] = useState(false)
    const [projectHistoricSelected, setProjectHistoricSelected] = useState({
        project_id: -1,
        name: ''
    })
    const [historic, setHistoric] = useState<HistoricProps[]>([])
    const [historicFilter, setHistoricFilter] = useState({
        current_page: 1,
        last_page: 1
    })

    useEffect(() => {
        async function loadProjects() {
            const response = await api.get(`/projects`)
            const projectsWithUuid = response.data.map((p: ProjectProps) => {
                const start_estimate = parseISO(p.start_estimate + '')
                let end_estimate = null
                if (p.end_estimate) end_estimate = parseISO(p.end_estimate + '')
                return { ...p, uuid: v4(), start_estimate, end_estimate, has_loaded_resources: false }
            })
            setProjects(projectsWithUuid)
            setProjectsFiltered(projectsWithUuid)
            setLoadingProjects(false)

            const viewProject = queryString.parse(search)
            if (viewProject) {
                const project_id = Number(viewProject.visualizar)
                const currentProjects = [...projectsWithUuid]
                const currentProject = currentProjects.find(p => p.id === project_id)
                if (currentProject) {
                    setProjectSelected(currentProject)
                    resetUpdateProjectModal()
                    clearUpdateErrors()

                    setValueUpdate('status', currentProject.status)
                    setValueUpdate('start_estimate', currentProject.start_estimate)
                    setValueUpdate('end_estimate', currentProject.end_estimate)
                    setValueUpdate('type', currentProject.type)
                    onOpenView()

                    if (!currentProject.has_loaded_resources) {
                        setIsLoadingProjectResources(true)
                        const response = await api.get(`/projects/${currentProject.id}/resources`)
                        currentProject.resources = response.data
                        currentProject.has_loaded_resources = true
                        setProjectSelected(currentProject)
                        setIsLoadingProjectResources(false)
                    }
                }
            }
        }

        async function loadResources() {
            const response = await api.get('/resources/actives')
            const currentResources = response.data.map((cr: ResourceProps) => {
                return { ...cr, value: cr.id, label: cr.name, project_hours: 0 }
            })
            setResources(currentResources)
            setIsLoadingResources(false)
        }

        async function loadResponsibles() {
            const response = await api.get('/resources/responsibles')
            const currentResponsibles = response.data.map((cr: ResourceProps) => {
                return { ...cr, value: cr.id, label: cr.name, project_hours: 0 }
            })
            setResponsibles(currentResponsibles)
            setIsLoadingResponsibles(false)
        }

        async function loadCustomers() {
            await api.get('/customers?qtdPerPage=100')
                .then(res => {
                    const { data } = res.data
                    const allCurrentCustomers: CustomerProps[] = data.map((cr: CustomerProps) => {
                        return { ...cr, value: cr.id, label: cr.name }
                    })
                    setAllCustomers(allCurrentCustomers)

                    const currentActiveCustomers = allCurrentCustomers.filter(acc => acc.status === 'ATIVO')
                    setCustomers(currentActiveCustomers)
                })
        }

        loadProjects()
        loadResources()
        loadResponsibles()
        loadCustomers()

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function loadHistoricFilter() {
            if (projectHistoricSelected.project_id !== -1) {
                const response = await api.get(`/projects/${projectHistoricSelected.project_id}/historic?currentPage=1`)
                const responseData = response.data
                const { current_page, last_page } = responseData
                setHistoricFilter({ current_page, last_page })
            }
        }

        loadHistoricFilter()
    }, [projectHistoricSelected.project_id])

    useEffect(() => {
        async function loadHistoric() {
            const response = await api.get(`/projects/${projectHistoricSelected.project_id}/historic?currentPage=${historicFilter.current_page}`)
            const responseData = response.data
            const newHistorics = responseData.data.map((h: HistoricProps) => {
                const parsedDate = parseISO(h.created_at.toString())
                return { ...h, uuid: v4(), created_at: format(parsedDate, 'dd/MM/yyyy hh:mm') }
            })
            setHistoric(oldData => { return [...oldData, ...newHistorics] })
            setLoadingHistoric(false)
            setLoadingMoreHistoric(false)
        }

        loadHistoric()

        //eslint-disable-next-line
    }, [historicFilter])

    async function showHistoric(id: number, name: string) {
        setLoadingHistoric(true)
        setProjectHistoricSelected({ project_id: id, name })
        onOpenHistoric()
    }

    function onCloseProjectHistoricModal() {
        setProjectHistoricSelected({ project_id: -1, name: '' })
        setHistoric([])
        onCloseHistoric()
    }

    async function loadMoreHistoric() {
        setLoadingMoreHistoric(true)
        setHistoricFilter(oldData => { return { ...oldData, current_page: oldData.current_page + 1 } })
    }

    const newProjectSchema = yup.object({
        name: yup.string().required('Nome é obrigatório'),
        customer_id: yup.number().min(1, 'Cliente é obrigatório').required('Cliente é obrigatório'),
        hours: yup.number().typeError('Horas é obrigatório').min(1, 'Valor de horas deve ser maior que 1'),
        type: yup.mixed().oneOf([
            'PF',
            'PR'
        ], 'Tipo é obrigatório'),
        end_estimate: yup.date().nullable().when('type', (type, end_estimate) =>
            type === 'PF' ? end_estimate.required('Finalização é obrigatória') : end_estimate
        ),
        start_estimate: yup.date().when('end_estimate', (end_estimate, start_estimate) =>
            start_estimate.test('isStartDateBeforeEndDate', 'Data deve ser menor que finalização', (value: any) => {
                if (end_estimate) return isBefore(value, end_estimate)
                else return true
            }).required('Estimativa é obrigatória').nullable()
        ),
        status: yup.mixed().oneOf([
            'EM_ANDAMENTO',
            'A_INICIAR',
            'CONCLUIDO'
        ], 'Status é obrigatório')
    })

    const {
        control,
        watch,
        getValues,
        setValue,
        register: registerNewProject,
        handleSubmit: handleSubmitNewProject,
        formState, reset: resetNewProjectModal,
        clearErrors: clearNewErrors } = useForm({ resolver: yupResolver(newProjectSchema) })

    const watchStatus = watch('status')
    const watchType = useWatch({ control, name: 'type' })

    const {
        control: controlUpdate,
        watch: watchUpdate,
        getValues: getValuesUpdate,
        setValue: setValueUpdate,
        register: registerUpdateProject,
        handleSubmit: handleSubmitUpdateProject,
        formState: formStateUpdate, reset: resetUpdateProjectModal,
        clearErrors: clearUpdateErrors } = useForm({ resolver: yupResolver(newProjectSchema) })

    const watchStatusUpdate = watchUpdate('status')
    const watchTypeUpdate = useWatch({ control: controlUpdate, name: 'type' })

    function openNewProjectModal() {
        if (isOpenNew) {
            onCloseNew()
        } else {
            resetNewProjectModal()
            clearNewErrors()
            setNewProjectResources([])
            onOpenNew()
        }
    }

    async function setProjectNow(project: ProjectProps) {
        setProjectSelected(project)
        resetUpdateProjectModal()
        clearUpdateErrors()

        setValueUpdate('status', project.status)
        setValueUpdate('start_estimate', project.start_estimate)
        setValueUpdate('end_estimate', project.end_estimate)
        setValueUpdate('type', project.type)

        if (!project.has_loaded_resources) {
            setIsLoadingProjectResources(true)
            const response = await api.get(`/projects/${project.id}/resources`)
            project.resources = response.data
            project.has_loaded_resources = true
            setProjectSelected(project)
            setIsLoadingProjectResources(false)
        }
    }

    async function selectProject(project_id: number) {
        const currentProjects = [...projects]
        const currentProject = currentProjects.find(p => p.id === project_id)
        if (currentProject) {
            setProjectNow(currentProject)
        }
        onOpenUpdate()
    }

    async function viewSelectedProject(project_id: number) {
        const currentProjects = [...projects]
        const currentProject = currentProjects.find(p => p.id === project_id)
        if (currentProject) {
            setProjectSelected(currentProject)
            if (!currentProject.has_loaded_resources) {
                setIsLoadingProjectResources(true)
                const response = await api.get(`/projects/${project_id}/resources`)
                currentProject.resources = response.data
                currentProject.has_loaded_resources = true
                setProjectSelected(currentProject)
                setIsLoadingProjectResources(false)
            }
        }
        onOpenView()
    }

    async function handleCreateNewProject(values: any): Promise<void> {
        const { responsible_id } = values
        setIsLoadingCreating(true)

        try {
            const currentResources = newProjectResources.map(r => {
                return {
                    resource_id: r.id,
                    hours_amount: r.project_hours
                }
            })

            const response = await api.post('/projects', {
                ...values,
                responsible_id: responsible_id !== '0' ? responsible_id : null,
                resources: currentResources
            })

            const start_estimate = parseISO(response.data.start_estimate)
            let end_estimate = null
            if (response.data.end_estimate) end_estimate = parseISO(response.data.end_estimate)
            const newProjectCreated = { ...response.data, start_estimate, end_estimate, uuid: v4() }
            const oldProjects = [...projects]
            oldProjects.push(newProjectCreated)
            setProjects(oldProjects)
            setProjectsFiltered(oldProjects)

            onCloseNew()

            toast({
                title: 'Projeto cadastrado com sucesso',
                status: 'success',
                duration: 4000,
                isClosable: true
            })
        } catch (err: any) {
            toast({
                title: 'Ocorreu um erro',
                status: 'error',
                duration: 4000,
                isClosable: true
            })
        }

        setIsLoadingCreating(false)
    }

    async function handleUpdateProject(values: any): Promise<void> {
        const { responsible_id } = values
        setIsLoadingUpdating(true)

        try {
            const response = await api.put(`/projects/${projectSelected?.id}`, {
                ...values,
                responsible_id: responsible_id !== '0' ? responsible_id : null
            })

            let end_estimate = null
            if (response.data.end_estimate) end_estimate = parseISO(response.data.end_estimate)
            const newProjectUpdated = {
                ...response.data,
                start_estimate: parseISO(response.data.start_estimate + ''),
                end_estimate,
                uuid: projectSelected?.uuid
            }
            const oldProjects = [...projects]
            const oldProjectIndex = oldProjects.findIndex(p => p.id === newProjectUpdated.id)
            oldProjects[oldProjectIndex] = newProjectUpdated
            setProjects(oldProjects)
            setProjectsFiltered(oldProjects)

            setProjectHistoricSelected({
                project_id: -1,
                name: ''
            })

            onCloseUpdate()

            toast({
                title: 'Projeto atualizado com sucesso',
                status: 'success',
                duration: 4000,
                isClosable: true
            })
        } catch (err: any) {
            toast({
                title: 'Ocorreu um erro',
                status: 'error',
                duration: 4000,
                isClosable: true
            })
        }

        setIsLoadingUpdating(false)
    }

    function addResourceToProject(newResource: any) {
        const addNewResource: ResourceProps = newResource[0]
        if (newProjectResources.length === 0) {
            setNewProjectResources(newResource)
        } else {
            const oldResources = [...newProjectResources]
            const resourceExistsIndex = oldResources.findIndex(or => or.id === addNewResource.id)

            if (resourceExistsIndex !== -1) {
                oldResources.splice(resourceExistsIndex, 1)
            } else {
                oldResources.push(addNewResource)
            }
            setNewProjectResources(oldResources)
        }
    }

    function addOrRemoveResourceToSelectedProject(newResource: any) {
        if (projectSelected) {
            const oldProjectSelected = { ...projectSelected }
            const currentResources = [...oldProjectSelected.resources]

            addOrRemoveResourceToProjects(newResource)
            if (currentResources.length === 0) {
                oldProjectSelected.resources = newResource
                setProjectSelected(oldProjectSelected)
            } else {
                const addNewResource: ResourceProps = newResource[0]

                const resourceIndex = currentResources.findIndex(pr => pr.id === addNewResource.id)
                if (resourceIndex !== -1) {
                    currentResources.splice(resourceIndex, 1)
                } else {
                    currentResources.push(addNewResource)
                }

                oldProjectSelected.resources = currentResources
                setProjectSelected(oldProjectSelected)
            }
        }
    }

    function addOrRemoveResourceToProjects(newResource: any) {
        if (projectSelected) {
            const oldProjects = [...projects]
            const currentProjectIndex = oldProjects.findIndex(op => op.id === projectSelected?.id)
            const currentProject = oldProjects[currentProjectIndex]

            const currentResources = [...currentProject.resources]

            if (currentResources.length === 0) {
                currentProject.resources = newResource
                oldProjects[currentProjectIndex] = currentProject
                handleUpdateResourceHours(newResource[0].id, projectSelected.id, 0)
                setProjects(oldProjects)
            } else {
                const addNewResource: ResourceProps = newResource[0]

                const resourceIndex = currentResources.findIndex(pr => pr.id === addNewResource.id)
                if (resourceIndex !== -1) {
                    handleRemoveResourceFromProject(addNewResource.id, projectSelected.id)
                    currentResources.splice(resourceIndex, 1)
                } else {
                    handleUpdateResourceHours(addNewResource.id, projectSelected.id, 0)
                    currentResources.push(addNewResource)
                }

                currentProject.resources = currentResources
                oldProjects[currentProjectIndex] = currentProject
                setProjects(oldProjects)
            }
        }
    }

    function removeResourceFromProjects(resource_id: number) {
        if (projectSelected) {
            const oldProjects = [...projects]
            const currentProjectIndex = oldProjects.findIndex(op => op.id === projectSelected?.id)
            const currentProject = oldProjects[currentProjectIndex]

            const currentResources = [...currentProject.resources]
            const currentResourceIndex = currentResources.findIndex(cr => cr.id === resource_id)
            currentResources.splice(currentResourceIndex, 1)

            currentProject.resources = currentResources
            oldProjects[currentProjectIndex] = currentProject
            setProjects(oldProjects)
        }
    }

    async function removeResourceFromSelectedProject(resource_id: number) {
        if (projectSelected) {
            const oldProjectSelected = { ...projectSelected }

            const oldProjectResources = [...oldProjectSelected.resources]
            const resourceIndex = oldProjectResources.findIndex(pr => pr.id === resource_id)
            if (resourceIndex !== -1) {
                oldProjectResources.splice(resourceIndex, 1)
                oldProjectSelected.resources = oldProjectResources
                removeResourceFromProjects(resource_id)
                setProjectSelected(oldProjectSelected)
                handleRemoveResourceFromProject(resource_id, projectSelected.id)
            }
        }
    }

    async function handleRemoveResourceFromProject(resource_id: number, project_id: number) {
        await api.delete(`/projects/${project_id}/resources/${resource_id}`)
    }

    async function updateProjectSelectedResourceHours(resource_id: number) {
        if (projectSelected) {
            setIsLoadingUpdateResourceHours(resource_id)
            const projectSelectedResources = [...projectSelected.resources]
            const resourceIndex = projectSelectedResources.findIndex(psr => psr.id === resource_id)
            const currentProjectResourceSelected = projectSelectedResources[resourceIndex]

            const oldProjects = [...projects]
            const currentProjectIndex = oldProjects.findIndex(op => op.id === projectSelected.id)
            const currentProject = oldProjects[currentProjectIndex]
            const currentProjectResources = currentProject.resources
            const currentResourceIndex = currentProjectResources.findIndex(cpr => cpr.id === resource_id)
            const currentResource = currentProjectResources[currentResourceIndex]

            const hours_left = currentResource.hours_left - (currentProjectResourceSelected.project_hours - currentResource.project_hours)
            const project_hours = currentProjectResourceSelected.project_hours

            currentResource.hours_left = hours_left
            currentResource.project_hours = project_hours

            currentProjectResources[currentResourceIndex] = currentResource

            currentProject.resources = currentProjectResources

            oldProjects[currentProjectIndex] = currentProject
            setProjects(oldProjects)
            await handleUpdateResourceHours(resource_id, projectSelected.id, project_hours)
            setIsLoadingUpdateResourceHours(-1)
            toast({
                title: 'Horas de recurso atualizada!',
                status: 'success',
                duration: 4000,
                isClosable: true
            })
        }
    }

    async function handleUpdateResourceHours(resource_id: number, project_id: number, project_hours: number) {
        await api.patch(`/projects/${project_id}/resources/${resource_id}/hours`, {
            hours_amount: project_hours
        })
    }

    function removeResourceFromProject(resource_id: number) {
        const oldResources = [...newProjectResources]
        const resourceExistsIndex = oldResources.findIndex(or => or.id === resource_id)
        oldResources.splice(resourceExistsIndex, 1)
        setNewProjectResources(oldResources)
    }

    function updateProjectResourceHours(resource_id: number, project_hours: number) {
        const oldResources = [...newProjectResources]
        const resourceIndex = newProjectResources.findIndex(r => r.id === resource_id)
        const resource = { ...oldResources[resourceIndex] }
        resource.project_hours = project_hours
        oldResources[resourceIndex] = resource
        setNewProjectResources(oldResources)
    }

    function updateProjectResourceHoursFromProjectSelected(resource_id: number, project_hours: number) {
        if (projectSelected) {
            const oldProjectSelected = { ...projectSelected }
            const oldResourcesProjectSelected = [...oldProjectSelected.resources]
            const resourceIndex = oldResourcesProjectSelected.findIndex(r => r.id === resource_id)

            const resource = { ...oldResourcesProjectSelected[resourceIndex] }
            resource.hours_left = resource.hours_left - (project_hours - resource.project_hours)
            resource.project_hours = project_hours
            oldResourcesProjectSelected[resourceIndex] = resource
            oldProjectSelected.resources = oldResourcesProjectSelected
            setProjectSelected(oldProjectSelected)
        }
    }

    function typeOfProjects(pStatus: string) {
        const projectsOfStatus = projectsFiltered.filter(p => p.status === pStatus)

        return projectsOfStatus
    }

    const projectStatusDescription = (status: string) => {
        switch (status) {
            case 'EM_ANDAMENTO':
                return 'Em andamento'
            case 'A_INICIAR':
                return 'A iniciar'
            case 'CONCLUIDO':
                return 'Concluído'
        }
    }

    useMemo(() => {
        if (getValues('status') !== 'CONCLUIDO') {
            setValue('start_estimate', null)
        }

        //eslint-disable-next-line
    }, [watchStatus])

    useMemo(() => {
        if (getValues('type') !== 'PF') {
            setValue('end_estimate', null)
        }

        //eslint-disable-next-line
    }, [watchType])

    useMemo(() => {
        if (getValuesUpdate('type') !== 'PF') {
            setValueUpdate('end_estimate', null)
        }

        //eslint-disable-next-line
    }, [watchTypeUpdate])

    const typeHistoricIcon = (type: string) => {
        if (type === 'NEW_PROJECT') return <FaLockOpen size={20} color='red' />
        else if (type === 'CLOSING_PROJECT') return <FaLock size={20} color='red' />
        else if (type === 'ADD_RESOURCE') {
            return <Flex borderRadius='50%' position={'relative'} left='-2px'><IoIosAddCircle size={25} color='red' /></Flex>
        }
        else if (type === 'NAME' || type === 'HOURS' || type === 'TYPE' || type === 'STATUS' ||
            type === 'INCREASE_RESOURCE_HOURS' || type === 'DECREASE_RESOURCE_HOURS' ||
            type === 'START_ESTIMATE' || type === 'RESPONSIBLE_ID' || type === 'CUSTOMER_ID') {
            return <AiFillEdit size={23} color='red' />
        } else {
            return <Flex borderRadius='50%' position={'relative'} left='-4px'><TiDelete size={30} color='red' /></Flex>
        }
    }

    function filterProjectsByName(name: string) {
        name = name.toLowerCase()
        if (name !== '' || !name.match(/\s\s+/g)) {
            setTimeout(() => {
                let newProjectsFiltered = projects.filter((r: ProjectProps) => {
                    const containsResource = r.resources.filter(r => r.name.toLowerCase().includes(name))
                    const containsProject = r.name.toLocaleLowerCase().includes(name)
                    const containsCustomer = r.customer.name.toLocaleLowerCase().includes(name)
                    return containsResource.length > 0 || containsProject || containsCustomer
                })
                setProjectsFiltered(newProjectsFiltered)
            }, 1000)
        } else {
            setProjectsFiltered(projects)
        }
    }

    function onCloseProjectView() {
        history.replace('/projetos')

        onCloseView()
    }

    return (
        <S.Container>
            <Flex alignItems={'center'}>
                <Checkbox onChange={(e) => setShowFinishedProjects(e.target.checked)}>Exibir projetos finalizados</Checkbox>
                <Flex marginLeft='auto' alignItems={'center'} gap='10px'>
                    <InputDefault width={'284px'} onChange={(e) => filterProjectsByName(e.target.value)} size='md' name='name' placeholder='Insira o nome do recurso ou cliente' />
                </Flex>
            </Flex>
            <S.PanelsContent>
                <ProjectsTable
                    projects={typeOfProjects('EM_ANDAMENTO')}
                    loadingProjects={loadingProjects}
                    selectProject={selectProject}
                    viewSelectedProject={viewSelectedProject}
                    showHistoric={showHistoric}
                    panelTitle='Projetos em andamento'
                    projectStatus='EM_ANDAMENTO' />

                <ProjectsTable
                    projects={typeOfProjects('A_INICIAR')}
                    loadingProjects={loadingProjects}
                    selectProject={selectProject}
                    viewSelectedProject={viewSelectedProject}
                    showHistoric={showHistoric}
                    panelTitle='Projetos a iniciar'
                    projectStatus='A_INICIAR' />

                {showFinishedProjects && (
                    <ProjectsTable
                        projects={typeOfProjects('CONCLUIDO')}
                        loadingProjects={loadingProjects}
                        selectProject={selectProject}
                        viewSelectedProject={viewSelectedProject}
                        showHistoric={showHistoric}
                        panelTitle='Projetos finalizados'
                        projectStatus='CONCLUIDO' />
                )}
                <HasPermission permissions={['create_project']}>
                    <CreateButton tooltipText='projeto' icon={MdLibraryAdd} onClick={openNewProjectModal} />
                </HasPermission>
            </S.PanelsContent>
            <Modal isOpen={isOpenNew} onClose={onCloseNew}>
                <ModalOverlay />
                <ModalContent>
                    <Box as='form' onSubmit={handleSubmitNewProject(handleCreateNewProject)}>
                        <ModalHeader>Cadastro de projetos</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex
                                flexDir={'column'}
                                gap={4}>
                                <Input
                                    label='Nome'
                                    placeholder='W3haus, Paquetá...'
                                    error={formState.errors.name}
                                    {...registerNewProject('name')} />
                                <Flex direction={'row'} gap='10px'>

                                    <FormControl isInvalid={!!formState.errors.status}>
                                        <FormLabel>Status</FormLabel>
                                        <ChakraSelect
                                            placeholder='Selecione...'
                                            {...registerNewProject('status')} >
                                            <option value='EM_ANDAMENTO'>Em andamento</option>
                                            <option value='A_INICIAR'>À iniciar</option>
                                            <option value='CONCLUIDO'>Concluído</option>
                                        </ChakraSelect>
                                        <FormErrorMessage>
                                            {formState.errors.status && formState.errors.status.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!formState.errors.type}>
                                        <FormLabel>Tipo</FormLabel>
                                        <ChakraSelect
                                            placeholder='Selecione...'
                                            {...registerNewProject('type')} >
                                            <option value='PF'>PF</option>
                                            <option value='PR'>PR</option>
                                        </ChakraSelect>
                                        <FormErrorMessage>
                                            {formState.errors.type && formState.errors.type.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Flex>

                                <Flex gap='10px'>
                                    <FormControl isInvalid={!!formState.errors.start_estimate}>
                                        <Controller
                                            control={control}
                                            name="start_estimate"
                                            render={({ field: { onChange, value } }) => (
                                                <InputDate
                                                    {...(getValues('status') === 'EM_ANDAMENTO' || getValues('status') === 'CONCLUIDO' ? { maxDate: new Date() } : {})}
                                                    {...(getValues('status') === 'A_INICIAR' ? { minDate: addDays(new Date(), 1) } : {})}
                                                    label='Estimativa de início'
                                                    dateFormat="dd/MM/yyyy"
                                                    locale={ptBR}
                                                    onChange={onChange}
                                                    onBlur={onChange}
                                                    selected={value}
                                                />
                                            )}
                                        />
                                        <FormErrorMessage>
                                            {formState.errors.start_estimate && formState.errors.start_estimate.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                    {watchType === 'PF' && (
                                        <FormControl isInvalid={!!formState.errors.end_estimate}>
                                            <Controller
                                                control={control}
                                                name="end_estimate"
                                                render={({ field: { onChange, value } }) => (
                                                    <InputDate
                                                        minDate={new Date()}
                                                        label='Finalização'
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={ptBR}
                                                        onChange={onChange}
                                                        onBlur={onChange}
                                                        selected={value}
                                                    />
                                                )}
                                            />
                                            <FormErrorMessage>
                                                {formState.errors.end_estimate && formState.errors.end_estimate.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Flex>

                                <Flex justifyContent={'space-between'} gap='10px'>
                                    <FormControl isInvalid={!!formState.errors.hours} maxWidth='min-content'>
                                        <FormLabel htmlFor='hours'>Horas</FormLabel>
                                        <NumberInput size='md' width={'100px'} defaultValue={0} min={0} max={9999}>
                                            <NumberInputField id='hours' {...registerNewProject('hours')} />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        <FormErrorMessage>
                                            {formState.errors.hours && formState.errors.hours.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!formState.errors.customer_id}>
                                        <FormLabel>Cliente</FormLabel>
                                        <ChakraSelect
                                            {...registerNewProject('customer_id')} >
                                            <option value={0}>Selecione...</option>
                                            {customers.length > 0 && customers.map(c => (
                                                <option key={c.id + c.name} value={c.id}>{c.name}</option>
                                            ))}
                                        </ChakraSelect>
                                        <FormErrorMessage>
                                            {formState.errors.customer_id && formState.errors.customer_id.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Flex>

                                <Flex>
                                    <FormControl isInvalid={!!formState.errors.responsible_id}>
                                        <FormLabel>Líder</FormLabel>
                                        <ChakraSelect
                                            isDisabled={isLoadingResponsibles}
                                            {...registerNewProject('responsible_id')} >
                                            <option value={0}>Selecione...</option>
                                            {responsibles.length > 0 && responsibles.map(r => (
                                                <option key={r.id + r.name} value={r.id}>{r.name}</option>
                                            ))}
                                        </ChakraSelect>
                                        <FormErrorMessage>
                                            {formState.errors.responsible_id && formState.errors.responsible_id.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Flex>

                                <Flex>
                                    <FormControl>
                                        <FormLabel>Colaboradores do Projeto</FormLabel>
                                        {isLoadingResources ? (
                                            <Skeleton width={'100%'} height='35px' />
                                        ) : (
                                            <Select
                                                isMulti={true}
                                                noOptionsMessage={() => 'Sem mais Colaborades'}
                                                onChange={addResourceToProject}
                                                placeholder='Adicione mais colaboradores'
                                                value={null}
                                                options={resources}
                                            />
                                        )}
                                    </FormControl>
                                </Flex>
                                <Flex gap='8px' flexWrap={'wrap'}>
                                    {newProjectResources.map((a) => (
                                        <Popover key={a.id}>
                                            <PopoverTrigger>
                                                <S.AvatarContainer>
                                                    {a.hours_left - a.project_hours < 0 && (
                                                        <Flex
                                                            position='absolute'
                                                            top='-7px'
                                                            right='-5px'
                                                            justifyContent={'center'}
                                                            alignItems='center'
                                                            width={'24px'}
                                                            height={'24px'}
                                                            borderRadius={'50%'}
                                                            bgColor={'#fff'}
                                                        >
                                                            <HiExclamationCircle color='red' size={24} />
                                                        </Flex>
                                                    )}
                                                    <Avatar
                                                        zIndex={-1}
                                                        objectFit={'cover'}
                                                        borderRadius='full'
                                                        boxSize='50px'
                                                        name={a.name}
                                                        src={a.photo_url} />
                                                </S.AvatarContainer>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverCloseButton />
                                                <PopoverHeader>
                                                    <Flex direction='column' gap='10px'>
                                                        <Text>{a.name}</Text>
                                                        {a.hours_left - a.project_hours < 0 && (
                                                            <Alert status='error' padding='5px'>
                                                                Esse colaborador excedeu as horas disponíveis
                                                            </Alert>
                                                        )}
                                                    </Flex>
                                                </PopoverHeader>
                                                <PopoverBody>
                                                    <Text>Horas cadastradas: {a.hours_amount}</Text>
                                                    <Text>Horas livres: {a.hours_left - a.project_hours <= 0 ? 0 : a.hours_left - a.project_hours}</Text>
                                                    <Text color={a.hours_left - a.project_hours < 0 ? 'red' : 'black'}>Horas excedentes: {a.hours_left - a.project_hours <= 0 ? (a.hours_left - a.project_hours) * -1 : 0}</Text>
                                                    <FormControl maxWidth='max-content'>
                                                        <FormLabel htmlFor='hours-amount'>Horas para este projeto</FormLabel>
                                                        <Flex gap='10px'>
                                                            <NumberInput
                                                                size='md'
                                                                width={'100px'}
                                                                min={0}
                                                                max={9999}
                                                                value={a.project_hours}
                                                                onChange={(e) => updateProjectResourceHours(a.id, Number(e))}>
                                                                <NumberInputField />
                                                                <NumberInputStepper>
                                                                    <NumberIncrementStepper />
                                                                    <NumberDecrementStepper />
                                                                </NumberInputStepper>
                                                            </NumberInput>
                                                            <Button type='button' colorScheme={'red'} onClick={() => removeResourceFromProject(a.id)}>Remover do projeto</Button>
                                                        </Flex>
                                                    </FormControl>
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                    ))}
                                </Flex>
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={onCloseNew}>
                                Cancelar
                            </Button>
                            <Button type='submit' colorScheme='blue' disabled={isLoadingCreating}>{isLoadingCreating ? <Spinner /> : 'Cadastrar'}</Button>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenUpdate} onClose={onCloseUpdate}>
                <ModalOverlay />
                <ModalContent>
                    <Box as='form' onSubmit={handleSubmitUpdateProject(handleUpdateProject)}>
                        <ModalHeader>{projectSelected?.name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {projectSelected && (
                                <Flex
                                    flexDir={'column'}
                                    gap={4}>
                                    <Input
                                        label='Nome'
                                        placeholder='W3haus, Paquetá...'
                                        error={formStateUpdate.errors.name}
                                        defaultValue={projectSelected.name}
                                        {...registerUpdateProject('name')} />
                                    <Flex direction={'row'} gap='10px'>
                                        <FormControl isInvalid={!!formStateUpdate.errors.status}>
                                            <FormLabel>Status</FormLabel>
                                            <ChakraSelect
                                                placeholder='Selecione...'
                                                defaultValue={projectSelected.status}
                                                {...registerUpdateProject('status', {
                                                    onChange: () => {
                                                        if (getValuesUpdate('status') !== 'CONCLUIDO') {
                                                            setValueUpdate('start_estimate', null)
                                                        }
                                                    }
                                                })} >
                                                <option value='EM_ANDAMENTO'>Em andamento</option>
                                                <option value='A_INICIAR'>À iniciar</option>
                                                <option value='CONCLUIDO'>Concluído</option>
                                            </ChakraSelect>
                                            <FormErrorMessage>
                                                {formStateUpdate.errors.status && formStateUpdate.errors.status.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={!!formStateUpdate.errors.type}>
                                            <FormLabel>Tipo</FormLabel>
                                            <ChakraSelect
                                                placeholder='Selecione...'
                                                defaultValue={projectSelected.type}
                                                {...registerUpdateProject('type')} >
                                                <option value='PF'>PF</option>
                                                <option value='PR'>PR</option>
                                            </ChakraSelect>
                                            <FormErrorMessage>
                                                {formStateUpdate.errors.type && formStateUpdate.errors.type.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </Flex>

                                    <Flex justifyContent={'space-between'} gap='10px'>
                                        <FormControl isInvalid={!!formStateUpdate.errors.start_estimate}>
                                            <Controller
                                                control={controlUpdate}
                                                name="start_estimate"
                                                render={({ field: { onChange, value } }) => (
                                                    <InputDate
                                                        {...(watchStatusUpdate && (getValuesUpdate('status') === 'EM_ANDAMENTO' || getValuesUpdate('status') === 'CONCLUIDO') ? { maxDate: new Date() } : {})}
                                                        {...(watchStatusUpdate && getValuesUpdate('status') === 'A_INICIAR' ? { minDate: addDays(new Date(), 1) } : {})}
                                                        label='Estimativa de início'
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={ptBR}
                                                        onChange={onChange}
                                                        onBlur={onChange}
                                                        selected={value}
                                                    />
                                                )}
                                            />
                                            <FormErrorMessage>
                                                {formStateUpdate.errors.start_estimate && formStateUpdate.errors.start_estimate.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                        {watchTypeUpdate === 'PF' && (
                                            <FormControl isInvalid={!!formStateUpdate.errors.end_estimate}>
                                                <Controller
                                                    control={controlUpdate}
                                                    name="end_estimate"
                                                    render={({ field: { onChange, value } }) => (
                                                        <InputDate
                                                            minDate={new Date()}
                                                            label='Estimativa de finalização'
                                                            dateFormat="dd/MM/yyyy"
                                                            locale={ptBR}
                                                            onChange={onChange}
                                                            onBlur={onChange}
                                                            selected={value}
                                                        />
                                                    )}
                                                />
                                                <FormErrorMessage>
                                                    {formStateUpdate.errors.end_estimate && formStateUpdate.errors.end_estimate.message}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Flex>

                                    <Flex justifyContent={'space-between'} gap='10px'>
                                        <FormControl isInvalid={!!formStateUpdate.errors.hours} maxWidth='min-content'>
                                            <FormLabel htmlFor='hours'>Horas</FormLabel>
                                            <NumberInput size='md' width={'100px'} defaultValue={projectSelected.hours} min={0} max={9999}>
                                                <NumberInputField id='hours' {...registerUpdateProject('hours')} />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                            <FormErrorMessage>
                                                {formStateUpdate.errors.hours && formStateUpdate.errors.hours.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={!!formStateUpdate.errors.customer_id}>
                                            <FormLabel>Cliente</FormLabel>
                                            <ChakraSelect
                                                defaultValue={projectSelected.customer.id}
                                                {...registerUpdateProject('customer_id')} >
                                                <option value={0}>Selecione...</option>
                                                {allCustomers.length > 0 && allCustomers.map(c => (
                                                    <option key={c.id} value={c.id}>{c.name}</option>
                                                ))}
                                            </ChakraSelect>
                                            <FormErrorMessage>
                                                {formStateUpdate.errors.customer_id && formStateUpdate.errors.customer_id.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </Flex>
                                    <Flex>
                                        <FormControl isInvalid={!!formStateUpdate.errors.responsible_id}>
                                            <FormLabel>Líder</FormLabel>
                                            <ChakraSelect
                                                isDisabled={isLoadingResponsibles}
                                                defaultValue={projectSelected.responsible ? projectSelected.responsible.id : 0}
                                                {...registerUpdateProject('responsible_id')}>
                                                <option value={0}>Selecione...</option>
                                                {responsibles.length > 0 && responsibles.map(r => (
                                                    <option key={r.id + r.name} value={r.id}>{r.name}</option>
                                                ))}
                                            </ChakraSelect>
                                            <FormErrorMessage>
                                                {formStateUpdate.errors.responsible_id && formStateUpdate.errors.responsible_id.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </Flex>
                                    <Flex>
                                        <FormControl>
                                            <FormLabel>Colaboradores</FormLabel>
                                            <Select
                                                isDisabled={isLoadingProjectResources}
                                                isMulti={true}
                                                noOptionsMessage={() => 'Sem mais Colaboradores'}
                                                onChange={addOrRemoveResourceToSelectedProject}
                                                placeholder='Adicione mais colaboradores'
                                                value={null}
                                                options={resources}
                                            />
                                        </FormControl>
                                    </Flex>
                                    <Flex gap='8px' flexWrap={'wrap'}>
                                        {isLoadingProjectResources ? (
                                            <>
                                                <SkeletonCircle size='14' />
                                                <SkeletonCircle size='14' />
                                                <SkeletonCircle size='14' />
                                                <SkeletonCircle size='14' />
                                                <SkeletonCircle size='14' />
                                                <SkeletonCircle size='14' />
                                            </>
                                        ) : (
                                            <>
                                                {projectSelected.resources.length > 0 ? (
                                                    <>
                                                        {projectSelected.resources.map((a) => (
                                                            <Popover key={projectSelected.id + a.id}>
                                                                <PopoverTrigger>
                                                                    <S.AvatarContainer>
                                                                        {a.hours_left < 0 && (
                                                                            <Flex
                                                                                position='absolute'
                                                                                top='-7px'
                                                                                right='-5px'
                                                                                justifyContent={'center'}
                                                                                alignItems='center'
                                                                                width={'24px'}
                                                                                height={'24px'}
                                                                                borderRadius={'50%'}
                                                                                bgColor={'#fff'}
                                                                            >
                                                                                <HiExclamationCircle color='red' size={24} />
                                                                            </Flex>
                                                                        )}
                                                                        <Avatar
                                                                            zIndex={-1}
                                                                            objectFit={'cover'}
                                                                            borderRadius='full'
                                                                            boxSize='50px'
                                                                            name={a.name}
                                                                            src={a.photo_url} />
                                                                    </S.AvatarContainer>
                                                                </PopoverTrigger>
                                                                <PopoverContent width={'max-content'} maxWidth='350px'>
                                                                    <PopoverArrow />
                                                                    <PopoverCloseButton />
                                                                    <PopoverHeader>
                                                                        <Flex direction='column' gap='10px'>
                                                                            <Text>{a.name}</Text>
                                                                            {a.hours_left < 0 && (
                                                                                <Alert status='error' padding='5px'>
                                                                                    Esse recurso excedeu as horas disponíveis
                                                                                </Alert>
                                                                            )}
                                                                        </Flex>
                                                                    </PopoverHeader>
                                                                    <PopoverBody>
                                                                        <Text>Horas cadastradas: {a.hours_amount}</Text>
                                                                        <Text>Horas livres: {a.hours_left <= 0 ? 0 : a.hours_left}</Text>
                                                                        <Text color={a.hours_left < 0 ? 'red' : 'black'}>Horas excedentes: {a.hours_left <= 0 ? (a.hours_left * -1) : 0}</Text>
                                                                        <FormControl maxWidth='max-content'>
                                                                            <FormLabel htmlFor='hours-amount'>Horas para este projeto</FormLabel>
                                                                            <Flex direction='column' gap='10px'>
                                                                                <Flex gap='10px'>
                                                                                    <NumberInput
                                                                                        isDisabled={isLoadingUpdateResourceHours === a.id}
                                                                                        size='md'
                                                                                        width={'100px'}
                                                                                        min={0}
                                                                                        max={9999}
                                                                                        value={a.project_hours}
                                                                                        onChange={(e) => updateProjectResourceHoursFromProjectSelected(a.id, Number(e))}
                                                                                    >
                                                                                        <NumberInputField />
                                                                                        <NumberInputStepper>
                                                                                            <NumberIncrementStepper />
                                                                                            <NumberDecrementStepper />
                                                                                        </NumberInputStepper>
                                                                                    </NumberInput>
                                                                                    <Button
                                                                                        disabled={isLoadingUpdateResourceHours === a.id}
                                                                                        type='button'
                                                                                        colorScheme={'blue'}
                                                                                        onClick={() => updateProjectSelectedResourceHours(a.id)}>Atualizar {isLoadingUpdateResourceHours === a.id && (
                                                                                            <Spinner ml='2px' />
                                                                                        )}</Button>
                                                                                </Flex>
                                                                                <Button disabled={isLoadingUpdateResourceHours === a.id} type='button' colorScheme={'red'} onClick={() => removeResourceFromSelectedProject(a.id)}>Remover do projeto</Button>
                                                                            </Flex>
                                                                        </FormControl>
                                                                    </PopoverBody>
                                                                </PopoverContent>
                                                            </Popover>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <Text>Projeto não contém recursos</Text>
                                                )}
                                            </>
                                        )}
                                    </Flex>
                                </Flex>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={onCloseUpdate}>
                                Cancelar
                            </Button>
                            <Button type='submit' colorScheme='blue' disabled={isLoadingUpdating}>{isLoadingUpdating ? <Spinner /> : 'Atualizar'}</Button>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenView} onClose={onCloseProjectView}>
                <ModalOverlay />
                <ModalContent>
                    <Box>
                        <ModalHeader>{projectSelected?.name}</ModalHeader>
                        <ModalCloseButton />
                        {projectSelected && (
                            <ModalBody>
                                <Flex
                                    flexDir={'column'}
                                    gap={4}>
                                    <FormControl>
                                        <FormLabel htmlFor='hours'>Nome</FormLabel>
                                        <Text>{projectSelected.name}</Text>
                                    </FormControl>
                                    <Flex justifyContent={'space-between'} gap='10px'>
                                        <FormControl>
                                            <FormLabel>Status</FormLabel>
                                            <Text>{projectStatusDescription(projectSelected.status)}</Text>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Tipo</FormLabel>
                                            <Text>{projectSelected.type}</Text>
                                        </FormControl>
                                    </Flex>
                                    <Flex gap='10px'>
                                        <FormControl>
                                            <FormLabel>Estimativa de início</FormLabel>
                                            <Text>{format(projectSelected.start_estimate, 'dd/MM/yyyy')}</Text>
                                        </FormControl>
                                        {projectSelected.end_estimate && (
                                            <FormControl>
                                                <FormLabel>Estimativa de finalização</FormLabel>
                                                <Text>{format(projectSelected.end_estimate, 'dd/MM/yyyy')}</Text>
                                            </FormControl>
                                        )}
                                    </Flex>
                                    <Flex justifyContent={'space-between'} gap='10px'>
                                        <FormControl>
                                            <FormLabel htmlFor='hours'>Horas</FormLabel>
                                            <Text>{projectSelected.hours}</Text>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Cliente</FormLabel>
                                            <Text>{projectSelected.customer.name}</Text>
                                        </FormControl>
                                    </Flex>
                                    <Flex>
                                        <FormControl>
                                            <FormLabel>Líder</FormLabel>
                                            <Text>
                                                {projectSelected.responsible
                                                    ? projectSelected.responsible?.name
                                                    : 'Sem líder'}
                                            </Text>
                                        </FormControl>
                                    </Flex>
                                    <Flex>
                                        <FormControl>
                                            <FormLabel>Colaboradores</FormLabel>
                                        </FormControl>
                                    </Flex>
                                    <Flex gap='8px' flexWrap={'wrap'}>
                                        {projectSelected.resources.map((a) => (
                                            <Popover key={projectSelected.id + a.id}>
                                                <PopoverTrigger>
                                                    <S.AvatarContainer>
                                                        {a.hours_left < 0 && (
                                                            <Flex
                                                                position='absolute'
                                                                top='-7px'
                                                                right='-5px'
                                                                justifyContent={'center'}
                                                                alignItems='center'
                                                                width={'24px'}
                                                                height={'24px'}
                                                                borderRadius={'50%'}
                                                                bgColor={'#fff'}
                                                            >
                                                                <HiExclamationCircle color='red' size={24} />
                                                            </Flex>
                                                        )}
                                                        <Avatar
                                                            zIndex={-1}
                                                            objectFit={'cover'}
                                                            borderRadius='full'
                                                            boxSize='50px'
                                                            name={a.name}
                                                            src={a.photo_url} />
                                                    </S.AvatarContainer>
                                                </PopoverTrigger>
                                                <PopoverContent>
                                                    <PopoverArrow />
                                                    <PopoverCloseButton />
                                                    <PopoverHeader>
                                                        <Flex direction='column' gap='10px'>
                                                            <Text>{a.name}</Text>
                                                            {a.hours_left < 0 && (
                                                                <Alert status='error' padding='5px'>
                                                                    Esse colaborador excedeu as horas disponíveis
                                                                </Alert>
                                                            )}
                                                        </Flex>
                                                    </PopoverHeader>
                                                    <PopoverBody>
                                                        <Text>Horas cadastradas: {a.hours_amount}</Text>
                                                        <Text>Horas livres: {a.hours_left <= 0 ? 0 : a.hours_left}</Text>
                                                        <Text color={a.hours_left < 0 ? 'red' : 'black'}>Horas excedentes: {a.hours_left <= 0 ? (a.hours_left * -1) : 0}</Text>
                                                        <Text>Horas para este projeto: {a.project_hours}</Text>
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                        ))}
                                    </Flex>
                                </Flex>
                            </ModalBody>
                        )}
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={onCloseProjectView}>
                                Fechar
                            </Button>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenHistoric} onClose={onCloseProjectHistoricModal} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <Box>
                        <ModalHeader>{projectHistoricSelected.name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {loadingHistoric ? (
                                <Spinner size='lg' />
                            ) : (
                                <>
                                    {historic.length > 0 ? (
                                        <Flex flexDirection={'column'}>
                                            {historic.map(h => (
                                                <Flex key={h.uuid} minHeight='100px'>
                                                    <Flex position='relative' minWidth='60px' alignItems={'center'} marginLeft='10px'>
                                                        <Box
                                                            position='absolute'
                                                            zIndex='1'
                                                            left='-14px'
                                                            borderRadius={'50%'}
                                                            bgColor='white'
                                                            padding='5px'>
                                                            {typeHistoricIcon(h.type)}
                                                        </Box>
                                                        <Divider opacity='1' borderLeftWidth={'2px'} orientation='vertical' />
                                                    </Flex>
                                                    <Flex flexDirection={'column'} justifyContent='center'>
                                                        <Text dangerouslySetInnerHTML={{ __html: `${h.description}` }} />
                                                        <Text color='gray'>{h.created_at}</Text>
                                                    </Flex>
                                                </Flex>
                                            ))}
                                            {historicFilter.current_page < historicFilter.last_page && (
                                                <Button
                                                    disabled={loadingMoreHistoric}
                                                    marginTop={'10px'}
                                                    width={'max-content'}
                                                    onClick={() => loadMoreHistoric()}>
                                                    {loadingMoreHistoric && (
                                                        <Spinner mr={'5px'} />
                                                    )}
                                                    Carregar mais
                                                </Button>
                                            )}
                                        </Flex>
                                    ) : (
                                        <Text>Não há histórico</Text>
                                    )}
                                </>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' onClick={onCloseProjectHistoricModal}>
                                Fechar
                            </Button>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
        </S.Container >
    )
}

export default Projects