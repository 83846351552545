import { Box, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

import { IoMdPerson, IoMdSettings } from 'react-icons/io'

import * as S from './styles'
import VersionNotes from '../VersionNotes'
import Notifications from '../Notifications'
import MobileSidebar from '../MobileSidebar'
import { HasPermission } from '../HasPermission'

const Header: React.FC = () => {
    const location = useLocation()
    const { user } = useAuth()

    const screenName = () => {
        const currentName = location.pathname.split('/')[1]
        switch (currentName) {
            case 'meu-perfil':
                return 'Meu Perfil'
            case 'dashboard':
                return 'Dashboard'
            case 'projetos':
                return 'Projetos'
            case 'clientes':
                return 'Clientes'
            case 'recursos':
                return 'Recursos'
            case 'usuarios':
                return 'Usuários'
            case 'hard-skills':
                return 'Hard Skills'
            case 'grupo-de-permissoes':
                return 'Grupo de Permissões'
            case 'skills':
                return 'Skills'
            default:
                return 'Visão Geral'
        }
    }

    return (
        <S.Container>
            <MobileSidebar />
            <Text color={'#6e6e6e'} fontWeight={700}>{screenName()}</Text>
            <S.Profile>
                <HasPermission permissions={['view_version_note', 'create_version_note', 'update_version_note']}>
                    <VersionNotes />
                </HasPermission>
                <Notifications />
                <HasPermission permissions={['view_users', 'delete_user', 'create_user', 'update_user', 'view_roles', 'create_role', 'update_role', 'delete_role']}>
                    <Menu>
                        <Tooltip hasArrow label={'Configurações'} bg='red.600'>
                            <Box display="inline-block">
                                <MenuButton
                                    as={S.ActionButton}
                                    width='40px'
                                    bgColor='transparent'
                                    borderRadius='50%'
                                    display='flex'
                                    justifyContent={'center'}
                                    alignItems='center'
                                    padding='0'>
                                    <IoMdSettings size={25} color='#6e6e6e' />
                                </MenuButton>
                            </Box>
                        </Tooltip>
                        <MenuList>
                            <HasPermission permissions={['view_users', 'delete_user', 'create_user', 'update_user']}>
                                <MenuItem to='/usuarios' as={Link}>Usuários</MenuItem>
                            </HasPermission>
                            <HasPermission permissions={['view_roles', 'create_role', 'update_role', 'delete_role']}>
                                <MenuItem to='/grupo-de-permissoes' as={Link}>Grupo de Permissões</MenuItem>
                            </HasPermission>
                        </MenuList>
                    </Menu>
                </HasPermission>
                <S.ProfileLink to='/meu-perfil'>
                    <IoMdPerson size={25} color='#6e6e6e' />
                    <Text color={'#6e6e6e'} fontWeight={700}>{user.name}</Text>
                </S.ProfileLink>
            </S.Profile>
        </S.Container>
    )
}

export default Header