import { memo } from "react"
import { Avatar, Box, Divider, Flex, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Skeleton, Spinner, Stack, Table, Tag, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react"

import { FaRegEye } from "react-icons/fa"
import { FiEdit } from "react-icons/fi"
import { CgProfile } from 'react-icons/cg'

import { HasPermission } from "../../../components/HasPermission"
import Panel from "../../../components/Panel"
import { ResourceProjectsProps, ResourceProps } from "../Models"

import * as S from './styles'
import TableActionButton from "../../../components/Buttons/TableActionButton"
import { Link } from "react-router-dom"

interface ResourceStatusDescription {
  description: string
  color: string
}

interface ResourcesTableProps {
  loadingResources: boolean
  resources: ResourceProps[]
  panelTitle: string
  loadingResourceProjectLists: boolean
  selectResourceProjectLists: ResourceProjectsProps | undefined
  resourceStatusDescription(status: string, hours_amount: number, classification?: string): ResourceStatusDescription
  selectResource(resource_id: number): void
  viewSelectedResource(resource_id: number): void
  handleSelectResourceAndListProjects(resource_id: number): void
}

const ResourcesTable = memo((
  {
    panelTitle,
    loadingResources,
    resources,
    loadingResourceProjectLists,
    selectResourceProjectLists,
    resourceStatusDescription,
    selectResource,
    viewSelectedResource,
    handleSelectResourceAndListProjects }: ResourcesTableProps) => {

  const resourceName = (name: string): string => {
    const lastName = name.substring(name.lastIndexOf(' '), name.length)
    const firstName = name.substring(0, name.indexOf(' '))

    return `${firstName} ${lastName}`
  }

  return (
    <Panel flexGrow={1} title={panelTitle} counter={resources.length.toString()} position='relative' flexDir={'column'}>
      {loadingResources ? (
        <Stack>
          <Skeleton height='20px' />
          <Skeleton height='20px' />
          <Skeleton height='20px' />
        </Stack>
      ) : (
        <Flex maxWidth={'-webkit-fill-available'} overflowX='auto'>
          {resources.length > 0 ? (
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>NOME</Th>
                  <Th>
                    {panelTitle !== 'Sobrecarga' ? (
                      <Tooltip hasArrow label='Horas disponíveis' bg='red.600'>
                        HORAS
                      </Tooltip>
                    ) : (
                      <Tooltip hasArrow label='Horas excedentes' bg='red.600'>
                        HORAS
                      </Tooltip>
                    )}
                  </Th>
                  {panelTitle !== 'Alocados' && panelTitle !== 'Sobrecarga' && (
                    <Th>STATUS</Th>
                  )}
                  <HasPermission permissions={['update_resource']}>
                    <Th></Th>
                  </HasPermission>
                </Tr>
              </Thead>
              <Tbody>
                {resources.map((r) => (
                  <Tr key={r.uuid1}>
                    <Td>
                      <Flex alignItems={'center'} gap='20px'>
                        <Popover key={r.id}>
                          <PopoverTrigger>
                            <Avatar
                              onClick={() => handleSelectResourceAndListProjects(r.id)}
                              cursor='pointer'
                              objectFit={'cover'}
                              borderRadius='full'
                              boxSize='50px'
                              name={r.name}
                              src={r.photo_url}
                            />
                          </PopoverTrigger>
                          <PopoverContent width={'max-content'} maxWidth='330px'>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader mr='20px'>
                              <Text
                                to={`/recursos/${r.id}`}
                                as={Link}
                                fontWeight={700}
                              >{resourceName(r.name)}</Text>
                            </PopoverHeader>
                            <PopoverBody>
                              {loadingResourceProjectLists ? (
                                <Spinner />
                              ) : (
                                <Flex flexDirection={'column'}>
                                  {selectResourceProjectLists && (
                                    <>
                                      <>
                                        {selectResourceProjectLists.projects.length > 0 ? (
                                          selectResourceProjectLists.projects.map(rp => (
                                            <Text mb={'5px'} key={rp.id}>{rp.project.name} - {rp.hours_amount}h</Text>
                                          ))
                                        ) : (
                                          <Text>Recurso não está em nenhum projeto</Text>
                                        )}
                                        <Divider mt={'10px'} orientation='horizontal' />
                                      </>
                                      <Text mt={'10px'}>Horas disponíveis: {selectResourceProjectLists.available_hours}h</Text>
                                    </>
                                  )}
                                </Flex>
                              )}
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                        <Stack display='inline-block' maxWidth='min-content'>
                          <Text
                            maxW={'100px'}
                            fontWeight={700}>
                            {resourceName(r.name)}
                          </Text>
                        </Stack>
                      </Flex>
                    </Td>
                    <Td w='20px'>
                      <Text fontWeight={700}>
                        {r.hours_left}H
                      </Text>
                    </Td>
                    {panelTitle !== 'Alocados' && panelTitle !== 'Sobrecarga' && (
                      <Td w={'105px'}>
                        <Tag
                          variant='solid'
                          fontWeight={700}
                          colorScheme={
                            panelTitle === 'Disponíveis' ? r.resourceStatus.substatus?.color : (
                              r.resourceStatus.substatus ? resourceStatusDescription(r.resourceStatus.substatus.name, r.hours_amount, r.resourceClassification?.classification.description).color
                                : resourceStatusDescription(r.resourceStatus.status.name, r.hours_amount, r.resourceClassification?.classification.description).color
                            )}>
                          {r.resourceStatus.substatus ? resourceStatusDescription(r.resourceStatus.substatus?.name, r.hours_amount, r.resourceClassification?.classification.description).description
                            : resourceStatusDescription(r.resourceStatus.status.name, r.hours_amount, r.resourceClassification?.classification.description).description}
                        </Tag>
                      </Td>
                    )}
                    <Td>
                      <Box display='flex' gap='10px'>
                        <HasPermission permissions={['update_resource']}>
                          <TableActionButton text='Editar' icon={FiEdit} onClick={() => selectResource(r.id)} />
                        </HasPermission>
                        <TableActionButton text='Visualizar' icon={FaRegEye} onClick={() => viewSelectedResource(r.id)} />
                        <HasPermission permissions={['view_resources']}>
                          <Tooltip hasArrow label='Acessar perfil' bg='red.600'>
                            <S.AccessResourceProfile to={`/recursos/${r.id}`}>
                              <CgProfile size={20} />
                            </S.AccessResourceProfile>
                          </Tooltip>
                        </HasPermission>
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table >
          ) : (
            <Text width='100%' textAlign='center'>Não há recursos neste status</Text>
          )}
        </Flex>
      )}
    </Panel >
  )
})

export default ResourcesTable