import { useEffect, useState } from 'react'
import { Avatar, Flex, Skeleton, Text, useToast } from '@chakra-ui/react'

import { useParams } from 'react-router-dom'

import { BiWindows } from 'react-icons/bi'
import { IoMdSettings } from 'react-icons/io'
import { MdOutlineFeedback } from 'react-icons/md'
import { TiHome } from 'react-icons/ti'

import * as S from './styles'
import { api } from '../../services/api'

interface ResourceProfileParams {
    resource_id: any
}

interface ResourceProps {
    id: number
    name: string
    email: string
    photo_url: string
    resourceClassification: {
        classification: {
            description: string
        }
    }
}

const ResourceProfile: React.FC = ({children}: any) => {
    const { resource_id } = useParams<ResourceProfileParams>()

    const toast = useToast()

    const [loadingResource, setLoadingResource] = useState(true)
    const [resource, setResource] = useState<ResourceProps>()
    const [resourceExists, setResourceExists] = useState(true)

    useEffect(() => {
        async function loadResource() {
            try {
                const response = await api.get(`/resources/${resource_id}/simple-profile`)
                setResource(response.data)
            } catch(err: any) {
                if(err.response.status === 404) {
                    setResourceExists(false)
                } else {
                    toast({
                        title: 'Erro ao carregar dados do recurso',
                        status: 'error',
                        duration: 4000,
                        isClosable: true
                    })
                }
            }

            setLoadingResource(false)
        }

        loadResource()
    }, [resource_id, toast])

    return (
        <Flex direction={'column'}>
            <S.BackgroundContainer />
                {resourceExists ? (
                    <S.Teste>
                        <S.BackgroundProfile>
                            {loadingResource ? (
                                <>
                                    <Flex gap={'10px'}>
                                        <Skeleton height='100px' width='100px' borderRadius='10px' />
                                        <Flex justifyContent={'center'} direction='column' gap='10px'>
                                            <Skeleton height='20px' width='200px' />
                                            <Skeleton height='20px' width='200px' />
                                        </Flex>
                                    </Flex>
                                </>
                            ) : (
                                <S.ProfileContainer>
                                    <Avatar
                                        height={'100px'}
                                        width={'100px'}
                                        size='2xl'
                                        src={resource?.photo_url}
                                        name={resource?.name}
                                        borderRadius='10px'/>
                                    <Flex direction={'column'} gap='4px' ml='15px'>
                                        <Text fontSize='22px' fontWeight={700}>{resource?.name}</Text>
                                        <Text fontSize='17px' color='#797979'>{resource?.resourceClassification.classification.description}</Text>
                                    </Flex>
                                </S.ProfileContainer>
                            )}
                            <S.MenuContainer>
                                <S.MenuItem to={`/recursos/${resource_id}`} exact activeClassName='profile-menu-active'>
                                    <TiHome size={20} color='#254568'/>Perfil
                                </S.MenuItem>  
                                <S.MenuItem to={`/recursos/${resource_id}/projetos`} activeClassName='profile-menu-active'>
                                    <BiWindows size={20} color='#254568'/>Projetos
                                </S.MenuItem>  
                                <S.MenuItem to={`/recursos/${resource_id}/feedbacks`} activeClassName='profile-menu-active'>
                                    <MdOutlineFeedback size={20} color='#254568'/>Feedbacks
                                </S.MenuItem>
                                <S.MenuItem to={`/recursos/${resource_id}/skills`} activeClassName='profile-menu-active'>
                                    <IoMdSettings size={20} color='#254568' />Skills
                                </S.MenuItem> 
                            </S.MenuContainer>
                        </S.BackgroundProfile>
                        {resource && (
                            <Flex width='100%' bgColor='#fff' padding='20px' borderRadius='10px'>
                                {children}
                            </Flex>
                        )}
                    </S.Teste>
                ) : (
                    <Text>Recurso não E-FUCKING-EXISTE</Text>
                )}
                
        </Flex>
    )
}

export default ResourceProfile