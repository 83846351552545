import { Button, Flex, useToast } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"

import * as yup from 'yup'

import Input from "../../components/Forms/Input"
import { useAuth } from "../../context/AuthContext"
import { api } from "../../services/api"

import * as S from './styles'

const MyProfile: React.FC = () => {
    const toast = useToast()
    const { user, updateUser, singOut } = useAuth()

    const schema = yup.object({
        name: yup.string().required('Nome é obrigatório'),
        email: yup.string().required('E-mail é obrigatório').email('E-mail é inválido'),
    }).required()

    const schemaPassword = yup.object({
        password: yup.string()
            .min(6, 'Senha deve ter entre 6 e 10 caracteres')
            .max(10, 'Senha deve ter entre 6 e 10 caracteres')
            .required('Senha atual é obrigatória'),
        newPassword: yup.string()
            .when('password', (password, newPassword) =>
                password ? newPassword
                    .min(6, 'Nova senha deve ter entre 6 e 10 caracteres')
                    .max(10, 'Nova senha deve ter entre 6 e 10 caracteres')
                    .required('Nova senha é obrigatória') : newPassword),
        confirmation: yup.string()
            .when('newPassword', (newPassword, confirmation) =>
                newPassword ? confirmation.required('Confirmação de senha é obrigatória').oneOf([yup.ref('newPassword')], 'Confirmação deve ser igual a nova senha') : confirmation
            )
    }).required()

    const { handleSubmit, formState, register } = useForm({
        resolver: yupResolver(schema)
    })

    const { handleSubmit: handleSubmitPassword, formState: formStatePassword, register: registerPassword } = useForm({
        resolver: yupResolver(schemaPassword),
        mode: 'onSubmit'
    })

    async function handleUpdateUser(values: any): Promise<void> {
        await updateUser(values)
    }

    async function handleUpdatePassword(values: any): Promise<void> {
        await api.put('/me/password', values)
            .then(res => {
                toast({
                    title: 'Senhas atualizadas!',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                })
            })
            .catch(err => {
                toast({
                    title: 'Erro ao atualizar senha',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            })
    }

    return (
        <S.Container>
            <Button
                onClick={() => singOut()}
                maxWidth={'min-content'}
                colorScheme={'red'}>Deslogar</Button>
            {user && (
                <S.Content>
                    <S.FormWrapper>
                        <Flex
                            onSubmit={(handleSubmit(handleUpdateUser))}
                            as='form'
                            flexDirection={'column'}
                            gap='20px'>
                            <Input
                                defaultValue={user.name}
                                placeholder='Beltrano'
                                textOverflow={'ellipsis'}
                                label="Nome"
                                error={formState.errors.name}
                                {...register('name')}
                            />
                            <Input
                                defaultValue={user.email}
                                label='Email'
                                placeholder='fulano@testingcompany.com.br'
                                error={formState.errors.email}
                                {...register('email')} />
                            <Button type='submit' colorScheme='blue'>Atualizar perfil</Button>
                        </Flex>
                        <Flex
                            onSubmit={(handleSubmitPassword(handleUpdatePassword))}
                            as='form'
                            flexDirection={'column'}
                            gap='20px'>
                            <Input
                                label="Senha atual"
                                type='password'
                                maxLength={10}
                                error={formStatePassword.errors.password}
                                {...registerPassword('password')}
                            />
                            <Input
                                label="Nova senha"
                                type='password'
                                maxLength={10}
                                error={formStatePassword.errors.newPassword}
                                {...registerPassword('newPassword')}
                            />
                            <Input
                                label="Confirmação"
                                type='password'
                                maxLength={10}
                                error={formStatePassword.errors.confirmation}
                                {...registerPassword('confirmation')}
                            />
                            <Button type='submit' colorScheme='blue'>Atualizar senha</Button>
                        </Flex>
                    </S.FormWrapper>
                </S.Content>
            )}
        </S.Container>
    )
}

export default MyProfile