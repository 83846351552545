import styled from 'styled-components'

export const PanelBottom = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & > a {
        margin-left: 5px;
        color: red;
    }
`