import { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button, Flex, Spinner, useToast } from '@chakra-ui/react'

import * as S from './styles'
import Input from "../../components/Forms/Input"
import { api } from '../../services/api'

const PasswordRecovery: React.FC = () => {
    const history = useHistory()
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)
    const schema = yup.object({
        email: yup.string().required('E-mail é obrigatório').email('E-mail é inválido')
    }).required()

    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(schema)
    })

    async function handleSignIn(values: any) {
        setIsLoading(true)

        const { email } = values

        try {
            await api.post('/password-recover', {
                email
            })
            toast({
                title: 'Recuperação enviada para o e-mail!',
                status: 'success',
                duration: 4000,
                isClosable: true
            })
            history.push('/login')
        } catch (err) {
            toast({
                title: 'Ocorreu um erro',
                status: 'error',
                duration: 4000,
                isClosable: true
            })
        }

        setIsLoading(false)
    }

    return (
        <Flex
            as='form'
            flexDir={'column'}
            gap={4}
            onSubmit={handleSubmit(handleSignIn)}>
            <Input
                label='Email'
                placeholder='beltrano@testingcompany.com.br'
                error={formState.errors.email}
                {...register('email')} />
            <Button type='submit' isDisabled={isLoading} colorScheme='red' mt='15'>
                {isLoading ? <Spinner /> : 'Enviar'}
            </Button>
            <S.PanelBottom>
                <Link to='/login'>Voltar para login</Link>
            </S.PanelBottom>
        </Flex>
    )
}

export default PasswordRecovery