import { useEffect, useState } from 'react'

import {
    Flex,
    Text,
    useToast,
    Table,
    Thead,
    Tr,
    Th,
    Avatar,
    Tbody,
    Td,
    Stack,
    Skeleton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Spinner,
    Divider,
    Checkbox,
    Button
} from "@chakra-ui/react"

import { MdPeople } from 'react-icons/md'
import { BsExclamationCircle } from 'react-icons/bs'
import { AiOutlineClockCircle } from 'react-icons/ai'

import Panel from "../../components/Panel"

import { api } from '../../services/api'
import OnlyButtonInput from '../../components/Forms/OnlyButtonInput'

import * as S from './styles'
import { parseISO, format } from 'date-fns'
import CustomDatePicker from '../../components/Forms/CustomDatePicker'
import { HasPermission } from '../../components/HasPermission'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'

interface ResourceProjectsProps {
    resource_hours: number
    available_hours: number
    projects: {
        id: number
        hours_amount: number
        project: {
            id: number
            name: string
            customer: {
                id: number
                name: string
            }
        }
    }[]
}

interface ResourceProps {
    id: number
    name: string
    email: string
    photo_url: string
}

interface CustomerProps {
    id: number
    created_at: Date
    name: string
    status: string
    image_url: string
    hired_hours: number
    resources: ResourceProps[]
    responsibles: ResourceProps[]
}

interface TopDataProps {
    resourcesAvailable: number,
    realNecessity: number,
    plannedNecessity: number
    plannedRelease: number
}

interface GeralVisionProps {
    allResourcesHours: number
    totalHoursHappeningWithoutExceeds: number
    totalExcededHours: number
    totalHoursHappening: number
    allProjectsHours: number
}

interface DisponibilityDataProps {
    totalAvailableHours: number
    totalMissingHours: number
    totalDisponibilityHoursToHappen: number
}

interface AllValuesProps {
    topDash: TopDataProps
    disponibility: DisponibilityDataProps
    geralVision: GeralVisionProps
}

interface PlannedReleaseProps {
    id: number
    hours_amount: number
    project: {
        end_estimate: Date
    }
    resource: {
        id: number
        name: string
        photo_url: string
    }
}

const GeralVision: React.FC = () => {
    const toast = useToast()
    const { user } = useAuth()

    const [loadingResourceProjects, setLoadingResourceProjects] = useState(false)
    const [selectedResource, setSelectedResource] = useState(-1)
    const [selectedResourceProject, setSelectedResourceProject] = useState<ResourceProjectsProps>()

    const [searchText, setSearchText] = useState('')

    const [loadingAllValues, setLoadingAllValues] = useState(true)
    const [allValues, setAllValues] = useState<AllValuesProps>()

    const [plannedReleaseLoading, setPlannedReleaseLoading] = useState(false)
    const [plannedReleaseCalendarDisplay, setPlannedReleaseCalendarDisplay] = useState(false)
    const [plannedReleaseDate, setPlannedReleaseDate] = useState(new Date())
    const [plannedRelease, setPlannedRelease] = useState<PlannedReleaseProps[]>([])

    const [loadingCustomers, setLoadingCustomers] = useState(true)
    const [customers, setCustomers] = useState<CustomerProps[]>([])
    const [customersFiltered, setCustomersFiltered] = useState<CustomerProps[]>([])

    useEffect(() => {
        const userPermissions = user.permissions
        const hasPermission = userPermissions.some(userPermission => userPermission === 'view_geral_vision')

        async function loadValues() {
            const response = await api.get('/geral-vision/all-values')
            setAllValues(response.data)
            setLoadingAllValues(false)
        }

        async function loadCustomers() {
            try {
                const response = await api.get('/customers/with-resources')
                setCustomers(response.data)
                setCustomersFiltered(response.data)
                setLoadingCustomers(false)
            } catch (err) {
                toast({
                    title: 'Erro ao carregar clientes',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            }
        }

        if (hasPermission) loadValues()
        loadCustomers()
    }, [toast, user.permissions])

    useEffect(() => {
        async function plannedRelease() {
            setPlannedReleaseLoading(true)
            const response = await api.get(`/geral-vision/planned-release?date=${plannedReleaseDate}`)
            const responseFormatted = response.data.map((pr: PlannedReleaseProps) => {
                const end_estimate = parseISO(pr.project.end_estimate.toString())
                return { ...pr, project: { ...pr.project, end_estimate: format(end_estimate, 'dd/MM/yyyy') } }
            })
            setPlannedRelease(responseFormatted)
            setPlannedReleaseLoading(false)
        }
        plannedRelease()

    }, [plannedReleaseDate])

    function onSearchByText(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchText(e.target.value)
        const value = e.target.value.toLowerCase()

        const customersFiltered = customers.filter(c => {
            return c.name.toLowerCase().includes(value) || c.resources.some(r => r.name.toLowerCase().includes(value))
        })

        setCustomersFiltered(customersFiltered)
    }

    async function handleSelectResource(resource_id: number) {
        if (selectedResource !== resource_id) {
            setLoadingResourceProjects(true)
            setSelectedResource(resource_id)
            const response = await api.get(`/resources/${resource_id}/projects`)
            const resourceProject = response.data as ResourceProjectsProps
            setSelectedResourceProject(resourceProject)
            setLoadingResourceProjects(false)
        }
    }

    const onChangePlannedReleaseDate = (e: Date) => {
        setPlannedReleaseDate(e)
    }

    function hideCustomersWithNoResources(isChecked: boolean) {
        const customersFiltered = customers.filter(c => {
            return isChecked ? c.resources.length > 0 : c
        })

        setCustomersFiltered(customersFiltered)
    }

    const resourceName = (name: string): string => {
        const lastName = name.substring(name.lastIndexOf(' '), name.length)
        const firstName = name.substring(0, name.indexOf(' '))

        return `${firstName} ${lastName}`
    }

    return (
        <Flex flexDirection='column' gap='60px'>
            <HasPermission permissions={['view_geral_vision']}>
                <Flex flexWrap={'wrap'} width={'100%'} gap='30px'>
                    <S.TopPanel>
                        <Panel
                            position='absolute'
                            top={'-20px'}
                            left={'20px'}
                            maxW='min-content'
                            bgColor='black'>
                            <MdPeople size={30} color='#fff' />
                        </Panel>
                        <Flex
                            direction={'column'}
                            alignItems='flex-end'>
                            <Text>Recursos Disponíveis</Text>
                            {loadingAllValues ? (
                                <Spinner />
                            ) : (
                                <Text fontSize={'30px'} fontWeight={700}>
                                    {allValues?.topDash.resourcesAvailable ?? 0}
                                </Text>
                            )}
                        </Flex>
                    </S.TopPanel>
                    <S.TopPanel>
                        <Panel
                            position='absolute'
                            top={'-20px'}
                            left={'20px'}
                            maxW='min-content'
                            bgColor='black'>
                            <MdPeople size={30} color='#fff' />
                        </Panel>
                        <Flex
                            direction={'column'}
                            alignItems='flex-end'
                        >
                            <Text>Necessidade Real</Text>
                            {loadingAllValues ? (
                                <Spinner />
                            ) : (
                                <Text fontSize={'30px'} fontWeight={700}>
                                    {allValues?.topDash.realNecessity ?? 0}
                                </Text>
                            )}
                        </Flex>
                    </S.TopPanel>
                    <S.TopPanel>
                        <Panel
                            position='absolute'
                            top={'-20px'}
                            left={'20px'}
                            maxW='min-content'
                            bgColor='black'>
                            <MdPeople size={30} color='#fff' />
                        </Panel>
                        <Flex
                            direction={'column'}
                            alignItems='flex-end'
                        >
                            <Text>Necessidade Prevista</Text>
                            {loadingAllValues ? (
                                <Spinner />
                            ) : (
                                <Text fontSize={'30px'} fontWeight={700}>
                                    {allValues?.topDash.plannedNecessity ?? 0}
                                </Text>
                            )}
                        </Flex>
                    </S.TopPanel>
                </Flex>
            </HasPermission>
            <S.PanelsContainer>
                <Panel flex={1} position='relative' flexDir={'column'}>
                    <Checkbox onChange={(e) => hideCustomersWithNoResources(e.target.checked)} mb={'15px'}>Ocultar clientes sem recursos</Checkbox>
                    <Flex
                        alignItems='flex-start'
                        justifyContent='space-between'>
                        <Text fontWeight={700} fontSize='17px'>Alocações</Text>
                        <S.SearchButtonContainer>
                            <OnlyButtonInput
                                placeholder='Insira o nome do cliente ou recurso'
                                value={searchText}
                                name='alocations'
                                width={'100%'}
                                onChange={onSearchByText}
                            />
                        </S.SearchButtonContainer>
                    </Flex>

                    {loadingCustomers ? (
                        <Stack>
                            <Skeleton height='20px' />
                            <Skeleton height='20px' />
                            <Skeleton height='20px' />
                        </Stack>
                    ) : (
                        <Flex maxWidth={'-webkit-fill-available'} overflowX='auto'>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th>CLIENTE</Th>
                                        <Th>RECURSOS</Th>
                                        <Th>LÍDERES</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {customersFiltered.length > 0 ? customersFiltered.map((e) => {
                                        return e.status === 'ATIVO' && (
                                            <Tr key={e.id}>
                                                <Td>
                                                    <Flex alignItems={'center'} gap='20px'>
                                                        <Avatar
                                                            objectFit={'cover'}
                                                            borderRadius='full'
                                                            boxSize='50px'
                                                            name={e.name}
                                                            src={e.image_url}
                                                        />
                                                        <Text fontWeight={700}>
                                                            {e.name}
                                                        </Text>
                                                    </Flex>
                                                </Td>
                                                <Td>
                                                    <Flex flexWrap={'wrap'}>
                                                        {e.resources.length > 0 ? e.resources.map(r => (
                                                            <Popover key={r.id}>
                                                                <PopoverTrigger>
                                                                    <Avatar
                                                                        onClick={() => handleSelectResource(r.id)}
                                                                        cursor='pointer'
                                                                        size='lg'
                                                                        border='3px solid #fff'
                                                                        marginRight={'-20px'}
                                                                        name={r.name}
                                                                        src={r.photo_url}
                                                                    />
                                                                </PopoverTrigger>
                                                                <PopoverContent width={'max-content'} maxWidth='330px'>
                                                                    <PopoverArrow />
                                                                    <PopoverCloseButton />
                                                                    <PopoverHeader>
                                                                        <Text
                                                                            to={`/recursos/${r.id}`}
                                                                            as={Link}
                                                                            fontWeight={700}
                                                                            mr={'30px'}
                                                                        >{resourceName(r.name)}</Text>
                                                                    </PopoverHeader>
                                                                    <PopoverBody>
                                                                        {loadingResourceProjects ? (
                                                                            <Spinner />
                                                                        ) : (
                                                                            <Flex flexDirection={'column'}>
                                                                                {selectedResourceProject && (
                                                                                    <>
                                                                                        <>
                                                                                            {selectedResourceProject.projects.length > 0 ? (
                                                                                                selectedResourceProject.projects.map(rp => (
                                                                                                    <Text mb={'5px'} key={rp.id}>{rp.project.name} - {rp.hours_amount}h</Text>
                                                                                                ))
                                                                                            ) : (
                                                                                                <Text>Recurso não está em nenhum projeto</Text>
                                                                                            )}
                                                                                            <Divider mt={'10px'} orientation='horizontal' />
                                                                                        </>
                                                                                        <Text mt={'10px'}>Horas disponíveis: {selectedResourceProject.available_hours}h</Text>
                                                                                    </>
                                                                                )}
                                                                            </Flex>
                                                                        )}
                                                                    </PopoverBody>
                                                                </PopoverContent>
                                                            </Popover>
                                                        )) : (
                                                            <Text>Sem recursos</Text>
                                                        )}
                                                    </Flex>
                                                </Td>
                                                <Td>
                                                    <Flex flexWrap={'wrap'}>
                                                        {e.responsibles.length > 0 ? e.responsibles.map(r => (
                                                            <Popover key={r.id}>
                                                                <PopoverTrigger>
                                                                    <Avatar
                                                                        onClick={() => handleSelectResource(r.id)}
                                                                        cursor='pointer'
                                                                        size='lg'
                                                                        border='3px solid #fff'
                                                                        marginRight={'-20px'}
                                                                        name={r.name}
                                                                        src={r.photo_url}
                                                                    />
                                                                </PopoverTrigger>
                                                                <PopoverContent width={'max-content'} maxWidth='330px'>
                                                                    <PopoverArrow />
                                                                    <PopoverCloseButton />
                                                                    <PopoverHeader>
                                                                        <Text
                                                                            to={`/recursos/${r.id}`}
                                                                            as={Link}
                                                                            fontWeight={700}
                                                                            mr={'30px'}
                                                                        >{resourceName(r.name)}</Text>
                                                                    </PopoverHeader>
                                                                    <PopoverBody>
                                                                        {loadingResourceProjects ? (
                                                                            <Spinner />
                                                                        ) : (
                                                                            <Flex flexDirection={'column'}>
                                                                                {selectedResourceProject && (
                                                                                    <>
                                                                                        <>
                                                                                            {selectedResourceProject.projects.length > 0 ? (
                                                                                                selectedResourceProject.projects.map(rp => (
                                                                                                    <Text mb={'5px'} key={rp.id}>{rp.project.name} - {rp.hours_amount}h</Text>
                                                                                                ))
                                                                                            ) : (
                                                                                                <Text>Responsável não está em nenhum projeto</Text>
                                                                                            )}
                                                                                            <Divider mt={'10px'} orientation='horizontal' />
                                                                                        </>
                                                                                        <Text mt={'10px'}>Horas disponíveis: {selectedResourceProject.available_hours}h</Text>
                                                                                    </>
                                                                                )}
                                                                            </Flex>
                                                                        )}
                                                                    </PopoverBody>
                                                                </PopoverContent>
                                                            </Popover>
                                                        )) : (
                                                            <Text>Sem responsável</Text>
                                                        )}
                                                    </Flex>
                                                </Td>
                                            </Tr>
                                        )
                                    }) : (
                                        <Tr>
                                            <Td colSpan={3}>
                                                <Text textAlign='center'>Não há clientes</Text>
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </Flex>
                    )}
                </Panel>
                <HasPermission permissions={['view_geral_vision']}>
                    <S.PanelContent>
                        <S.Panel>
                            <Flex flexDirection={'column'} width='100%'>
                                <Flex justifyContent='space-between' alignItems='center'>
                                    <Text fontWeight={700} fontSize='17px'>Visão Geral</Text>
                                    <BsExclamationCircle size={25} />
                                </Flex>
                                <Flex flexDirection='column'>
                                    <Flex
                                        mt='10px'
                                        alignItems='center'
                                        gap='5px'
                                        paddingBottom={'8px'}
                                        borderBottom={'1px solid #ccc'}>
                                        <Popover>
                                            <PopoverTrigger>
                                                <Button padding={0} bgColor={'transparent'}>
                                                    <AiOutlineClockCircle size={20} />
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverBody>
                                                    <strong>Capacidade Total de Horas</strong> é a soma de todas as horas de recursos cadastrados na tela “Recursos”.
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                        <Text>Capacidade Total de Horas</Text>
                                        {loadingAllValues ? (
                                            <Spinner />
                                        ) : (
                                            <Text ml='auto'>
                                                {allValues?.geralVision.allResourcesHours ?? 0}h
                                            </Text>
                                        )}
                                    </Flex>
                                    <Flex
                                        mt='10px'
                                        alignItems='center'
                                        gap='5px'
                                        paddingBottom={'8px'}
                                        borderBottom={'1px solid #ccc'}>
                                        <Popover>
                                            <PopoverTrigger>
                                                <Button padding={0} bgColor={'transparent'}>
                                                    <AiOutlineClockCircle size={20} />
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverBody>
                                                    <strong>Horas em Andamento</strong> é a soma de todas as horas de recursos cadastradas em projetos.
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                        <Text>Horas em Andamento</Text>
                                        {loadingAllValues ? (
                                            <Spinner />
                                        ) : (
                                            <Text ml='auto'>
                                                {allValues?.geralVision.totalHoursHappening ?? 0}h
                                            </Text>
                                        )}
                                    </Flex>
                                    <Flex
                                        mt='10px'
                                        alignItems='center'
                                        gap='5px'
                                        paddingBottom={'8px'}
                                        borderBottom={'1px solid #ccc'}>
                                        <Popover>
                                            <PopoverTrigger>
                                                <Button padding={0} bgColor={'transparent'}>
                                                    <AiOutlineClockCircle size={20} />
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverBody>
                                                    <strong>Horas sem Excedentes em Andamento</strong> é a soma de todas as horas de recursos cadastradas em projetos sem contar os recursos que estão com horas excedentes.
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                        <Text>Horas sem Excedentes em Andamento</Text>
                                        {loadingAllValues ? (
                                            <Spinner />
                                        ) : (
                                            <Text ml='auto'>
                                                {allValues?.geralVision.totalHoursHappeningWithoutExceeds ?? 0}h
                                            </Text>
                                        )}
                                    </Flex>
                                    <Flex
                                        mt='10px'
                                        alignItems='center'
                                        gap='5px'
                                        paddingBottom={'8px'}>
                                        <Popover>
                                            <PopoverTrigger>
                                                <Button padding={0} bgColor={'transparent'}>
                                                    <AiOutlineClockCircle size={20} />
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverBody>
                                                    <strong>Previsão de Horas a Iniciar</strong> é a soma de todas as horas de projetos que vão iniciar.
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                        <Text>Previsão de Horas a Iniciar</Text>
                                        {loadingAllValues ? (
                                            <Spinner />
                                        ) : (
                                            <Text ml='auto'>
                                                {allValues?.geralVision.allProjectsHours ?? 0}h
                                            </Text>
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </S.Panel>
                        <S.Panel>
                            <Flex flexDirection={'column'} width='100%'>
                                <Flex justifyContent='space-between' alignItems='center'>
                                    <Text fontWeight={700} fontSize='17px'>Disponibilidades</Text>
                                    <BsExclamationCircle size={25} />
                                </Flex>
                                <Flex flexDirection='column'>
                                    <Flex
                                        mt='10px'
                                        alignItems='center'
                                        gap='5px'
                                        paddingBottom={'8px'}
                                        borderBottom={'1px solid #ccc'}>
                                        <Flex flexDirection='column' width='100%'>
                                            <Flex alignItems={'center'} gap='5px'>
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Button padding={0} bgColor={'transparent'}>
                                                            <AiOutlineClockCircle size={20} />
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverArrow />
                                                        <PopoverBody>
                                                            <strong>Horas Disponíveis</strong> é o total de horas subtraindo as horas em andamento, quando resultado for positivo.
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                                <Text>Horas Disponíveis</Text>
                                                {loadingAllValues ? (
                                                    <Spinner />
                                                ) : (
                                                    <Text ml='auto'>
                                                        {allValues?.disponibility.totalAvailableHours ?? 0}h
                                                    </Text>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        mt='10px'
                                        alignItems='center'
                                        gap='5px'
                                        paddingBottom={'8px'}
                                        borderBottom={'1px solid #ccc'}>
                                        <Flex flexDirection='column' width='100%'>
                                            <Flex alignItems={'center'} gap='5px'>
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Button padding={0} bgColor={'transparent'}>
                                                            <AiOutlineClockCircle size={20} />
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverArrow />
                                                        <PopoverBody>
                                                            <strong>Horas Faltantes</strong> é o total de horas subtraindo as horas em andamento, quando resultado for negativo.
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                                <Text>Horas Faltantes</Text>
                                                {loadingAllValues ? (
                                                    <Spinner />
                                                ) : (
                                                    <Text ml='auto'>
                                                        {allValues?.disponibility.totalMissingHours ?? 0}h
                                                    </Text>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        mt='10px'
                                        alignItems='center'
                                        gap='5px'
                                        paddingBottom={'8px'}
                                        borderBottom={'1px solid #ccc'}>
                                        <Popover>
                                            <PopoverTrigger>
                                                <Button padding={0} bgColor={'transparent'}>
                                                    <AiOutlineClockCircle size={20} />
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverBody>
                                                    <strong>Horas Excedentes</strong> é a soma da quantidade de horas excedentes trabalhada por recursos.
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                        <Text>Horas Excedentes</Text>
                                        {loadingAllValues ? (
                                            <Spinner />
                                        ) : (
                                            <Text ml='auto'>
                                                {allValues?.geralVision.totalExcededHours ?? 0}h
                                            </Text>
                                        )}
                                    </Flex>
                                    <Flex
                                        mt='10px'
                                        alignItems='center'
                                        gap='5px'
                                        paddingBottom={'8px'}>
                                        <Flex flexDirection='column' width='100%'>
                                            <Flex alignItems={'center'} gap='5px' mb='5px'>
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Button padding={0} bgColor={'transparent'}>
                                                            <AiOutlineClockCircle size={20} />
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverArrow />
                                                        <PopoverBody>
                                                            <strong>Disponibilidade Prevista</strong> é o total de horas disponíveis subtraindo as horas faltantes e subtraindo a previsão de horas à iniciar.
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                                <Text>Disponibilidade Prevista</Text>
                                                {loadingAllValues ? (
                                                    <Spinner />
                                                ) : (
                                                    <Text ml='auto'>
                                                        {allValues?.disponibility.totalDisponibilityHoursToHappen ?? 0}h
                                                    </Text>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </S.Panel>
                        <S.Panel>
                            <Flex flexDirection={'column'} width='100%'>
                                <Flex justifyContent='space-between' alignItems='center'>
                                    <Text fontWeight={700} fontSize='17px'>Liberação Prevista</Text>
                                    <CustomDatePicker
                                        currentDate={plannedReleaseDate}
                                        isOpen={plannedReleaseCalendarDisplay}
                                        onChange={onChangePlannedReleaseDate}
                                        onBlur={() => setPlannedReleaseCalendarDisplay(false)}
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                        showTwoColumnMonthYearPicker
                                    />
                                </Flex>
                                <Flex flexDirection='column'>
                                    {plannedReleaseLoading ? (
                                        <Spinner />
                                    ) : (
                                        plannedRelease.length > 0 ? (
                                            plannedRelease.map(pr => (
                                                <Flex
                                                    key={pr.id}
                                                    mt='10px'
                                                    alignItems='center'
                                                    gap='5px'
                                                    paddingBottom={'8px'}
                                                    borderBottom={'1px solid #ccc'}>
                                                    <Flex flexDirection='column' width='100%'>
                                                        <Flex alignItems={'center'} justifyContent='space-between' gap='5px' mb='5px'>
                                                            <Flex alignItems={'center'} gap='10px' width='100px'>
                                                                <Popover>
                                                                    <PopoverTrigger>
                                                                        <Avatar
                                                                            onClick={() => handleSelectResource(pr.resource.id)}
                                                                            cursor='pointer'
                                                                            objectFit={'cover'}
                                                                            borderRadius='full'
                                                                            size={'md'}
                                                                            border='3px solid #fff'
                                                                            name={pr.resource.name}
                                                                            src={pr.resource.photo_url}
                                                                        />
                                                                    </PopoverTrigger>
                                                                    <PopoverContent width={'max-content'} maxWidth='330px'>
                                                                        <PopoverArrow />
                                                                        <PopoverCloseButton />
                                                                        <PopoverHeader>
                                                                            <Text
                                                                                to={`/recursos/${pr.resource.id}`}
                                                                                as={Link}
                                                                                fontWeight={700}
                                                                                mr={'30px'}
                                                                            >{resourceName(pr.resource.name)}</Text>
                                                                        </PopoverHeader>
                                                                        <PopoverBody>
                                                                            {loadingResourceProjects ? (
                                                                                <Spinner />
                                                                            ) : (
                                                                                <Flex flexDirection={'column'}>
                                                                                    {selectedResourceProject && (
                                                                                        <>
                                                                                            <>
                                                                                                {selectedResourceProject.projects.length > 0 ? (
                                                                                                    selectedResourceProject.projects.map(rp => (
                                                                                                        <Text mb={'5px'} key={rp.id}>{rp.project.name} - {rp.hours_amount}h</Text>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <Text>Recurso não está em nenhum projeto</Text>
                                                                                                )}
                                                                                                <Divider mt={'10px'} orientation='horizontal' />
                                                                                            </>
                                                                                            <Text mt={'10px'}>Horas disponíveis: {selectedResourceProject.available_hours}h</Text>
                                                                                        </>
                                                                                    )}
                                                                                </Flex>
                                                                            )}
                                                                        </PopoverBody>
                                                                    </PopoverContent>
                                                                </Popover>
                                                                <Text>{resourceName(pr.resource.name)}</Text>
                                                            </Flex>
                                                            <Text>{pr.hours_amount}h</Text>
                                                            <Text>{pr.project.end_estimate}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            ))
                                        ) : (
                                            <span>Não há previsões nesta data</span>
                                        )
                                    )}
                                </Flex>
                            </Flex>
                        </S.Panel>
                    </S.PanelContent>
                </HasPermission>
            </S.PanelsContainer>
        </Flex>
    )
}

export default GeralVision