import { useEffect, useState } from "react"
import { Flex, Text } from "@chakra-ui/react"

import { format, addMonths, subMonths } from 'date-fns'

import { api } from "../../../services/api"

import LineChart from "../../../components/Charts/LineChart"

import * as S from './styles'
import { MdPieChart } from "react-icons/md"

interface ProjectProps {
  month: string
  numberOfProjects: number
}

interface CustomerChartsProps {
  id: string
  data: {
    x: string
    y: number
  }[]
}

const ProjectsByMonths: React.FC = () => {
  const [lastProjectTypes, setLastProjectTypes] = useState<CustomerChartsProps[]>([])
  const [futureProjectTypes, setFutureProjectTypes] = useState<CustomerChartsProps[]>([])

  useEffect(() => {
    async function loadLastProjects() {
      const currentDate = format(subMonths(new Date(), 3), 'yyyy-MM-dd hh:mm')
      const lastDate = format(new Date(), 'yyyy-MM-dd hh:mm')

      const response = await api.get(`/dashboard/projects-by-month?first_date=${currentDate}&last_date=${lastDate}&showFinished=true`)
      const customerResponse: ProjectProps[] = response.data

      const data = customerResponse.map(pbm => {
        return {
          x: pbm.month,
          y: pbm.numberOfProjects
        }
      })

      const chartData = {
        id: 'Projetos',
        color: '#4287f5',
        data
      }
      setLastProjectTypes([chartData])
    }

    async function loadFutureProjects() {
      const currentDate = format(new Date(), 'yyyy-MM-dd hh:mm')
      const lastDate = format(addMonths(new Date(), 3), 'yyyy-MM-dd hh:mm')

      const response = await api.get(`/dashboard/projects-by-month?first_date=${currentDate}&last_date=${lastDate}&showFinished=false`)
      const customerResponse: ProjectProps[] = response.data

      const data = customerResponse.map(pbm => {
        return {
          x: pbm.month,
          y: pbm.numberOfProjects
        }
      })

      const chartData = {
        id: 'Projetos',
        color: '#4287f5',
        data
      }
      setFutureProjectTypes([chartData])
    }

    loadLastProjects()
    loadFutureProjects()
  }, [])

  return (
    <S.ChartLine>
      <Flex
        position='relative'
        borderRadius='10px'
        alignContent='center'
        justifyContent='center'
        direction='column'
        boxShadow={'0px 2px 5px 1px rgba(0,0,0,0.35)'}
        width='100%'
        minW={0}>
        <Flex position='absolute' top='-20px' left='20px' gap='20px' alignSelf='flex-start'>
          <Flex
            height='70px'
            width='70px'
            alignItems='center'
            justifyContent='center'
            borderRadius={'10px'}
            bg='linear-gradient(#ffa523, #fb8e03)'
            boxShadow='0px 2px 5px 1px rgba(0,0,0,0.35)'>
            <MdPieChart size={30} color='#fff' />
          </Flex>
          <Flex justifyContent='flex-end' direction='column' mt='30px'>
            <Text color='#254568' fontWeight={700}>Projetos iniciados por mês</Text>
            <Text color='#c2c2c2'>Comparação</Text>
          </Flex>
        </Flex>
        <Flex
          height='500px'
          borderRadius='10px'
          alignContent='center'
          direction='column'
          width='100%'
          minW={0}
          paddingTop={20}>
          <LineChart data={lastProjectTypes} />
        </Flex>
      </Flex>

      <Flex
        position='relative'
        borderRadius='10px'
        alignContent='center'
        justifyContent='center'
        direction='column'
        boxShadow={'0px 2px 5px 1px rgba(0,0,0,0.35)'}
        width='100%'
        minW={0}>
        <Flex position='absolute' top='-20px' left='20px' gap='20px' alignSelf='flex-start'>
          <Flex
            height='70px'
            width='70px'
            alignItems='center'
            justifyContent='center'
            borderRadius={'10px'}
            bg='linear-gradient(#ffa523, #fb8e03)'
            boxShadow='0px 2px 5px 1px rgba(0,0,0,0.35)'>
            <MdPieChart size={30} color='#fff' />
          </Flex>
          <Flex justifyContent='flex-end' direction='column' mt='30px'>
            <Text color='#254568' fontWeight={700}>Previsão de projetos a iniciar</Text>
            <Text color='#c2c2c2'>Comparação</Text>
          </Flex>
        </Flex>
        <Flex
          height='500px'
          borderRadius='10px'
          alignContent='center'
          direction='column'
          width='100%'
          minW={0}
          paddingTop={20}>
          <LineChart data={futureProjectTypes} />
        </Flex>
      </Flex>
    </S.ChartLine>
  )
}

export default ProjectsByMonths