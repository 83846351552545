import styled from 'styled-components'

export const PanelsContainer = styled.div`
  display: flex;
  gap: 30px;

  @media(max-width: 900px) {
    flex-direction: column;
  }
`

export const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 30px;

  @media(max-width: 900px) {
    width: 100%;
  }
`

export const Panel = styled.div`
  height: 'max-content';
  position: relative;
  padding: 24px;
  border-radius: 8px;
  background: #f7f7f7;
  box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.35);

  @media(max-width: 900px) {
    width: 100%;
  }
`

export const TopPanel = styled.div`
  position: relative;
  flex: 1;
  width: 280px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 15px;
  padding-left: 100px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.35);

  @media(max-width: 700px) {
    flex: none;
    width: 100%;
  }
`

export const SearchButtonContainer = styled.div`
  width: 360px;

  @media(max-width: 900px) {
    width: 250px;
  }
`