import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media(max-width: 700px) {
    width: 100%;
  }
`

export const Content = styled.div`
  width: 100%;
  flex-direction: column;
  justify-content: start;
  gap: 20px;
`

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 100px;

  @media(max-width: 700px) {
    flex-direction: column;
  }
`