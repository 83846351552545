import { useState, useEffect } from 'react'
import { Box, Button, Flex, Select as ChakraSelect, FormControl, FormErrorMessage, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, useDisclosure, useToast } from "@chakra-ui/react"

import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import SkillType from './SkillType'
import Input from '../../components/Forms/Input'
import { api } from '../../services/api'
import CreateButton from '../../components/Buttons/CreateButton'
import * as S from './styles'
import { HasPermission } from '../../components/HasPermission'
import { MdAddchart } from 'react-icons/md'

interface SkillProps {
    id: number
    description: string
    type: string
}

const Skills: React.FC = () => {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure()
    const [isLoading, setIsLoading] = useState(false)

    const [selectedSkill, setSelectedSkill] = useState<SkillProps>()
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

    const [loadingSkills, setLoadingSkills] = useState(true)
    const [skills, setSkills] = useState<SkillProps[]>([])
    const [isLoadingDeleteSkill, setIsLoadingDeleteSkill] = useState(false)

    useEffect(() => {
        async function loadSkills() {
            const response = await api.get('/skills')
            setSkills(response.data)
            setLoadingSkills(false)
        }

        loadSkills()
    }, [toast])

    const skillSchema = yup.object({
        description: yup.string().min(3, 'Deve ter no mínimo 3 caracteres').max(40, 'Deve ter até 40 caracteres').required('Descrição é obrigatória'),
        type: yup.mixed().oneOf([
            'HARD',
            'SOFT'], 'Tipo é obrigatório'),
    }).required()

    const {
        register,
        handleSubmit,
        formState,
        reset,
        clearErrors
    } = useForm({ resolver: yupResolver(skillSchema) })

    const {
        register: registerUpdate,
        handleSubmit: handleSubmitUpdate,
        formState: formStateUpdate,
        reset: resetUpdate,
        clearErrors: clearErrorsUpdate
    } = useForm({ resolver: yupResolver(skillSchema) })

    function closeModal() {
        reset()
        clearErrors()
        onClose()
    }

    function closeUpdateModal() {
        setSelectedSkill(undefined)
        resetUpdate()
        clearErrorsUpdate()
        onCloseUpdate()
    }

    function selectSkill(skill: SkillProps) {
        setSelectedSkill(skill)
        onOpenUpdate()
    }

    async function deleteSkill(skill_id: number) {
        setIsLoadingDeleteSkill(true)

        try {
            await api.delete(`/skills/${skill_id}`)
            const oldSkills = [...skills]
            const skillIndex = oldSkills.findIndex(hs => hs.id === skill_id)
            oldSkills.splice(skillIndex, 1)

            setSkills(oldSkills)

            toast({
                title: 'Skill deletada!',
                status: 'success',
                duration: 4000,
                isClosable: true
            })
        } catch (err: any) {
            toast({
                title: 'Ocorreu um erro ao tentar deletar skill',
                status: 'error',
                duration: 4000,
                isClosable: true
            })
        }
        setIsLoadingDeleteSkill(false)
    }

    async function handleUpdate(values: any) {
        setIsLoadingUpdate(true)
        if (selectedSkill) {
            try {
                const response = await api.put(`/skills/${selectedSkill.id}`, values)
                const newSkillUpdated: SkillProps = response.data
                const oldSkills = [...skills]
                const skillIndex = oldSkills.findIndex(ss => ss.id === selectedSkill.id)
                oldSkills[skillIndex] = newSkillUpdated

                orderSkills(oldSkills)

                setSkills(oldSkills)

                toast({
                    title: 'Skill atualizada com sucesso!',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                })
            } catch (err: any) {
                const { message } = err.response.data
                toast({
                    title: message,
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                })
            }
        }
        closeUpdateModal()
        setIsLoadingUpdate(false)
    }

    async function handleCreate(values: any) {
        setIsLoading(true)
        let createEntity = null
        try {
            const response = await api.post('/skills', values)
            createEntity = response.data

            const oldSkills = [...skills, createEntity]

            orderSkills(oldSkills)

            setSkills(oldSkills)

            closeModal()

            toast({
                title: 'Skill cadastrada com sucesso!',
                status: 'success',
                duration: 4000,
                isClosable: true
            })

        } catch (err: any) {
            const { message } = err.response.data
            toast({
                title: message,
                status: 'error',
                duration: 4000,
                isClosable: true
            })
        }
        setIsLoading(false)
    }

    const skillsByType = (type: string): SkillProps[] => {
        return skills.filter(s => s.type === type)
    }

    function orderSkills(oldSkills: SkillProps[]) {
        oldSkills.sort((a: SkillProps, b: SkillProps) => {
            if (a.description < b.description) { return -1 }
            if (a.description > b.description) { return 1 }
            return 0
        })
    }

    return (
        <Flex flexDirection={'column'}>
            <S.PanelsContent>
                <SkillType
                    title='Hard Skills'
                    text='hard skill'
                    skills={skillsByType('HARD')}
                    loadingSkills={loadingSkills}
                    isLoadingDeleteSkill={isLoadingDeleteSkill}
                    selectSkill={selectSkill}
                    deleteSkill={deleteSkill} />
                <SkillType
                    title='Soft Skills'
                    text='soft skill'
                    skills={skillsByType('SOFT')}
                    loadingSkills={loadingSkills}
                    isLoadingDeleteSkill={isLoadingDeleteSkill}
                    selectSkill={selectSkill}
                    deleteSkill={deleteSkill} />
            </S.PanelsContent>
            <HasPermission permissions={['create_skill']}>
                <CreateButton tooltipText='skill' icon={MdAddchart} onClick={() => onOpen()} />
            </HasPermission>
            <Modal isOpen={isOpen} onClose={() => closeModal()}>
                <ModalOverlay />
                <ModalContent>
                    <Box as='form' onSubmit={handleSubmit(handleCreate)}>
                        <ModalHeader>Cadastro de Skills</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex
                                flexDir={'column'}
                                gap={4}>
                                <Input
                                    label='Nome'
                                    placeholder='Selenium, Cypress...'
                                    error={formState.errors.description}
                                    {...register('description')} />
                                <FormControl isInvalid={!!formState.errors.type}>
                                    <FormLabel>Tipo</FormLabel>
                                    <ChakraSelect
                                        placeholder='Selecione...'
                                        {...register('type')} >
                                        <option value='HARD'>Hard Skill</option>
                                        <option value='SOFT'>Soft Skill</option>
                                    </ChakraSelect>
                                    <FormErrorMessage>
                                        {formState.errors.type && formState.errors.type.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant='ghost'
                                mr={3}
                                onClick={closeModal}
                                disabled={isLoading}>Cancelar</Button>
                            <Button isDisabled={isLoading} type='submit' colorScheme='blue'>
                                {isLoading && <Spinner marginRight='10px' />}
                                Cadastrar
                            </Button>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenUpdate} onClose={() => closeUpdateModal()}>
                <ModalOverlay />
                <ModalContent>
                    <Box as='form' onSubmit={handleSubmitUpdate(handleUpdate)}>
                        <ModalHeader>Atualizar {selectedSkill?.description}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedSkill && (
                                <Flex
                                    flexDir={'column'}
                                    gap={4}>
                                    <Input
                                        defaultValue={selectedSkill.description}
                                        label='Nome'
                                        placeholder='C#, Python...'
                                        error={formStateUpdate.errors.description}
                                        {...registerUpdate('description')} />
                                    <FormControl isInvalid={!!formState.errors.type}>
                                        <FormLabel>Tipo</FormLabel>
                                        <ChakraSelect
                                            defaultValue={selectedSkill.type}
                                            placeholder='Selecione...'
                                            {...registerUpdate('type')} >
                                            <option value='HARD'>Hard Skill</option>
                                            <option value='SOFT'>Soft Skill</option>
                                        </ChakraSelect>
                                        <FormErrorMessage>
                                            {formState.errors.type && formState.errors.type.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Flex>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant='ghost'
                                mr={3}
                                onClick={closeUpdateModal}
                                disabled={isLoadingUpdate}>Cancelar</Button>
                            <Button type='submit' colorScheme='blue' disabled={isLoadingUpdate}>{isLoadingUpdate ? <Spinner /> : 'Atualizar'}</Button>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
        </Flex>
    )
}

export default Skills