import { IoMdCreate } from 'react-icons/io'
import { IconBaseProps } from 'react-icons'

import * as S from './styles'
import { Tooltip } from '@chakra-ui/react'

interface CreateButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: React.ComponentType<IconBaseProps>
    forceAbsolute?: boolean
    tooltipText: 'projeto' | 'cliente' | 'recurso' | 'feedback' | 'skill' | 'grupo de permissão' | 'usuário'
}

const CreateButton: React.FC<CreateButtonProps> = ({ icon: Icon, tooltipText, forceAbsolute = true, ...props }) => {
    return (
        <Tooltip hasArrow label={'Criar '+tooltipText} bg='red.600'>
            <S.Button forceAbsolute={forceAbsolute} {...props}>
                {Icon ? (
                    <Icon size={20} color='#fff' />
                ) : (
                    <IoMdCreate size={20} color='#fff' />
                )}
            </S.Button>
        </Tooltip>
    )
}

export default CreateButton