import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const SidebarMenu = styled.div`
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    width: 80px;
    background-image: linear-gradient(to bottom, #4f4b4b, black);
    transition: all 0.3s;
    overflow: hidden;
    z-index: 11;

    &:hover {
        overflow-y: auto;
        width: 280px;

        & > div {
            justify-content: flex-start;

            h3 {
                right: 80px;
            }

            img {
                left: 60px;
            }
        }

        & h3 {
            opacity: 1;
        }

        span {
            opacity: 1;
            left: 0;
        }
    }

    @media(max-width: 900px) {
        display: none;
    }
`

export const BlurBackground = styled.div`
    position: relative;
    flex: 1;
    transition: all 0.2s;
    pointer-events: none;
    z-index: 3;
`

export const Container = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    ${SidebarMenu}:hover + ${BlurBackground} {
        background: rgba(0,0,0,0.5);
    }
`

export const Header = styled.div`
    position: relative;
    width: 100%;
    padding: 20px 5px;
    display: flex;
    align-items: center;

    border-bottom: 1px solid #948d8d;

    img {
        position: relative;
        left: 15px;
        transition: all 0.2s;
    }

    h3 {
        position: absolute;
        min-width: max-content;
        right: 30px;
        opacity: 0;
        font-size: 18px;
        font-weight: 700;
        color: #fff;

        transition: all 0.2s;
    }
`

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
`

export const MenuList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    h3 {
        font-weight: 700;
        color: #fff;
    }
`

export const Item = styled(Link)`
    position: relative;
    width: 100%;
    height: 50px;
    margin: 5px;
    padding: 7px;
    display: flex;
    align-items: center;

    border-radius: 10px;
    font-weight: 700;
    color: #fff;

    &:hover {
        background: red;
    }

    & > span {
        min-width: max-content;
        position: absolute;
        margin-left: 40px;
        left: -50px;
        opacity: 0;

        transition: all 0.2s;
    }
`

export const IconContainer = styled.div`
    min-width: max-content;
    margin-right: 10px;
    transition: all 0.2s;
`