import { useRef, useState } from 'react'

import { MdDashboard } from 'react-icons/md'
import { GiHamburgerMenu } from 'react-icons/gi'
import { BsPersonFill, BsFileEarmarkBarGraphFill } from 'react-icons/bs'
import { RiFileList3Line } from 'react-icons/ri'
import { BiWindows } from 'react-icons/bi'
import { IoRocketOutline } from 'react-icons/io5'

import { Button } from '@chakra-ui/react'

import useOutsideClick from '../OutsideClick'
import * as S from './styles'
import { HasPermission } from '../HasPermission'

import menuIcon from '../../assets/icone_do_menu.png'

const MobileSidebar: React.FC = () => {
    const [mobileVisible, setMobileVisible] = useState(false)
    const ref = useRef<any>(false)

    useOutsideClick(ref, () => {
        setMobileVisible(false)
    })

    return (
        <S.Container ref={ref}>
            <S.ResponsibleContainer>
                <Button marginRight='5px' onClick={() => setMobileVisible(true)}>
                    <GiHamburgerMenu size='25px' />
                </Button>
                <S.Content mobileVisible={mobileVisible}>
                    <S.Header>
                        <img width='40px' height='40px' src={menuIcon} alt='Icone do menu' />
                        <h3>Equipe +</h3>
                    </S.Header>
                    <S.Body>
                        <S.MenuList>
                            <S.Item to='/' onClick={() => setMobileVisible(false)}>
                                <S.IconContainer>
                                    <BsFileEarmarkBarGraphFill color='#fff' size={25} />
                                </S.IconContainer>
                                <span>Visão Geral</span>
                            </S.Item>
                            <HasPermission permissions={['view_dashboard_menu']}>
                                <S.Item to='/dashboard' onClick={() => setMobileVisible(false)}>
                                    <S.IconContainer>
                                        <MdDashboard color='#fff' size={25} />
                                    </S.IconContainer>
                                    <span>Dashboard</span>
                                </S.Item>
                            </HasPermission>
                            <HasPermission permissions={['view_projects', 'create_project', 'update_project']}>
                                <S.Item to='/projetos' onClick={() => setMobileVisible(false)}>
                                    <S.IconContainer>
                                        <BiWindows color='#fff' size={25} />
                                    </S.IconContainer>
                                    <span>Projetos</span>
                                </S.Item>
                            </HasPermission>
                            <HasPermission permissions={['view_customers', 'create_customer', 'update_customer', 'delete_customer']} >
                                <S.Item to='/clientes' onClick={() => setMobileVisible(false)}>
                                    <S.IconContainer>
                                        <RiFileList3Line color='#fff' size={25} />
                                    </S.IconContainer>
                                    <span>Clientes</span>
                                </S.Item>
                            </HasPermission>
                            <HasPermission permissions={['view_resources', 'create_resource', 'update_resource']}>
                                <S.Item to='/recursos' onClick={() => setMobileVisible(false)}>
                                    <S.IconContainer>
                                        <BsPersonFill color='#fff' size={25} />
                                    </S.IconContainer>
                                    <span>Recursos</span>
                                </S.Item>
                            </HasPermission>
                            <HasPermission permissions={['view_skills', 'create_skill', 'update_skill', 'delete_skill']}>
                                <S.Item to='/skills' onClick={() => setMobileVisible(false)}>
                                    <S.IconContainer>
                                        <IoRocketOutline color='#fff' size={25} />
                                    </S.IconContainer>
                                    <span>Skills</span>
                                </S.Item>
                            </HasPermission>
                        </S.MenuList>
                    </S.Body>
                </S.Content>
            </S.ResponsibleContainer>
        </S.Container>
    )
}

export default MobileSidebar