import { Flex } from "@chakra-ui/react"
import ComparativeHoursCharts from "./ComparativeHoursCharts"

import CustomersCharts from "./CustomersCharts"
import ProjectsByMonths from "./ProjectsByMonths"
import ProjectsCharts from "./ProjectsCharts"

import * as S from './styles'

const Dashboard: React.FC = () => {
  return (
    <Flex gap='50px' direction='column' mt='50px'>
      <CustomersCharts />
      <S.ChartLine>
        <ProjectsCharts />
        <ComparativeHoursCharts />
      </S.ChartLine>
      <ProjectsByMonths />
    </Flex>
  )
}

export default Dashboard