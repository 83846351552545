import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button, Flex, Progress, Spinner, Text, useToast } from '@chakra-ui/react'

import * as S from './styles'
import Input from "../../components/Forms/Input"
import { api } from '../../services/api'
import Panel from '../../components/Panel'

interface ParamsProps {
    token: string
}

const PasswordReset: React.FC = () => {
    const history = useHistory()
    const { token } = useParams<ParamsProps>()
    const toast = useToast()
    const [isLoadingVerifyToken, setIsLoadingVerifyToken] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        async function checkingToken() {
            try {
                await api.get(`/password-recover/${token}`)
                setIsValid(true)
            } catch (err) { }
            setIsLoadingVerifyToken(false)
        }
        checkingToken()
    }, [token])

    const schemaPassword = yup.object({
        newPassword: yup.string()
            .min(6, 'Senha deve ter entre 6 e 10 caracteres')
            .max(10, 'Senha deve ter entre 6 e 10 caracteres')
            .required('Senha atual é obrigatória'),
        confirmation: yup.string()
            .when('newPassword', (newPassword, confirmation) =>
                newPassword ? confirmation.required('Confirmação de senha é obrigatória').oneOf([yup.ref('newPassword')], 'Confirmação deve ser igual a nova senha') : confirmation
            )
    }).required()

    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(schemaPassword)
    })

    async function handleUpdatePassword(values: any) {
        setIsLoading(true)
        const { newPassword } = values

        try {
            await api.patch(`/password-recover/${token}`, {
                password: newPassword
            })
            toast({
                title: 'Você já pode fazer login com a nova senha!',
                status: 'success',
                duration: 4000,
                isClosable: true
            })
            history.push('/login')

        } catch (err) {
            toast({
                title: 'Ocorreu um erro ao tentar atualizar senha',
                status: 'error',
                duration: 4000,
                isClosable: true
            })
        }

        setIsLoading(false)
    }

    return (
        <Flex
            justify={'center'}
            align={'center'}
            h='100vh'
            backgroundColor='#f7f7f7'>
            <Panel
                title='Gestão de equipes TC'
                flexDir={'column'}
                maxWidth={'430px'}>
                {isLoadingVerifyToken ? (
                    <>
                        {!isValid ? (
                            <Flex flexDirection={'column'}>
                                <Text alignSelf={'center'} fontSize='20px' marginBottom='10px'>Validando se a recuperação ainda está válida...</Text>
                                <Progress size='md' borderRadius={'5px'} isIndeterminate />
                            </Flex>
                        ) : (
                            <Text alignSelf={'center'} fontSize='20px' marginBottom='10px'>O token expirou ou já foi usado 😡</Text>
                        )}
                    </>
                ) : (
                    <Flex
                        as='form'
                        flexDir={'column'}
                        gap={4}
                        onSubmit={handleSubmit(handleUpdatePassword)}>
                        <Input
                            maxLength={10}
                            label="Nova senha"
                            type='password'
                            placeholder='123456...'
                            error={formState.errors.newPassword}
                            {...register('newPassword')}
                        />
                        <Input
                            maxLength={10}
                            label="Confirmação"
                            type='password'
                            placeholder='Insira a mesma senha'
                            error={formState.errors.confirmation}
                            {...register('confirmation')}
                        />
                        <Button disabled={isLoading} type='submit' colorScheme='blue'>{isLoading && <Spinner marginRight={'5px'} />}Atualizar senha</Button>
                        {!isLoading && (
                            <S.PanelBottom>
                                <Link to='/login'>Voltar para login</Link>
                            </S.PanelBottom>
                        )}
                    </Flex>
                )}
            </Panel>
        </Flex >

    )
}

export default PasswordReset