import styled from 'styled-components'

export const ChartLine = styled.div`
    display: flex;
    width: 100%;
    min-width: 0;

    gap: 70px 30px;
    @media(max-width: 1100px) {
        flex-direction: column;
    }
`