import { FormControl, Input, InputProps as ChakraInputProps, FormLabel } from '@chakra-ui/react'

interface InputDefaultProps extends ChakraInputProps {
    name: string
    label?: string
}

const InputDefault: React.FC<InputDefaultProps> = ({ name, label, ...props }) => {
    return (
        <FormControl>
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <Input
                id={name}
                name={name}
                label={label}
                size={'lg'}
                textOverflow='ellipsis'
                {...props} />
        </FormControl>
    )
}

export default InputDefault